/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const CampaignSummaryAdittionalCardContainer = styled.div`
  grid-column: 1/5;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  align-items: start;
  background: #fdfbf8;
  border-radius: 4px;
  padding: 40px 24px 24px 24px;
  width: 100%;
  margin-bottom: 16px;
`

export const IconWrapper = styled.span`
  padding: 6px;
`

export const Container = styled.div`
  margin-top: 42px;
`
export const Title = styled.span`
  font-size: 16px;
`
export const Description = styled.span`
  font-size: 14px;
`

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: 1/5;
  padding: 4px 0 0 8px;
`

export const InfoContainer = styled.div`
  display: grid;
  grid-template-rows: 30px 30px;
  span,
  b {
    padding: 2px 0;
    font-size: 14px;
  }
`
export const IconClipboard = styled.div`
  svg {
    width: 32px;
    height: 36px;
    cursor: auto;
    path {
      fill: #f5a83f;
    }
    &:hover {
      path {
        fill: #f5a83f;
      }
    }
  }
`
