import styled from 'styled-components'

export const TemplateSetTableWrapper = styled.div`
  padding: 24px 0 16px 0;
  .table-navigation {
    display: none;
  }
  [class^='Tablestyles__TableContainer'] {
    padding: 0 !important;
    min-width: 1096px !important;

    .table-head {
      padding-right: 0;
      .table-row {
        border-top: 1px solid #c9cfd6;
        .table-data {
          background: #fdfbf8;
          > span {
            color: #7b828a !important;
          }
        }
      }
    }

    .table-body {
      overflow: hidden;
      margin-top: -4px;
    }

    .table-row {
      padding: 0 !important;
      margin-top: 0 !important;
      border-color: #c9cfd6;

      .table-data {
        border-right: 1px solid #c9cfd6;
        padding: 9px 0 !important;
        &:first-child {
          background: #fdfbf8;
          border-left: 1px solid #c9cfd6;
          font-weight: bold;
          min-width: 250px !important;
          flex: 0 0 250px;
          > span {
            padding-left: 12px;
            padding-right: 12px;
            color: #565d66 !important;
          }
        }
        &:not(:first-child) {
          width: calc((100% - 250px) / 6) !important;
          min-width: calc((100% - 250px) / 6) !important;
          flex: 0 0 calc((100% - 250px) / 6) !important;
          > span {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
`
export const TableWrapper = styled.div`
  position: relative;
`

export const TemplateName = styled.span`
  display: flex;
  .template-name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    margin-left: 5px;
  }
`
