import fetcher from '../fetcher'
import { getErrorStatus } from '../fetcher/utils'
import { BASES_REFLEX_API_URL } from '../../../constants'

/**
 * GET /templatesets
 * @param {object} params
 */
export async function getTemplates() {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/templatesets`
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * GET /templatesets/:id
 * @param {number} id
 */
export async function getTemplateById(id) {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/templatesets/${id}?expand=email_template,auth_template,landing_template`
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getCampaignsTemplateById(campaignId, templateId) {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/templatesets/${campaignId}/${templateId}?expand=email_template,auth_template,landing_template`
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}