import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  h1 {
    margin: 16px 0 24px 0;
    line-height: normal;
  }
  a span {
    font-size: 13px;
  }
  div[class^='Breadcrumb__BreadcrumbContainer'] {
    svg {
      margin: 2px 3px 0 5px;
      width: 9px;
      height: 9px;
      path {
        fill: #7b828a;
      }
    }
  }
`
