import React, { useState, useEffect, useCallback } from 'react'
import { Table } from '@elevate_security/elevate-component-library'
import PropTypes from 'prop-types'
import columns from './columns'
import {
  TemplateSetTableWrapper,
  TableWrapper,
  EmptyContainer,
  EmptyListMessage
} from './styles'
import { getActivityLogsFromCampaign } from '@src/services/apis/campaigns'
import { toastErrorHelper } from '@src/services/redux/toasts/actionsCreator'
import { useDispatch } from 'react-redux'
import ActivityIndicator from '@src/components/ActivityIndicator'
import { useCampaignResultState } from '../../CampaignResultContext'
import { sanitizeContent } from '../../../../services/utils'

function CampaignActivityLogTable() {
  const { campaign } = useCampaignResultState()
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({})
  const dispatch = useDispatch()

  const fetchActivityLogs = useCallback(
    async (params = { page: 1, size: 10, sort: '-timestamp' }) => {
      try {
        setLoading(true)
        const { status, data, error } = await getActivityLogsFromCampaign(
          campaign.id,
          params
        )
        if (status === 500) {
          dispatch(toastErrorHelper('An error has happened. Try again.'))
          return
        }
        if (error) {
          dispatch(
            toastErrorHelper(sanitizeContent(error.response.data.detail))
          )
          return
        }
        setResults(data?.results || [])
        setMeta(data?.meta || {})
      } catch (error) {
        setResults([])
        setMeta({})
      } finally {
        setLoading(false)
      }
    },
    [campaign]
  )

  useEffect(() => {
    campaign?.id && fetchActivityLogs()
  }, [campaign])

  const handleChangeTable = info => {
    const { pagination, sort } = info || {}
    const { limit = 5, offset = 0 } = pagination
    const page = Math.floor(offset / limit) + 1
    // default sort by descending timestamp
    const { direction = 'desc', property = 'time' } = sort || {}
    const sortParam = property === 'message' ? 'logstring' : 'timestamp'
    fetchActivityLogs({
      size: limit,
      page,
      sort: `${direction === 'desc' ? '-' : ''}${sortParam}`
    })
  }

  if (!results) return null

  return (
    <TableWrapper>
      <TemplateSetTableWrapper>
        <ActivityIndicator active={loading} margin="200px auto" opacity="0.3" />
        {results && !!results.length && (
          <Table
            manual
            data={results}
            totalCount={meta.totalElements || 0}
            columns={columns}
            defaultPageSize={10}
            pageSizeOptions={[5, 10, 25, 50]}
            onChangeTable={handleChangeTable}
          />
        )}
        {!loading && results && results.length === 0 && (
          <EmptyContainer>
            <EmptyListMessage>No activity logs found.</EmptyListMessage>
          </EmptyContainer>
        )}
      </TemplateSetTableWrapper>
    </TableWrapper>
  )
}

CampaignActivityLogTable.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
}

CampaignActivityLogTable.defaultProps = {}

export default CampaignActivityLogTable
