import React from 'react'
import PropTypes from 'prop-types'

import { Badge } from '@elevate_security/elevate-component-library'
import { CAMPAIGN_STATUS_NAME, CAMPAIGN_STATUS } from '@src/constants'

const TAG_COLOR = {
  [CAMPAIGN_STATUS.Scheduled]: 'yellow',
  [CAMPAIGN_STATUS.Configuring]: 'gray',
  [CAMPAIGN_STATUS.Ended]: 'green', // completes
  [CAMPAIGN_STATUS.Running]: 'white',
  [CAMPAIGN_STATUS.Paused]: 'white',
  [CAMPAIGN_STATUS.MailsSent]: 'white',
  [CAMPAIGN_STATUS.PausedForError]: 'red'
}

const isInProgress = state =>
  [
    CAMPAIGN_STATUS.Running,
    CAMPAIGN_STATUS.Paused,
    CAMPAIGN_STATUS.PausedForError,
    CAMPAIGN_STATUS.MailsSent
  ].includes(state)

const StatusBadge = ({ campaign_state }) => {
  return (
    <>
      {isInProgress(campaign_state) && (
        <>
          <Badge text="In Progress" theme="blue" />
          <span style={{ marginRight: '8px' }}></span>
        </>
      )}
      <Badge
        text={CAMPAIGN_STATUS_NAME[campaign_state]}
        theme={TAG_COLOR[campaign_state]}
      />
    </>
  )
}

StatusBadge.propTypes = {
  campaign_state: PropTypes.string.isRequired
}

export default StatusBadge
