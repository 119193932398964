import { ControlType } from '../../../../services/form-manager/form.types'
import { FormManager } from '../../../../services/form-manager/form'
import { Constrain } from '../../../../services/form-manager/validator'

export const REFLEX_RECIPIENTS_FORM_NAME = 'recipients-form'

export const getRecipientsForm = (campaign, groups) => {
  const data = campaign.data()

  return new FormManager(
    REFLEX_RECIPIENTS_FORM_NAME,
    {
      id: {
        type: ControlType.HIDDEN,
        dirty: true
      },
      organization: {
        type: ControlType.HIDDEN
      },
      current_wizard_step: {
        type: ControlType.HIDDEN
      },
      es_groups_id: {
        type: ControlType.MULTI_SELECT,
        validations: [
          {
            test: Constrain.REQUIRED,
            value: true,
            message: 'Select at least one group'
          }
        ]
      }
    },
    {
      ...campaign.data(),
      es_groups_id: groups.filter(group =>
        data?.es_groups_id?.includes(group.id)
      )
    }
  ).addBeforeSubmitCallBack(form => {
    const definition = form.get()

    const {
      // eslint-disable-next-line no-unused-vars
      organization,
      current_wizard_step,
      es_groups_id,
      ...otherFields
    } = definition

    return form.new(
      {
        ...otherFields,
        es_groups_id: {
          ...es_groups_id,
          value: es_groups_id.value.map(group => group.id)
        },
        current_wizard_step: {
          ...current_wizard_step,
          value: current_wizard_step.value > 2 ? current_wizard_step.value : 2
        }
      },
      true
    )
  })
}
