import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import { useHistory } from 'react-router-dom'
import { Icon } from '@elevate_security/elevate-component-library'
import { BREADCRUMB, getStepIndex } from '../../utils'
import { NavContainer, NavItem, NavList, SpanItem } from './styles'

const Breadcrumb = ({ wizardStep, userStepIndex, disabled, onNavigate }) => {
  const history = useHistory()
  const navigationItems = Object.keys(BREADCRUMB)
  const wizardStepIndex = getStepIndex(wizardStep)

  const handleNavigation = (step, index) => {
    return event => {
      event.preventDefault()
      event.stopPropagation()

      if (!disabled) {
        if (typeof onNavigate === 'function') {
          onNavigate(step, index)
        } else {
          // history.push(getUrl(step, { id }))
        }
      }
    }
  }

  return (
    <NavContainer data-id="hm-wizard-breadcrumbs">
      <NavList>
        {navigationItems.map((step, index) => (
          <li
            key={step}
            data-id={`hm-wizard-breadcrumb-${kebabCase(BREADCRUMB[step])}`}
          >
            {index <= userStepIndex && (
              <NavItem
                location={history.location}
                wizardIndex={wizardStepIndex}
                userIndex={userStepIndex}
                crumbIndex={index}
                disabled={disabled}
                onClick={handleNavigation(step, index)}
                href="/#"
              >
                {BREADCRUMB[step]}
                {index < navigationItems.length - 1 && (
                  <Icon name="Breadcrumb" />
                )}
              </NavItem>
            )}
            {index > userStepIndex && (
              <SpanItem
                wizardIndex={wizardStepIndex}
                userIndex={userStepIndex}
                crumbIndex={index}
              >
                {BREADCRUMB[step]}{' '}
                {index < navigationItems.length - 1 && (
                  <Icon name="Breadcrumb" />
                )}
              </SpanItem>
            )}
          </li>
        ))}
      </NavList>
    </NavContainer>
  )
}

Breadcrumb.propTypes = {
  wizardStep: PropTypes.string.isRequired,
  userStep: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onNavigate: PropTypes.func
}

Breadcrumb.defaultProps = {
  disabled: false,
  onNavigate: data => data
}

export default Breadcrumb
