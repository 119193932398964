import fetcher from '../fetcher'
import { getErrorStatus } from '../fetcher/utils'
import { BASE_EP_API_URL } from '../../../constants'

export async function getDepartmentsByOrganization(organizationId) {
  try {
    const { status, data } = await fetcher.get(
      `${BASE_EP_API_URL}/organizations/${organizationId}/get_departments/`
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

export async function getLocationsByOrganization(organizationId) {
  try {
    const { status, data } = await fetcher.get(
      `${BASE_EP_API_URL}/organizations/${organizationId}/get_locations/`
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
