import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Breadcrumb from '@elevate_security/elevate-component-library/dist/Breadcrumb'
import Link from '@elevate_security/elevate-component-library/dist/Link'
import Typography from '@elevate_security/elevate-component-library/dist/Typography'

import { HeaderWrapper } from './styles'

const { Text, H1 } = Typography

const PageHeader = ({ title, breadcrumbs }) => {
  return (
    <HeaderWrapper>
      <Breadcrumb>
        {breadcrumbs.map(({ label, link }) => (
          <Link to={link} key={label + link}>
            <Text color="300">{label}</Text>
          </Link>
        ))}
      </Breadcrumb>
      <H1 fontWeight="heavy" color="900">
        {title}
      </H1>
    </HeaderWrapper>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
}

export default memo(PageHeader)
