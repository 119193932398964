import Moment from 'moment'
import Timezone from 'moment-timezone'
import { ControlType } from './form-manager/form.types'
import { CAMPAIGN_STATUS } from '../constants'
import dompurify from 'dompurify'

export const isDefined = val => val !== undefined && val !== null

export const mapToId = array => array.map(({ id }) => id)

export const userQuery = search => {
  const params = new URLSearchParams(search)

  return Array.from(params.keys()).reduce(
    (query, key) => ({
      ...query,
      [key]: params.get(key)
    }),
    {}
  )
}

export const getResultValue = (name, definition, values) => {
  if (values && isDefined(values[name])) {
    return values[name]
  }

  if (name in definition && isDefined(definition[name].value)) {
    return definition[name].value
  }

  return null
}

export const getToggleValue = (field, value) => {
  const {
    value: { checked },
    key
  } = value
  if (checked) {
    const exists = (field.value || []).find(vl => vl === key)
    return !exists ? [...(field.value || []), key] : field.value
  }
  return (field.value || []).filter(val => key !== val)
}

export const getValueByFieldType = (field, value) => {
  switch (field.type) {
    case ControlType.TOGGLE:
      return getToggleValue(field, value)
    default:
      return value.value
  }
}

// comapares two dates without time
export const isSameDate = (date1, date2) =>
  date1.clone().startOf('day').isSame(date2.clone().startOf('day'))

// startOf and endOf are moment methods, key can be day, month or year
export const startOf = (date, key) => date.clone().startOf(key)

export const endOf = (date, key) => date.clone().endOf(key)

/**
 * converts the UTC timestamp to a corresponding timezone
 *
 * example params:-
 * date: 2020-11-18T17:00:00
 * timezone: Asia/Karachi
 *
 * example return value:-
 * 2020-11-18T22:00 (as Asia/Karachi is GMT+5)
 */
export const utcAndTzToTime = ({ date, timezone }) =>
  date
    ? (timezone
        ? Timezone.tz(Moment.utc(date).format(), timezone)
        : Timezone.tz(Moment.utc(date).format(), Moment.tz.guess())
      )
        .format()
        .slice(0, 16)
    : null

/**
 * converts time and timezone to UTC
 * UTC format is usually saved passed in api payload
 *
 * example params:-
 * date: 2020-11-18T22:00
 * timezone: Asia/Karachi
 *
 * example return value:-
 * 2020-11-18T17:00:00Z (as Asia/Karachi is GMT+5)
 */
export const timeAndTzToUTC = ({ date, timezone }) =>
  date && timezone
    ? Timezone.tz(date, timezone).utc().format()
    : Moment(date).utc().format()

export const pluralize = (word, plural, suffix = 's') =>
  plural ? `${word}${suffix}` : word

export const getGroupListAsString = campaignGroups => {
  const groupNameList = campaignGroups.map(group => group.name)
  const groupNameListAsString = groupNameList.join(', ')
  return groupNameListAsString
}

export const isCampaignScheduled = state => state === CAMPAIGN_STATUS.Scheduled

export const isCampaignInProgress = state =>
  [
    CAMPAIGN_STATUS.Running,
    CAMPAIGN_STATUS.Paused,
    CAMPAIGN_STATUS.MailsSent,
    CAMPAIGN_STATUS.PausedForError
  ].includes(state)

export const isCampaignPaused = state =>
  [CAMPAIGN_STATUS.Paused, CAMPAIGN_STATUS.PausedForError].includes(state)

export const isNA = value => value === null || value === undefined

export const getPercentage = (a, b) =>
  Number.isInteger((a / b) * 100) ? (a / b) * 100 : ((a / b) * 100).toFixed(1)

export const getPercentageString = (a, b) =>
  b === 0 || isNA(a) || isNA(b) ? '0%' : `${getPercentage(a, b)}%`

// Adds commas to numbers 1000 and above

export const formatWithCommas = num =>
  typeof num === 'number'
    ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 'N/A'

// Darkly flags

export const darkly = () => window && window.ldClient

export const darklyGetFlag = flag => {
  const ld = darkly()

  if (!ld) {
    return false
  }

  const allFlags = ld.allFlags()

  return allFlags && allFlags[flag]
}

export const darklyGetUser = () => {
  const ld = darkly()

  if (!ld) {
    return false
  }

  return ld.getUser()
}

/**
 * It takes a dirty HTML as input and return a clean string
 * free of any svg, svg filters, mathML and HTML
 *
 * @param {string} dirty
 */
export const sanitizeContent = dirty =>
  dompurify.sanitize(dirty, {
    USE_PROFILES: { svg: false, svgFilters: false, mathMl: false, html: false }
  })

export const exportCSVFromApi = ({ data, filename }) => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
