import numberToWords from 'number-to-words'

export const renderRankingMessage = (rankedTemplates, index) => {
  const total = rankedTemplates.length || 0
  const templateName = rankedTemplates[index]?.template_name || ''
  const recipients = rankedTemplates[index]?.mails_sent || 0

  const wordsOrdinal = numberToWords.toWordsOrdinal(index + 1)
  const ordinal = numberToWords.toOrdinal(index + 1)

  const notUsedTemplate = {
    title: `Not Used: `,
    description: `Due to the random nature of template set assignment, 
    <b>${templateName}</b> was not sent to any
    recipients in this campaign.`
  }
  const singleTemplate = {
    title: '',
    description: `Here is how <b>${templateName}</b> performed during this campaign:`
  }
  const bestTemplate = {
    title: 'Most Difficult Template: ',
    description: `Out of ${total} template sets, <b>${templateName}</b> performed the best during this campaign:`
  }
  const moderateTemplate = {
    title: `${ordinal} Most Difficult Template: `,
    description: `Out of ${total} template sets, <b>${templateName}</b> performed the ${wordsOrdinal} best during this campaign:`
  }
  const worstTemplate = {
    title: 'Least Difficult Template: ',
    description: `Out of ${total} template sets, <b>${templateName}</b> performed the worst during this campaign:`
  }

  if (recipients === 0) return notUsedTemplate
  if (total === 1) return singleTemplate
  if (index === 0) return bestTemplate
  if (index === total - 1) return worstTemplate

  return moderateTemplate
}

export const rankTemplates = stats => {
  return stats
    .map(template => ({
      ...template,
      percentageOfPosted: template.posted / (template.mails_sent || 1),
      percentageOfLinksClicked: template.clicked / (template.mails_sent || 1),
      percentageOfEmailsOpened: template.opened / (template.mails_sent || 1),
      percentageOfEmailsNotTrackable: template.not_trackable_opened / (template.mails_sent || 1)
    }))
    .sort((a, b) => {
      const percentageOfPosted = b.percentageOfPosted - a.percentageOfPosted
      const percentageOfLinksClicked =
        b.percentageOfLinksClicked - a.percentageOfLinksClicked
      const percentageOfEmailsOpened =
        b.percentageOfEmailsOpened - a.percentageOfEmailsOpened

      if (percentageOfPosted !== 0) return percentageOfPosted
      else if (percentageOfLinksClicked !== 0) return percentageOfLinksClicked
      else if (percentageOfEmailsOpened !== 0) return percentageOfEmailsOpened
      else return a.template_name.localeCompare(b.template_name)
    })
}
