import {
  SET_WIZARD_FORM,
  SET_WIZARD_CAMPAIGN,
  UNLOAD_WIZARD,
  CAMPAIGN_LOADING,
  IS_CREATING_CAMPAIGN
} from './types'
import { CampaignManager } from '../../campaign-manager'
import { STEP } from '../../../scenes/CampaignWizard/utils'

export const INITIAL_STATE = {
  isCreatingCampaign: false,
  isWizardOpen: false,
  /* The step in which the wizard is currently in */
  wizardStep: STEP.CAMPAIGN_INFO,
  campaign: new CampaignManager({}),
  wizardForm: null,
  error: null
}

export default function wizardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_WIZARD_FORM:
      return {
        ...state,
        wizardForm: action.data,
        campaign: state.campaign.setBusy(false)
      }
    case SET_WIZARD_CAMPAIGN:
      return {
        ...state,
        campaign: state.campaign.update(action.data).setBusy(false)
      }
    case CAMPAIGN_LOADING:
      return {
        ...state,
        campaign: state.campaign.setBusy(action.data)
      }
    case UNLOAD_WIZARD:
      return {
        ...state,
        isWizardOpen: false,
        isCreatingCampaign: false,
        wizardForm: null,
        campaign: new CampaignManager({})
      }
    case IS_CREATING_CAMPAIGN:
      return {
        ...state,
        isCreatingCampaign: action.data
      }
    default:
      return state
  }
}
