import fetcher from '../fetcher'
import { getErrorStatus } from '../fetcher/utils'
import { BASE_EP_API_URL } from '../../../constants'

/**
 * GET /employee/me/
 */
export async function fetchUser() {
  try {
    const { status, data } = await fetcher.get(
      `${BASE_EP_API_URL}/employee/me/`
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
