/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import IconAction from '@elevate_security/elevate-component-library/dist/IconAction'
import Button from '@elevate_security/elevate-component-library/dist/Button'
import Icon from '@elevate_security/elevate-component-library/dist/Icon'

import { ActionContainer, IconWrapper } from './styles'
import ConfirmationModal from '@src/components/ConfirmationModal'

import useActions from '@src/services/redux/useActions'
import * as campaignActions from '../../services/redux/campaigns/actions'
import { ACTION_TYPE, ACTION_ALLOWED, ACTION_PROPS } from './utils'

const Actions = ({
  campaign,
  onEdit,
  onCopy,
  onDelete,
  onCancel,
  onResume,
  onPause,
  options
}) => {
  const {
    deleteCampaign,
    copyCampaign,
    cancelCampaign,
    resumeCampaign,
    pauseCampaign,
    donwloadCampaignResults
  } = useActions(campaignActions)

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [actionOnCampaign, setActionOnCampaign] = useState(null)
  const [downloading, setDownloading] = useState(false)

  const onToggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const onActionClick = action => {
    setActionOnCampaign(action)
    if (
      [
        ACTION_TYPE.EDIT,
        ACTION_TYPE.COPY,
        ACTION_TYPE.DOWNLOAD_RESULTS
      ].includes(action)
    ) {
      // do not show confirmation dialog
      executeActionOnCampaign(action)
    } else {
      // show confirmation dialog
      onToggleConfirmationModal()
    }
  }

  const handleDeleteCampaign = async ({ id, name, campaign_state }) => {
    await deleteCampaign(id, name, campaign_state)
    onDelete && onDelete(id, name, campaign_state)
  }

  const handleCopyCampaign = async ({ id }) => {
    await copyCampaign(id)
    onCopy && onCopy(id)
  }

  const handleCancelCampaign = async ({ id, name }) => {
    await cancelCampaign(id, name)
    onCancel && onCancel(id, name)
  }

  const handlePauseCampaign = async ({ id, name }) => {
    await pauseCampaign(id, name)
    onPause && onPause(id, name)
  }

  const handleResumeCampaign = async ({ id, name }) => {
    await resumeCampaign(id, name)
    onResume && onResume(id, name)
  }

  const handleDownloadResults = async ({ id, name }) => {
    setDownloading(true)
    await donwloadCampaignResults(id, name)
    setDownloading(false)
  }

  const executeActionOnCampaign = action => {
    const { id, name, campaign_state } = campaign
    switch (action) {
      case ACTION_TYPE.EDIT:
        onEdit()
        break
      case ACTION_TYPE.DELETE:
        handleDeleteCampaign({ id, name, campaign_state })
        break
      case ACTION_TYPE.COPY:
        handleCopyCampaign({ id })
        break
      case ACTION_TYPE.CANCEL:
        handleCancelCampaign({ id, name })
        break
      case ACTION_TYPE.PAUSE:
        handlePauseCampaign({ id, name })
        break
      case ACTION_TYPE.RESUME:
        handleResumeCampaign({ id, name })
        break
      case ACTION_TYPE.DOWNLOAD_RESULTS:
        handleDownloadResults({ id, name })
        break
      default:
    }
  }

  const onConfirmUpdateCampaign = action => {
    executeActionOnCampaign(action)
    onToggleConfirmationModal()
  }

  const renderConfirmationText = () => (
    <div>
      {`You're about to ${actionOnCampaign?.toLowerCase()} `}
      <b>{campaign.name}</b>
      {options?.ConfirmationText
        ? options?.ConfirmationText
        : ` from your Campaigns list.`}
    </div>
  )

  const renderConfirmationModal = () => {
    if (!showConfirmationModal) return null

    return (
      <ConfirmationModal
        isOpen={showConfirmationModal}
        headerText={`${actionOnCampaign} Campaign`}
        bodyText={renderConfirmationText()}
        helpText="Are you sure you want to perform this action?"
        confirmText={`Yes, ${actionOnCampaign} Campaign`}
        dismissText={`No, Don't ${actionOnCampaign}`}
        onClose={onToggleConfirmationModal}
        onConfirm={() => onConfirmUpdateCampaign(actionOnCampaign)}
      />
    )
  }

  const removeHiddenActions = actionType => {
    return !options?.hideActions?.includes(actionType)
  }

  const renderActionButtons = () => {
    const { campaign_state } = campaign
    return (
      <ActionContainer>
        {ACTION_ALLOWED[campaign_state]
          .filter(removeHiddenActions)
          .map(action => {
            return (
              <IconWrapper>
                {action === ACTION_TYPE.DOWNLOAD_RESULTS ? (
                  <Button
                    onClick={() => onActionClick(action)}
                    disabled={downloading}
                  >
                    <Icon name="Download" fill="#FFFFFF" size="xsm" />
                    {downloading ? 'Downloading...' : 'Download Results'}
                  </Button>
                ) : (
                  <IconAction
                    name={ACTION_PROPS[action].icon}
                    onClick={() => onActionClick(action)}
                    tooltipText={ACTION_PROPS[action].tooltip}
                    renderTooltipOnHover
                  />
                )}
              </IconWrapper>
            )
          })}
      </ActionContainer>
    )
  }

  if (!campaign) return null

  return (
    <>
      {renderActionButtons()}
      {renderConfirmationModal()}
    </>
  )
}

Actions.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    campaign_state: PropTypes.string
  }),
  onEdit: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  onResume: PropTypes.func,
  onPause: PropTypes.func,
  options: PropTypes.shape({
    ConfirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideActions: PropTypes.arrayOf(PropTypes.string)
  })
}

Actions.defaultProps = {
  campaign: null,
  onEdit: () => null,
  onCopy: () => null,
  onDelete: () => null,
  onCancel: () => null,
  onResume: () => null,
  onPause: () => null,
  options: {
    // this action should only be visible on the campaign results screen
    // if you override this prop you must pass ACTION_TYPE.DOWNLOAD_RESULTS
    // in the array so download result button does not appear in the
    // campaigns list
    hideActions: [ACTION_TYPE.DOWNLOAD_RESULTS]
  }
}

export default Actions
