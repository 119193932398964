export const prepareSortParam = sort => {
  const sortColumnNames = {
    first_name: 'first_name',
    last_name: 'surname',
    email: 'email',
    title: 'title',
    department: 'department',
    location: 'location',
    [undefined]: 'first_name'
  }
  const direction = `${sort?.direction === 'desc' ? '-' : ''}`
  const property = `${sortColumnNames[sort?.property]}`
  return (direction || '') + (property || 'first_name')
}
