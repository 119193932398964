import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Tooltip } from '@elevate_security/elevate-component-library'

import { WrapperIcon } from './styles'

const HelpTooltip = props => {
  const {
    body,
    placement = 'top',
    size = 'sm',
    theme = 'light-border',
    readOnly = true,
    ...rest
  } = props
  return (
    <WrapperIcon>
      <Tooltip
        body={body}
        placement={placement}
        size={size}
        theme={theme}
        readOnly={readOnly}
        {...rest}
      >
        <Icon name="CircleExclamation" size="xsm" fill="#959DA8" />
      </Tooltip>
    </WrapperIcon>
  )
}

HelpTooltip.propTypes = {
  body: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired
}

export default HelpTooltip
