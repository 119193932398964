import keys from 'lodash/keys'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'

/**
 * The CampaignManager helps managing the campaign user data
 * as the user progress through the steps in the wizard.
 *
 * The wizard reducer is already set up to always create a new
 * instance when the campaign data is updated, so re-render can
 * propagate through the wizard.
 */
export class CampaignManager {
  properties = {
    archived: null,
    campaign_state: null,
    created_at: null,
    description: null,
    es_groups_id: null,
    expiry: null,
    fqdn: null,
    handler: null,
    id: null,
    is_waiting_for_schedule: null,
    mail_end: null,
    mail_start: null,
    mails_address_error: null,
    mails_expired: null,
    mails_permanent_error: null,
    mails_sent: null,
    mails_to_send: null,
    mails_total: null,
    name: null,
    notify_email: null,
    notify_on_click: null,
    notify_on_open: null,
    notify_on_post: null,
    scheduled_enable: null,
    scheduled_start: null,
    stagger_count: null,
    stagger_pause: null,
    stats: null,
    target_count: null,
    is_trial: null,
    test_run: null,
    timezone: null,
    type: null,
    sender_name: null,
    sender_email: null,
    email_templates: null,
    randomly_template: null,
    type_of_template_sets: null,
    current_wizard_step: null
  }

  templates = []

  templatesLoaded = false

  busy = false

  constructor(campaign, templates) {
    keys(campaign).forEach(key => {
      if (
        key in this.properties &&
        typeof this.properties[key] !== 'function'
      ) {
        this.properties[key] = campaign[key]
      }
    })
    this.templates = templates || []
    this.busy = false
  }

  id() {
    return this.properties.id
  }

  data() {
    return cloneDeep(this.properties)
  }

  new(data, templates) {
    return new CampaignManager(
      data,
      cloneDeep(templates || this.templates || [])
    ).setTemplatesLoaded(this.isTemplatesLoaded())
  }

  update(campaign, templates) {
    const data = this.data()
    keys(campaign).forEach(key => {
      if (key in data && typeof data[key] !== 'function') {
        data[key] = campaign[key]
      }
    })
    return this.new(data, templates)
  }

  setBusy(value) {
    this.busy = value
    return this
  }

  getName() {
    return this.properties.name
  }

  updateTemplates(templates) {
    this.setTemplatesLoaded(true)
    return this.new(this.data(), templates || [])
  }

  hasTemplates() {
    return this.templates.length > 0
  }

  getCurrentStep() {
    return this.properties.current_wizard_step
  }

  getCreatedDate() {
    return this.properties.created_at
  }

  getStatus() {
    return this.properties.campaign_state
  }

  getOrganization() {
    return this.properties.organization
  }

  getSchedule() {
    return pick(this.properties, ['start_date', 'end_date', 'timezone'])
  }

  getInfo() {
    return pick(this.properties, ['name', 'description', 'is_trial'])
  }

  getTemplates() {
    return this.templates
  }

  getUserStep() {
    return this.properties.current_wizard_step
  }

  isDeleted() {
    return this.properties.deleted
  }

  setTemplatesLoaded(value) {
    this.templatesLoaded = value
    return this
  }

  isTemplatesLoaded() {
    return this.templatesLoaded
  }

  loaded() {
    return this.id() && this.isTemplatesLoaded()
  }
}
