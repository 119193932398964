import React from 'react'
import PropTypes from 'prop-types'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { TypeAheadContainer } from './styles'

export const StyledAsyncTypeAhead = props => {
  return (
    <TypeAheadContainer>
      <AsyncTypeahead {...props} />
    </TypeAheadContainer>
  )
}

StyledAsyncTypeAhead.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  maxResults: PropTypes.number,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired
}

StyledAsyncTypeAhead.defaultProps = {
  multiple: false,
  disabled: false,
  options: [],
  selected: [],
  maxResults: 5,
  placeholder: 'Search...'
}
