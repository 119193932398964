import React from 'react'
import PropTypes from 'prop-types'
import { ButtonLink } from '@elevate_security/elevate-component-library'

import { EmptyListMessage, Container } from './styles'

const BlankCampaignsList = ({ onClick, campaignType }) => {
  const renderEmptyMessage = () => (
    <EmptyListMessage>
      {`Looks like there aren't any ${campaignType} campaigns yet.`}
    </EmptyListMessage>
  )

  const renderButton = () => (
    <ButtonLink onClick={onClick}>Want to create a new campaign?</ButtonLink>
  )
  return (
    <Container>
      {renderEmptyMessage()}
      {renderButton()}
    </Container>
  )
}

BlankCampaignsList.propTypes = {
  campaignType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default BlankCampaignsList
