/**
 * API Base Paths
 */

// eslint-disable-next-line no-undef
export const BASES_REFLEX_API_URL = REACT_APP_REFLEX_API_URL
export const BASE_EP_API_URL = REACT_APP_EP_API_URL

/**
 * Routes Base Paths
 */

export const REFLEX_BASE_PATH = '/engagement/reflex2'
export const REFLEX_CAMPAIGNS_BASE_PATH = `${REFLEX_BASE_PATH}/campaigns`
export const REFLEX_NEW_CAMPAIGN_PATH = `${REFLEX_CAMPAIGNS_BASE_PATH}/new-campaign`
export const REFLEX_EDIT_CAMPAIGN_BASE_PATH = `${REFLEX_CAMPAIGNS_BASE_PATH}/:id`

// statuses on Backend
export const CAMPAIGN_STATUS = {
  Configuring: 'Configuring',
  Scheduled: 'Scheduled',
  Ended: 'Ended',
  Running: 'Running',
  Paused: 'Paused',
  MailsSent: 'Mails sent',
  PausedForError: 'Paused for error'
}

// statuses on Frontend
export const CAMPAIGN_STATUS_NAME = {
  Configuring: 'Draft',
  Scheduled: 'Scheduled',
  Ended: 'Completed',
  Running: 'Currently Sending',
  Paused: 'Paused',
  'Mails sent': 'Sending Finished',
  'Paused for error': 'Paused for error'
}

export const campaignTabNames = {
  ALL: 'All',
  DRAFT: 'Draft',
  SCHEDULED: 'Scheduled',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed'
}

export const campaignsTabs = [
  {
    name: campaignTabNames.ALL,
    status: null
  },
  {
    name: campaignTabNames.DRAFT,
    status: 'Configuring'
  },
  {
    name: campaignTabNames.SCHEDULED,
    status: 'Scheduled'
  },
  {
    name: campaignTabNames.IN_PROGRESS,
    status: 'Running,Paused,Mails sent,Paused for error'
  },
  {
    name: campaignTabNames.COMPLETED,
    status: 'Ended'
  }
]

export const breadcrumbLinks = [
  {
    label: 'Reflex',
    link: '/engagement/reflex2'
  },
  {
    label: 'Campaigns',
    link: '/engagement/reflex2'
  }
]
