import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  Layout,
  TimezonePicker,
  CustomSelect
} from '@elevate_security/elevate-component-library'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import InputErrors from '../../../../components/InputErrors'
import DatePicker from '../../../../components/DatePicker'
import HelpTooltip from '../../../../components/HelpTooltip'
import { RouteContainer, Form, FieldSet, Label } from '../../styles'
import { REFLEX_SCHEDULING_FORM_NAME, getSchedulingForm } from './form'
import { withForm } from '@src/services/form-manager/with-form'
import * as wizardActions from '@src/services/redux/wizard/actions'
import { wizardSelector } from '@src/services/redux/wizard/selectors'
import { getUserLocalTimeZone } from '@src/services/redux/app/selectors'
import useActions from '@src/services/redux/useActions'
import { formOutOfSync } from '../../utils'
import { StaggerRow } from './styles'
import {
  getFormattedData,
  staggerCountOptions,
  staggerPauseOptions,
  updateValidations
} from './utils'
import { isCampaignInProgress } from '@src/services/utils'

const SchedulingStep = props => {
  const { id } = useParams()
  const { campaign, wizardForm } = useSelector(wizardSelector)
  const userTimezone = useSelector(getUserLocalTimeZone)
  const { setWizardForm } = useActions(wizardActions)
  const { handleChange, values } = props
  const campaignData =
    campaign && campaign.id() && campaign.data()
      ? getFormattedData(campaign.data())
      : {}

  useEffect(() => {
    setWizardForm(getSchedulingForm(campaignData, userTimezone))
  }, [])

  useEffect(() => {
    if (id && campaign.id() && id !== values.id) {
      setWizardForm(getSchedulingForm(campaignData, userTimezone))
    }
  }, [campaign])

  if (formOutOfSync(REFLEX_SCHEDULING_FORM_NAME, wizardForm))
    return <ActivityIndicator active />

  const inProgress =
    campaign && isCampaignInProgress(campaign.data().campaign_state)

  const onChange = (name, value) => {
    const updatedForm = wizardForm.updateValue(name, value)
    // update validations if any of these is changed
    if (
      name === 'scheduled_start' ||
      name === 'expiry' ||
      name === 'timezone'
    ) {
      const updatedValidations = updateValidations(updatedForm)
      setWizardForm(updatedValidations)
    } else {
      setWizardForm(updatedForm)
    }
  }

  const {
    scheduled_start,
    expiry,
    timezone,
    stagger_count,
    stagger_pause
  } = wizardForm.get()

  return (
    <RouteContainer>
      <Form>
        <Layout
          container
          style={{
            margin: '0 -12px 37px -12px'
          }}
        >
          <FieldSet
              width="calc((100% / 3) - 24px)"
              style={{ margin: '0 12px', opacity: inProgress ? 0.5 : 1 }}
          >
            <Label>Time Zone</Label>
            <TimezonePicker
                placeholder="Select a timezone"
                defaultValue={timezone.value || timezone.defaultValue}
                onChange={value => onChange('timezone', { value })}
                dropdownWidth="auto"
                invalid={!timezone.valid}
                disabled={inProgress}
            />
            {!timezone.valid && <InputErrors errors={timezone.errors} />}
          </FieldSet>
          <FieldSet
            width="calc((100% / 3) - 24px)"
            style={{ margin: '0 12px' }}
          >
            <Label>
              Campaign Start &nbsp;
              <HelpTooltip
                placement="bottom"
                body="This is when we will begin sending out phishing simulation emails and start tracking data for this campaign."
              />
            </Label>
            <DatePicker
              value={scheduled_start.value}
              onChange={({ value }) => onChange('scheduled_start', { value })}
              invalid={!scheduled_start.valid}
              disabled={inProgress}
            />
            {!scheduled_start.valid && (
              <InputErrors errors={scheduled_start.errors} />
            )}
          </FieldSet>
          <FieldSet
            width="calc((100% / 3) - 24px)"
            style={{ margin: '0 12px' }}
          >
            <Label>
              Campaign End &nbsp;
              <HelpTooltip
                placement="bottom"
                body="This is when we will stop collecting data for this campaign. Phishing links will no longer work after this date."
              />
            </Label>
            <DatePicker
              value={expiry.value}
              onChange={({ value }) => onChange('expiry', { value })}
              invalid={!expiry.valid}
            />
            {!expiry.valid && <InputErrors errors={expiry.errors} />}
          </FieldSet>
        </Layout>
        <StaggerRow>
          <span>Stagger emails by sending</span>
          <CustomSelect
            style={{ width: '100px', margin: 10 }}
            defaultValue={{
              label: `${stagger_count.value}`,
              value: stagger_count.value
            }}
            onChange={({ value }) => handleChange('stagger_count', { value })}
            options={staggerCountOptions}
            isSearch
            maxHeightDropdown="200px"
            disabled={inProgress}
            invalid={!stagger_count.valid}
          />
          <span>emails every</span>
          <CustomSelect
            style={{ width: '100px', margin: 10 }}
            defaultValue={{
              label: `${stagger_pause.value}`,
              value: stagger_pause.value
            }}
            onChange={({ value }) => handleChange('stagger_pause', { value })}
            options={staggerPauseOptions}
            isSearch
            maxHeightDropdown="200px"
            disabled={inProgress}
            invalid={!stagger_pause.valid}
          />
          <span style={{ marginRight: '10px' }}>seconds</span>
          <HelpTooltip
            placement="bottom"
            body="Staggering emails reduces the chance of colleagues getting the same phishing email at the same time and then communicating among themselves to confirm whether or not it’s a phish."
          />
        </StaggerRow>
      </Form>
    </RouteContainer>
  )
}

SchedulingStep.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number,
    stagger_count: PropTypes.any,
    stagger_pause: PropTypes.any
  }),
  handleChange: PropTypes.func.isRequired
}

export default withForm(SchedulingStep)
