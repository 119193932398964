import styled from 'styled-components'

export const StaggerRow = styled.div`
  display: flex;
  align-items: center;
  [class^='inputstyles__InputContainer'] {
    margin-left: 8px;
    margin-right: 8px;
    input[type='number'] {
      width: 96px !important;
    }
  }
`
