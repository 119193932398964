import Timezone from 'moment-timezone'
import { TIME_ZONES } from '@elevate_security/elevate-component-library'
import { ControlType } from '../../../../services/form-manager/form.types'
import { FormManager } from '../../../../services/form-manager/form'
import { Constrain } from '../../../../services/form-manager/validator'
import { timeAndTzToUTC } from '../../../../services/utils'
import { getDatesValidations } from './utils'

export const REFLEX_SCHEDULING_FORM_NAME = 'scheduling-form'

const timeZoneOptions = Object.keys(TIME_ZONES).map(key => ({
  label: key,
  value: TIME_ZONES[key]
}))

export const getSchedulingForm = (data, defaultTimezone) => {
  /**
   * if values are `null` then set default values for
   *
   * timezone (org's default timezone if set)
   * scheduled_start (now + 5 mins)
   * expirty (now + 5 mins + 2 weeks)
   */
  const defaultValues = {
    timezone: data.timezone || defaultTimezone || null,
    scheduled_start:
      data.scheduled_start ||
      (defaultTimezone
        ? Timezone.tz(defaultTimezone).add(5, 'minutes').format()
        : null),
    expiry:
      data.expiry ||
      (defaultTimezone
        ? Timezone.tz(defaultTimezone)
            .add(5, 'minutes')
            .add(2, 'weeks')
            .format()
        : null)
  }
  return new FormManager(
    REFLEX_SCHEDULING_FORM_NAME,
    {
      id: {
        type: ControlType.HIDDEN,
        dirty: true
      },
      current_wizard_step: {
        type: ControlType.HIDDEN
      },
      campaign_state: {
        type: ControlType.HIDDEN
      },
      scheduled_enable: {
        type: ControlType.HIDDEN
      },
      scheduled_start: {
        type: ControlType.DATE,
        validations: getDatesValidations(data).scheduled_start
      },
      expiry: {
        type: ControlType.DATE,
        validations: getDatesValidations(data).expiry
      },
      timezone: {
        defaultValue: '',
        options: timeZoneOptions,
        type: ControlType.SELECT,
        validations: [
          {
            test: Constrain.REQUIRED,
            value: true,
            message: 'Timezone is required'
          }
        ]
      },
      stagger_count: {
        type: ControlType.NUMBER,
        defaultValue: 150,
        validations: [
          { test: Constrain.REQUIRED, value: true, message: '' },
          {
            test: Constrain.INTEGER,
            value: true,
            message: '',
            min: 1
          }
        ]
      },
      stagger_pause: {
        type: ControlType.NUMBER,
        defaultValue: 120,
        validations: [
          { test: Constrain.REQUIRED, value: true, message: '' },
          {
            test: Constrain.INTEGER,
            value: true,
            message: '',
            min: 1
          }
        ]
      }
    },
    {
      ...data,
      ...defaultValues
    }
  ).addBeforeSubmitCallBack(form => {
    const definition = form.get()

    const {
      current_wizard_step,
      scheduled_enable,
      scheduled_start,
      expiry,
      timezone,
      // campaign_state is hidden field, do not submit
      // eslint-disable-next-line no-unused-vars
      campaign_state,
      ...otherFields
    } = definition

    return form.new(
      {
        ...otherFields,
        current_wizard_step: {
          ...current_wizard_step,
          value: current_wizard_step.value > 5 ? current_wizard_step.value : 5
        },
        scheduled_enable: {
          ...scheduled_enable,
          value: true
        },
        scheduled_start: {
          ...scheduled_start,
          value: timeAndTzToUTC({
            date: scheduled_start.value,
            timezone: timezone.value
          })
        },
        expiry: {
          ...expiry,
          value: timeAndTzToUTC({
            date: expiry.value,
            timezone: timezone.value
          })
        },
        timezone
      },
      true
    )
  })
}
