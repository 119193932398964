import { ControlType } from '../../../../services/form-manager/form.types'
import { FormManager } from '../../../../services/form-manager/form'
import { Constrain } from '../../../../services/form-manager/validator'

export const REFLEX_CAMPAIGN_INFO_FORM_NAME = 'campaign-info-form'

export const getCampaignInfoForm = campaign => {
  return new FormManager(
    REFLEX_CAMPAIGN_INFO_FORM_NAME,
    {
      id: {
        type: ControlType.HIDDEN,
        dirty: true
      },
      organization: {
        type: ControlType.HIDDEN
      },
      current_wizard_step: {
        type: ControlType.HIDDEN
      },
      name: {
        type: ControlType.TEXT,
        validations: [
          {
            test: Constrain.REQUIRED,
            value: true,
            message: 'Campaign should have a name'
          }
        ]
      },
      description: {
        type: ControlType.TEXT
      },
      is_trial: {
        type: ControlType.TOGGLE
      },
      test_run: {
        type: ControlType.TOGGLE
      }
    },
    {
      ...campaign.data(),
      is_trial: campaign.data().is_trial ? ['is_trial'] : null,
      test_run: campaign.data().test_run ? ['test_run'] : null
    }
  ).addBeforeSubmitCallBack(form => {
    const definition = form.get()

    const {
      is_trial,
      test_run,
      // eslint-disable-next-line no-unused-vars
      organization,
      current_wizard_step,
      ...otherFields
    } = definition

    return form.new(
      {
        ...otherFields,
        is_trial: {
          ...is_trial,
          value: !!(is_trial.value && is_trial.value.length)
        },
        test_run: {
          ...test_run,
          value: !!(test_run.value && test_run.value.length)
        },
        current_wizard_step: {
          ...current_wizard_step,
          value: current_wizard_step.value > 1 ? current_wizard_step.value : 1
        }
      },
      true
    )
  })
}
