import React, { useMemo, useState } from 'react'
import { TemplateSetPerformanceSliderContainer } from './styles'
import { useCounter } from 'ahooks'
import {
  LeftArrowIcon,
  RightArrowIcon,
  TemplatePage,
  TemplateThumbnail
} from './components'
import { rankTemplates } from './utils'
import { useCampaignResultState } from '@src/scenes/CampaignResult/CampaignResultContext'
import { Carousel } from '@elevate_security/elevate-component-library'
import TemplatesPreview from '../../CampaignSummary/TemplateSetModal/TemplatesPreview'

function TemplateSetSlider() {
  const { campaign, stats, templatesPreview } = useCampaignResultState()
  const [previewingTemplateId, setPreviewingTemplateId] = useState(null)
  const rankedTemplates = useMemo(() => {
    return rankTemplates(stats?.templates || [])
  }, [stats])

  const urlsById = campaign.email_templates.reduce(
    (acc, item) => ({
      ...acc,
      [item.template_id]: item.phishing_url && `https://${item.phishing_url}`
    }),
    {}
  )

  const [index, { inc, dec, set }] = useCounter(0)
  const increment = () => {
    if (index + 1 < (rankedTemplates.length || 0)) inc()
    else set(0)
  }
  const decrement = () => {
    if (index - 1 >= 0) dec()
    else set((rankedTemplates.length || 0) - 1)
  }

  return (
    <TemplateSetPerformanceSliderContainer>
      <LeftArrowIcon decrement={decrement} total={rankedTemplates.length} />
      <Carousel
        steps={rankedTemplates.map(rT => (
          <TemplateThumbnail
            templatePreview={
              templatesPreview ? templatesPreview[rT.template_id] : null
            }
            onExpand={() => setPreviewingTemplateId(rT.template_id)}
          />
        ))}
        activeStep={index + 1}
        goToStep={step => set(step - 1)}
        height="250px"
      />
      <TemplatePage rankedTemplates={rankedTemplates} index={index} />
      <RightArrowIcon increment={increment} total={rankedTemplates.length} />
      {!!rankedTemplates.length && (
        <TemplatesPreview
          campaingTemplates={rankedTemplates.map(item => ({
            id: item.template_id,
            url: urlsById[item.template_id],
            name: item.template_name
          }))}
          templateId={previewingTemplateId}
          visible={!!previewingTemplateId}
          onBack={() => setPreviewingTemplateId(null)}
          onClose={() => {
            setPreviewingTemplateId(null)
          }}
        />
      )}
    </TemplateSetPerformanceSliderContainer>
  )
}

export default TemplateSetSlider
