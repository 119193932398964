import React from 'react'
import PropTypes from 'prop-types'
import { ButtonLink, Icon } from '@elevate_security/elevate-component-library'
import styled from 'styled-components'

const IconWrapper = styled.span`
  margin-right: 6px;
  svg {
    line {
      stroke: ${({ iconFill }) => iconFill};
    }
  }
`
const TextWrapper = styled.span`
  text-decoration: none;
  font-weight: ${props => props.fontWeight};
  color: ${props => (props.disabled ? '#AECCCE' : '')};
  line-height: 39px;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`

const IconLink = ({
  text,
  onClick,
  iconName,
  iconSize = 'default',
  iconFill = '#0C7D84',
  fontWeight,
  disabled
}) => {
  const fillColor = disabled ? '#AECCCE' : iconFill
  const iconProps = {
    name: iconName,
    size: iconSize,
    fill: fillColor
  }
  return (
    <ButtonLink
      style={{
        cursor: disabled ? 'not-allowed' : ''
      }}
      onClick={e => (onClick && !disabled ? onClick(e) : null)}
    >
      {iconName && (
        <IconWrapper iconFill={fillColor}>
          <Icon {...iconProps} />
        </IconWrapper>
      )}
      <TextWrapper fontWeight={fontWeight} disabled={disabled}>
        {text}
      </TextWrapper>
    </ButtonLink>
  )
}

IconLink.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  iconSize: PropTypes.string,
  iconFill: PropTypes.string,
  fontWeight: PropTypes.string,
  disabled: PropTypes.bool
}

IconLink.defaultProps = {
  onClick: () => null,
  iconName: null,
  iconSize: 'default',
  iconFill: 'rgb(12, 125, 132)',
  fontWeight: 'normal',
  disabled: false
}

export default IconLink
