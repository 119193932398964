import { CAMPAIGN_STATUS } from '@src/constants'

export const ACTION_TYPE = {
  EDIT: 'Edit',
  COPY: 'Copy',
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  RESUME: 'Resume',
  PAUSE: 'Pause',
  DOWNLOAD_RESULTS: 'Download Results'
}

export const ACTION_PROPS = {
  [ACTION_TYPE.EDIT]: {
    icon: 'Pencil',
    tooltip: 'Edit campaign'
  },
  [ACTION_TYPE.COPY]: {
    icon: 'Copy',
    tooltip: 'Make a draft copy'
  },
  [ACTION_TYPE.DELETE]: {
    icon: 'Trash',
    tooltip: 'Remove campaign'
  },
  [ACTION_TYPE.CANCEL]: {
    icon: 'Cancel',
    tooltip: 'Cancel campaign'
  },
  [ACTION_TYPE.RESUME]: {
    icon: 'Play',
    tooltip: 'Resume campaign'
  },
  [ACTION_TYPE.PAUSE]: {
    icon: 'Pause',
    tooltip: 'Pause campaign'
  }
}

export const ACTION_ALLOWED = {
  [CAMPAIGN_STATUS.Configuring]: [
    ACTION_TYPE.EDIT,
    ACTION_TYPE.COPY,
    ACTION_TYPE.DELETE
  ],
  [CAMPAIGN_STATUS.Scheduled]: [
    ACTION_TYPE.EDIT,
    ACTION_TYPE.COPY,
    ACTION_TYPE.CANCEL
  ],
  [CAMPAIGN_STATUS.Ended]: [
    ACTION_TYPE.COPY,
    ACTION_TYPE.DELETE,
    ACTION_TYPE.DOWNLOAD_RESULTS
  ],
  [CAMPAIGN_STATUS.Running]: [
    ACTION_TYPE.PAUSE,
    ACTION_TYPE.EDIT,
    ACTION_TYPE.COPY,
    ACTION_TYPE.CANCEL,
    ACTION_TYPE.DOWNLOAD_RESULTS
  ],
  [CAMPAIGN_STATUS.MailsSent]: [
    ACTION_TYPE.EDIT,
    ACTION_TYPE.COPY,
    ACTION_TYPE.CANCEL,
    ACTION_TYPE.DOWNLOAD_RESULTS
  ],
  [CAMPAIGN_STATUS.Paused]: [
    ACTION_TYPE.RESUME,
    ACTION_TYPE.EDIT,
    ACTION_TYPE.COPY,
    ACTION_TYPE.CANCEL,
    ACTION_TYPE.DOWNLOAD_RESULTS
  ],
  [CAMPAIGN_STATUS.PausedForError]: [
    ACTION_TYPE.RESUME,
    ACTION_TYPE.EDIT,
    ACTION_TYPE.COPY,
    ACTION_TYPE.CANCEL,
    ACTION_TYPE.DOWNLOAD_RESULTS
  ]
}
