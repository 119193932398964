import fetcher from '../fetcher'
import { getErrorStatus } from '../fetcher/utils'
import { BASE_EP_API_URL } from '../../../constants'

/**
 * GET /groups
 * @param {object} params
 */
export async function getEPGroups(params = {}) {
  try {
    const { status, data } = await fetcher.get(`${BASE_EP_API_URL}/groups`, {
      params
    })
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
