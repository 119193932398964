import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Toggle, Input } from '@elevate_security/elevate-component-library'
import { useSelector } from 'react-redux'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import InputErrors from '../../../../components/InputErrors'
import HelpTooltip from '../../../../components/HelpTooltip'
import { RouteContainer, FieldSet, Form, Label } from '../../styles'
import { REFLEX_NOTIFICATIONS_FORM_NAME, getNotificationsForm } from './form'
import { withForm } from '../../../../services/form-manager/with-form'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { wizardSelector } from '../../../../services/redux/wizard/selectors'
import useActions from '../../../../services/redux/useActions'
import { formOutOfSync } from '../../utils'
import { useParams } from 'react-router-dom'
import { Constrain } from '@src/services/form-manager/validator'

const NotificationsStep = props => {
  const { id } = useParams()
  const { campaign, wizardForm } = useSelector(wizardSelector)
  const { email: authenticatedUserEmail } = useSelector(
    state => state.reflexReducer.me || {}
  )
  const { setWizardForm } = useActions(wizardActions)
  const { handleChange, values } = props

  useEffect(() => {
    setWizardForm(getNotificationsForm(campaign))
  }, [])

  useEffect(() => {
    if (id && campaign.id() && id !== values.id) {
      setWizardForm(getNotificationsForm(campaign))
    }
  }, [campaign])

  /**
   * Disabled behaviors (tags) validation when short session is on.
   */
  useEffect(() => {
    if (
      wizardForm &&
      (wizardForm.get('notify_on_click') ||
        wizardForm.get('notify_on_open') ||
        wizardForm.get('notify_on_post'))
    ) {
      const {
        notify_on_click,
        notify_on_open,
        notify_on_post,
        notify_email
      } = wizardForm.get()

      const hasAtLeastOneNotification =
        notify_on_click.value || notify_on_open.value || notify_on_post.value

      /**
       * If does not have notifications, and email still have validations, remove them
       * if has notifications and email has no validations, add them
       */
      if (
        (!hasAtLeastOneNotification && notify_email.validations.length > 0) ||
        (hasAtLeastOneNotification && notify_email.validations.length === 0)
      ) {
        setWizardForm(
          wizardForm.update(
            'notify_email',
            (() => {
              if (!hasAtLeastOneNotification) {
                return {
                  ...notify_email,
                  disabled: true,
                  value: notify_email.value || authenticatedUserEmail,
                  validations: []
                }
              }

              return {
                ...notify_email,
                disabled: false,
                value: notify_email.value || authenticatedUserEmail,
                validations: [
                  {
                    test: Constrain.REQUIRED,
                    message: 'Notification email is required'
                  }
                ]
              }
            })()
          )
        )
      }
    }
  }, [wizardForm])

  if (formOutOfSync(REFLEX_NOTIFICATIONS_FORM_NAME, wizardForm))
    return <ActivityIndicator active />

  const { notify_email } = wizardForm.get()

  return (
    <RouteContainer>
      <Form>
        <Label>Send an email notification when:</Label>
        <FieldSet gap="6px" direction="row">
          <Toggle
            name="notify_on_post"
            onChange={e => handleChange('notify_on_post', { value: e.checked })}
            label="Credentials are posted"
            checked={values.notify_on_post}
          />
          <HelpTooltip
            placement="right"
            body="If enabled, we will send a notification email whenever we detect a recipient has submitted credentials on your phishing website."
          />
        </FieldSet>
        <FieldSet gap="6px" direction="row">
          <Toggle
            name="notify_on_open"
            onChange={e => handleChange('notify_on_open', { value: e.checked })}
            label="A email is opened (not recommended for large campaigns)"
            checked={values.notify_on_open}
          />
          <HelpTooltip
            placement="right"
            body="If enabled, we will send a notification email whenever we detect a recipient has opened the phishing email. We don’t recommend enabling this feature on large campaigns, as you may be flooded with notifications."
          />
        </FieldSet>
        <FieldSet direction="row">
          <Toggle
            name="notify_on_click"
            onChange={e =>
              handleChange('notify_on_click', { value: e.checked })
            }
            label="A link is clicked (not recommended for large campaigns)"
            checked={values.notify_on_click}
          />
          <HelpTooltip
            placement="right"
            body="If enabled, we will send a notification email whenever we detect a recipient has clicked on a link in the phishing email. We don’t recommend enabling this feature on large campaigns, as you may be flooded with notifications."
          />
        </FieldSet>
        <FieldSet>
          <Label>Send email notification to:</Label>
          <Input
            width="full"
            value={values.notify_email || notify_email.value}
            required={
              notify_email.dirty && wizardForm.isRequired('notify_email')
            }
            disabled={notify_email.disabled}
            placeholder="Ex: some.one@gmail.com"
            invalid={!notify_email.valid}
            onChange={value => handleChange('notify_email', { value })}
          />
          {true && <InputErrors errors={notify_email.errors} />}
        </FieldSet>
      </Form>
    </RouteContainer>
  )
}

NotificationsStep.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number,
    notify_email: PropTypes.string,
    notify_on_open: PropTypes.bool,
    notify_on_click: PropTypes.bool,
    notify_on_post: PropTypes.bool
  }),
  handleChange: PropTypes.func.isRequired
}

export default withForm(NotificationsStep)
