export default [
  {
    header: 'Recipient'.toUpperCase(),
    key: 'recipient',
    isHeadSort: true,
    asc: true
  },
  { header: 'Opened'.toUpperCase(), key: 'opened', isHeadSort: true },
  { header: 'Link clicked'.toUpperCase(), key: 'clicked', isHeadSort: true },
  {
    header: 'Creds Posted'.toUpperCase(),
    key: 'posted',
    isHeadSort: true
  },
  { header: 'Reported'.toUpperCase(), key: 'reported', isHeadSort: true },
  { header: 'Department'.toUpperCase(), key: 'department', isHeadSort: true },
  { header: 'Location'.toUpperCase(), key: 'location', isHeadSort: true },
  { header: 'Manager'.toUpperCase(), key: 'manager', isHeadSort: true },
  { header: 'Tenure'.toUpperCase(), key: 'tenure', isHeadSort: true }
]
