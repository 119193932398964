import {
  CAMPAIGN_LOADING,
  SET_WIZARD_CAMPAIGN,
  IS_CREATING_CAMPAIGN
} from './types'

export const setWizardCampaign = data => ({
  type: SET_WIZARD_CAMPAIGN,
  data
})

export const campaignLoading = data => ({
  type: CAMPAIGN_LOADING,
  data
})

export const isCreatingCampaign = data => ({
  type: IS_CREATING_CAMPAIGN,
  data
})
