import styled from 'styled-components'

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`
export const ModalWrapper = styled.div`
  [class^='Modalstyles__ModalContainer'] {
    padding: 30px 40px 8px 40px;
  }
  [class^='Modalstyles__ModalContent'] {
    padding-top: 30px;
  }
  [class^='Modalstyles__ModalFooter'] {
    display: none;
  }
`
