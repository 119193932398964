import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import { Provider } from 'react-redux'

import Reflex from './App'
import store from './services/redux'

function domElementGetter() {
  const ID = 'reflex'
  let el = document.getElementById(ID)
  if (!el) {
    el = document.createElement('div')
    el.id = ID
    document.body.appendChild(el)
  }
  // eslint-disable-next-line no-undef
  el.setAttribute('version', __COMMIT_HASH__)
  return el
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: () => (
    <Provider store={store}>
      <Reflex />
    </Provider>
  ),
  domElementGetter
})

export const bootstrap = [reactLifecycles.bootstrap]

export const mount = [
  reactLifecycles.mount,
  // eslint-disable-next-line no-console
  () => Promise.resolve(console.log('Mounting Reflex 2.0'))
]

export const unmount = [
  reactLifecycles.unmount,
  // eslint-disable-next-line no-console
  () => Promise.resolve(console.log('Unmounting Reflex 2.0'))
]

export const unload = [reactLifecycles.unload]
