import { ControlType } from '../../../../services/form-manager/form.types'
import { FormManager } from '../../../../services/form-manager/form'

export const REFLEX_NOTIFICATIONS_FORM_NAME = 'notifications-form'

export const getNotificationsForm = campaign => {
  return new FormManager(
    REFLEX_NOTIFICATIONS_FORM_NAME,
    {
      id: {
        type: ControlType.HIDDEN,
        dirty: true
      },
      current_wizard_step: {
        type: ControlType.HIDDEN
      },
      notify_on_post: {
        type: ControlType.BOOL
      },
      notify_on_click: {
        type: ControlType.BOOL
      },
      notify_on_open: {
        type: ControlType.BOOL
      },
      notify_email: {
        type: ControlType.TEXT,
        disabled: true,
        validations: []
      }
    },
    {
      ...campaign.data()
    }
  ).addBeforeSubmitCallBack(form => {
    const definition = form.get()

    const {
      // eslint-disable-next-line no-unused-vars
      organization,
      current_wizard_step,
      notify_email,
      notify_on_post,
      notify_on_click,
      notify_on_open,
      ...otherFields
    } = definition

    const hasAtLeastOneNotification =
      notify_on_post.value || notify_on_click.value || notify_on_open.value

    const values = {
      notify_on_post,
      notify_on_click,
      notify_on_open,
      ...otherFields,
      ...(hasAtLeastOneNotification
        ? {
            notify_email
          }
        : {}),
      current_wizard_step: {
        ...current_wizard_step,
        value: current_wizard_step.value > 4 ? current_wizard_step.value : 4
      }
    }

    return form.new(values, true)
  })
}
