import React from 'react'
import { TitleSection } from '../../styles'
import TemplateSetSlider from './TemplateSetSlider'
import TemplateSetTable from './TemplateSetTable'
function TemplateSetPerformance() {
  return (
    <>
      <TitleSection>Template Set Performance</TitleSection>
      <TemplateSetSlider />
      <TemplateSetTable />
    </>
  )
}

export default TemplateSetPerformance
