import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Campaigns from './scenes/Campaigns'
import ToastsManager from './scenes/ToastsManager'
import CampaignResult from './scenes/CampaignResult'
import useActions from './services/redux/useActions'
import * as campaignActions from './services/redux/campaigns/actions'
import * as appActions from './services/redux/app/actions'
import { CampaignResultProvider } from './scenes/CampaignResult/CampaignResultContext'

const Reflex = () => {
  const {
    setReflexCampaignList,
    setEPGroups,
    setDomains,
    setTemplates
  } = useActions(campaignActions)
  const { fetchUser } = useActions(appActions)

  useEffect(() => {
    setReflexCampaignList()
    // pre-load some data for the wizard
    setEPGroups()
    setTemplates()
    setDomains()
    fetchUser()
  }, [])

  return (
    <BrowserRouter>
      <div>
        <ToastsManager />
        <Switch>
          <Route
            exact
            path="/engagement/reflex2/campaigns/:id/overview"
            render={() => (
              <CampaignResultProvider>
                <CampaignResult />
              </CampaignResultProvider>
            )}
          />
          <Route
            exact
            path="/engagement/reflex2/campaigns/:id/overview-mvp"
            component={() => (
              <Redirect to={{ pathname: 'overview', search: '?mvp=true' }} />
            )}
          />
          <Route path="/engagement/reflex2/campaigns" component={Campaigns} />
          <Redirect
            from="/engagement/reflex2"
            to="/engagement/reflex2/campaigns"
          />
        </Switch>
      </div>
    </BrowserRouter>
  )
}

export default Reflex
