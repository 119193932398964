import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { Container, DropdownToggle, Dropdown, Section } from './styles'
import { getDropdownOptions, FILTER_TYPE } from './utils'
import Icon from '@elevate_security/elevate-component-library/dist/Icon'
import MultiSelectFilter from './MultiSelectFilter'
import { useSelector } from 'react-redux'
import { useRequest } from 'ahooks'
import {
  getLocationsByOrganization,
  getDepartmentsByOrganization
} from '@src/services/apis/organizations'

const MoreFilter = ({ filters, onSelection }) => {
  const ref = useRef(null)
  const count = filters.length
  const { organization } = useSelector(state => state.reflexReducer.me || {})

  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState([])

  useRequest(
    async () =>
      organization &&
      Promise.all([
        getDepartmentsByOrganization(organization),
        getLocationsByOrganization(organization)
      ]),
    {
      onSuccess: ([departments, locations]) => {
        setOptions(
          getDropdownOptions(
            departments?.data?.results || [],
            locations?.data?.results || []
          )
        )
      },
      refreshDeps: [organization]
    }
  )

  const clickListener = useCallback(
    e => {
      if (
        !ref.current.contains(e.target) &&
        !e.target.classList.contains('show-more-link') &&
        !e.target.classList.contains('expand-icon')
      ) {
        setIsOpen(false)
      }
    },
    [ref.current]
  )

  useEffect(() => {
    document.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('click', clickListener)
    }
  })

  const renderFilterComponent = section => {
    const { type } = section
    if (type === FILTER_TYPE.MULTI_SELECT)
      return (
        <MultiSelectFilter
          section={section}
          filters={filters}
          handleChange={filter => onSelection(filter)}
        />
      )
    return null
  }

  return (
    <Container ref={ref}>
      <DropdownToggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        More Filters
        {count > 0 ? <span>&nbsp;({count})</span> : null}
        <Icon name="CaretDown" size="xxsm" fill="#959DA8" />
      </DropdownToggle>
      {isOpen && (
        <Dropdown>
          {options.map(section => (
            <Section key={section.key}>
              {renderFilterComponent(section)}
            </Section>
          ))}
        </Dropdown>
      )}
    </Container>
  )
}

MoreFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelection: PropTypes.func.isRequired
}

export default MoreFilter
