import { FormManager } from '../../../../services/form-manager/form'
import { ControlType } from '../../../../services/form-manager/form.types'
import { Constrain } from '../../../../services/form-manager/validator'
import { CAMPAIGN_STATUS } from '../../../../constants'

export const REFLEX_CAMPAIGN_CONFIRM_FORM_NAME =
  'REFLEX_CAMPAIGN_CONFIRM_FORM_NAME'

export const getConfirmForm = campaign => {
  const data = campaign.data()
  const status = data.campaign_state !== CAMPAIGN_STATUS.Configuring

  return new FormManager(
    REFLEX_CAMPAIGN_CONFIRM_FORM_NAME,
    {
      id: {
        type: ControlType.HIDDEN,
        dirty: true
      },
      current_wizard_step: {
        type: ControlType.HIDDEN
      },
      campaign_state: {
        type: ControlType.HIDDEN
      },
      status: {
        type: ControlType.BOOL,
        validations: [
          {
            test: Constrain.REQUIRED,
            value: true,
            message: 'Please confirm your campaign details.'
          }
        ]
      }
    },
    {
      ...data,
      status
    }
  ).addBeforeSubmitCallBack(form => {
    const { id, current_wizard_step, campaign_state } = form.get()

    return form.new(
      {
        id,
        current_wizard_step: {
          ...current_wizard_step,
          value: 6
        },
        campaign_state: {
          ...campaign_state,
          value: CAMPAIGN_STATUS.Scheduled
        }
      },
      true
    )
  })
}
