import React, { useMemo } from 'react'
import { Table } from '@elevate_security/elevate-component-library'
import { columns, renderData } from './utils'
import { TemplateSetTableWrapper } from './styles'
import { useCampaignResultState } from '@src/scenes/CampaignResult/CampaignResultContext'
import { rankTemplates } from '../TemplateSetSlider/utils'

function TemplateSetTable() {
  const { stats } = useCampaignResultState()
  const rankedTemplates = useMemo(() => {
    return rankTemplates(stats?.templates || [])
  }, [stats])
  return (
    <TemplateSetTableWrapper>
      <Table
        resizable
        data={renderData(rankedTemplates || [])}
        totalCount={rankedTemplates?.length || 0}
        columns={columns}
      />
    </TemplateSetTableWrapper>
  )
}

TemplateSetTable.defaultProps = {}

export default TemplateSetTable
