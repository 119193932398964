import React from 'react'
import PropTypes from 'prop-types'
import { Description, SubTitle, Title, VerticalCard } from './styles'

function CampaignSummaryCard({ title, subtitle, description }) {
  return (
    <VerticalCard>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <Description>{description}</Description>
      {/* {onClick && (
        <ButtonContainer>
          <Button onClick={onClick}>CTA</Button>
        </ButtonContainer>
      )} */}
    </VerticalCard>
  )
}

CampaignSummaryCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func
}

export default CampaignSummaryCard
