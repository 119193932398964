import * as api from '../../apis/user'
import { setAuthenticatedUser } from './actionsCreator'

export const fetchUser = () => {
  return async dispatch => {
    const { data, error } = await api.fetchUser()

    if (!error) {
      dispatch(setAuthenticatedUser(data))
    }
  }
}
