export default [
  {
    header: 'First Name'.toUpperCase(),
    key: 'first_name',
    isHeadSort: true
  },
  {
    header: 'Last Name'.toUpperCase(),
    key: 'last_name',
    isHeadSort: true
  },
  {
    header: 'Email'.toUpperCase(),
    key: 'email',
    isHeadSort: true
  },
  {
    header: 'Title'.toUpperCase(),
    key: 'title',
    isHeadSort: true
  },
  {
    header: 'Departament'.toUpperCase(),
    key: 'department',
    isHeadSort: true
  },
  {
    header: 'Location'.toUpperCase(),
    key: 'location',
    isHeadSort: true
  }
]
