import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Input, Toggle } from '@elevate_security/elevate-component-library'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import InputErrors from '../../../../components/InputErrors'
import HelpTooltip from '../../../../components/HelpTooltip'
import { RouteContainer, Form, FieldSet, Label } from '../../styles'
import { getCampaignInfoForm, REFLEX_CAMPAIGN_INFO_FORM_NAME } from './form'
import { withForm } from '../../../../services/form-manager/with-form'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { wizardSelector } from '../../../../services/redux/wizard/selectors'
import useActions from '../../../../services/redux/useActions'
import { formOutOfSync } from '../../utils'

const CampaignInfoStep = props => {
  const { id } = useParams()
  const { campaign, wizardForm } = useSelector(wizardSelector)
  const { is_reflex_admin } = useSelector(state => state.reflexReducer.me || {})
  const { setWizardForm } = useActions(wizardActions)
  const { handleChange, values } = props

  useEffect(() => {
    setWizardForm(getCampaignInfoForm(campaign))
  }, [])

  useEffect(() => {
    if (id && campaign.id() && id !== values.id) {
      setWizardForm(getCampaignInfoForm(campaign))
    }
  }, [campaign])

  if (formOutOfSync(REFLEX_CAMPAIGN_INFO_FORM_NAME, wizardForm))
    return <ActivityIndicator active />

  const { name } = wizardForm.get()

  return (
    <RouteContainer>
      <Form>
        <FieldSet>
          <Label>Campaign Name</Label>
          <Input
            width="full"
            value={values.name}
            required={name.dirty && wizardForm.isRequired('name')}
            invalid={!name.valid}
            onChange={value => handleChange('name', { value })}
          />
          {!name.valid && <InputErrors errors={name.errors} />}
        </FieldSet>
        <FieldSet>
          <Label>Campaign Description (Optional)</Label>
          <Input
            width="full"
            value={values.description}
            onChange={value => handleChange('description', { value })}
          />
        </FieldSet>
        <FieldSet direction="row" gap="14px">
          <Toggle
            name="is_trial"
            label="Test campaign"
            checked={values.is_trial && values.is_trial.length}
            onChange={value =>
              handleChange('is_trial', { key: 'is_trial', value })
            }
          />
          <HelpTooltip
            body="Results for campaigns marked as a test will not affect recipients’ scores."
            placement="right"
          />
        </FieldSet>
        {is_reflex_admin && (
          <FieldSet direction="row">
            <Toggle
              name="test_run"
              label="Admin Test Run"
              checked={values.test_run && values.test_run.length}
              onChange={value =>
                handleChange('test_run', { key: 'test_run', value })
              }
            />
          </FieldSet>
        )}
      </Form>
    </RouteContainer>
  )
}

CampaignInfoStep.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    is_trial: PropTypes.any,
    test_run: PropTypes.any
  }),
  handleChange: PropTypes.func.isRequired
}

export default withForm(CampaignInfoStep)
