/* eslint-disable react/prop-types */
import React from 'react'
import dompurify from 'dompurify'
import { Icon } from '@elevate_security/elevate-component-library'
import {
  TemplateInfo,
  TemplatePageContainer,
  Label,
  Value,
  Stats,
  PreviewThumbnail,
  ArrowIcon
} from './styles'
import { renderRankingMessage } from './utils'

export const LeftArrowIcon = ({ decrement, total }) =>
  total > 1 ? (
    <ArrowIcon onClick={decrement}>
      <Icon name="CaretLeft" fill="#959DA8" />
    </ArrowIcon>
  ) : (
    <div />
  )

export const RightArrowIcon = ({ increment, total }) =>
  total > 1 ? (
    <ArrowIcon onClick={increment}>
      <Icon name="CaretRight" fill="#959DA8" />
    </ArrowIcon>
  ) : (
    <div />
  )

export const TemplatePage = ({ rankedTemplates, index }) => {
  const message = renderRankingMessage(rankedTemplates, index)
  const renderTemplateInfo = () => {
    const template = rankedTemplates[index]
    const sanitizer = dompurify.sanitize
    if (!template) return null
    return (
      <TemplateInfo>
        <>
          <h1>
            {message.title}
            {template.template_name}
          </h1>
          <h2
            dangerouslySetInnerHTML={{
              __html: sanitizer(message.description)
            }}
          ></h2>
        </>

        <Stats>
          <Label>Recipients</Label>
          <Value>{template.mails_sent}</Value>
          <Label>Opened</Label>
          <Value>{template.opened}</Value>
          <Label>Not Trackable</Label>
          <Value>{template.not_trackable_opened}</Value>
          <Label>Link Clicked</Label>
          <Value>{template.clicked}</Value>
          <Label>Credentials Posted</Label>
          <Value>{template.posted}</Value>
          <Label>Reported</Label>
          <Value>{template.reported}</Value>
          <Label>No Action</Label>
          <Value>{template.no_action}</Value>
        </Stats>
      </TemplateInfo>
    )
  }
  return <TemplatePageContainer>{renderTemplateInfo()}</TemplatePageContainer>
}

export const TemplateThumbnail = ({ templatePreview, onExpand }) => {
  let nodeRef = null

  const handleFormReady = () => {
    if (nodeRef && nodeRef.querySelector) {
      const { body } = nodeRef.querySelector(
        'iframe[name="template-thumbnail-iframe"]'
      ).contentDocument

      // set custom styles for iframe html tag
      const css = document.createElement('style')
      css.appendChild(document.createTextNode('html { overflow: hidden; }'))
      body.appendChild(css)
    }
  }

  return (
    <PreviewThumbnail loading={!templatePreview}>
      <div
        className="thumbnail-container"
        ref={el => {
          nodeRef = el
        }}
      >
        <div className="thumbnail">
          <iframe
            name="template-thumbnail-iframe"
            srcDoc={templatePreview?.email_template?.contents}
            frameBorder="0"
            onLoad={() => handleFormReady()}
          ></iframe>
        </div>
        <span className="icon-expand" onClick={onExpand}>
          <Icon name="Expand" fill="#1D1E1F" />
        </span>
      </div>
    </PreviewThumbnail>
  )
}
