/* eslint-disable react/prop-types */
import React from 'react'
import { Icon } from '@elevate_security/elevate-component-library'
import { YesOrNoContainer } from './styles'
import moment from 'moment'

export const YesOrNo = ({ value, inverted }) => {
  if (inverted)
    return (
      <YesOrNoContainer isYes={value} inverted={inverted}>
        <Icon
          size="xsm"
          name={value ? 'CircleCheckSolid' : 'CircleXSolid'}
          fill={value ? '#15785F' : '#C73434'}
        />
        {value ? 'Yes' : 'No'}
      </YesOrNoContainer>
    )
  return (
    <YesOrNoContainer isYes={value} inverted={inverted}>
      <Icon
        size="xsm"
        name={value ? 'CircleXSolid' : 'CircleCheckSolid'}
        fill={value ? '#C73434' : '#15785F'}
      />
      {value ? 'Yes' : 'No'}
    </YesOrNoContainer>
  )
}

export const prepareSortParam = sort => {
  const sortColumnNames = {
    recipient: 'first_name',
    opened: 'opened',
    clicked: 'clicked',
    posted: 'posted',
    reported: 'reported',
    department: 'department',
    location: 'location',
    manager: 'manager',
    tenure: 'start_date',
    [undefined]: 'first_name'
  }
  const direction = `${sort?.direction === 'desc' ? '-' : ''}`
  const property = `${sortColumnNames[sort?.property]}`
  return (direction || '') + (property || 'first_name')
}

const getTextForOpenedCol = recipient => {
  if (recipient.opened) return 'Opened'
  if (recipient.clicked || recipient.posted) return 'Not Trackable'
  return 'Unopened'
}

export const prepareTableData = data => {
  const prepareRecipient = recipient => {
    const opened = getTextForOpenedCol(recipient)
    return {
      ...recipient,
      recipient: `${recipient.first_name} ${recipient.last_name}`,
      clicked:
        opened === 'Unopened' ? '—' : <YesOrNo value={recipient.clicked} />,
      posted:
        opened === 'Unopened' ? '—' : <YesOrNo value={recipient.posted} />,
      reported: <YesOrNo value={recipient.reported} inverted={true} />,
      opened,
      department: recipient.department ? recipient.department : '',
      location: recipient.location ? recipient.location : '',
      manager: recipient.manager ? recipient.manager : '',
      tenure: recipient.start_date
        ? moment(recipient.start_date).fromNow(true)
        : ''
    }
  }
  return data.map(prepareRecipient)
}
