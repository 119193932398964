import styled from 'styled-components'

export const ParticipantsContainer = styled.div`
  justify-self: center;
  h2 {
    font-size: 18px;
  }
  span {
    font-size: 14px;
  }
`
