/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const TemplateSetPerformanceSliderContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 430px auto 20px;
  grid-gap: 24px;
  align-items: flex-start;
  background: #fdfbf8;
  border-radius: 4px;
  padding: 24px;
  width: 100%;
  height: 320px;
  min-width: 1096px;

  text-align: center;

  .bg {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    will-change: opacity;
  }

  div[class^='Carouselstyles__Dots'] {
    margin-top: 10px !important;
    label[class^='Carouselstyles__Dot'] {
      height: 12px !important;
      width: 12px !important;
    }
  }
`
export const TemplatePageContainer = styled.div`
  display: grid;
  grid-template-columns: 300px;
  grid-template-areas:
    'info info . . .'
    'page-control page-control none none none';
  grid-gap: 24px;
  text-align: left;
  align-items: flex-start;
`
export const TemplateInfo = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 5px;
  h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #34383c;
    margin-bottom: 16px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #565d66;
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #565d66;
  }
`

export const Stats = styled.div`
  display: grid;
  grid-template-columns: 200px 50px;
  width: 50%;
`
export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`
export const Value = styled.div`
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
  font-weight: bold;
`

export const ArrowIcon = styled.div`
  cursor: pointer;
  align-self: center;
`

const thumbnailWidth = '768px'
const thumbnailHeight = '480px'
const thumbnailZoom = 0.5

export const PreviewThumbnail = styled.div`
  /* This container helps the thumbnail behave as if it were an unscaled IMG element
  */
  .thumbnail-container {
    width: calc(${thumbnailWidth} * ${thumbnailZoom});
    height: calc(${thumbnailHeight} * ${thumbnailZoom});
    display: inline-block;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 1px 16px rgba(149, 157, 168, 0.26);

    .icon-expand {
      position: absolute;
      right: 8px;
      top: 12px;
      cursor: pointer;
      > svg {
        height: 22px;
        width: 22px;
      }
    }
  }

  /* Image Icon for the Background
  */
  .thumbnail-container::before {
    position: absolute;
    left: calc(50% - 16px);
    top: calc(50% - 18px);
    opacity: ${({ loading }) => (loading ? 0.2 : 0)};
    display: block;
    -ms-zoom: 2;
    -o-transform: scale(2);
    -moz-transform: scale(2);
    -webkit-transform: scale(2);
    content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJwaG90b18xXyI+PHBhdGggZD0iTTI3LDBINUMyLjc5MSwwLDEsMS43OTEsMSw0djI0YzAsMi4yMDksMS43OTEsNCw0LDRoMjJjMi4yMDksMCw0LTEuNzkxLDQtNFY0QzMxLDEuNzkxLDI5LjIwOSwwLDI3LDB6ICAgIE0yOSwyOGMwLDEuMTAyLTAuODk4LDItMiwySDVjLTEuMTAzLDAtMi0wLjg5OC0yLTJWNGMwLTEuMTAzLDAuODk3LTIsMi0yaDIyYzEuMTAyLDAsMiwwLjg5NywyLDJWMjh6IiBmaWxsPSIjMzMzMzMzIi8+PHBhdGggZD0iTTI2LDRINkM1LjQ0Nyw0LDUsNC40NDcsNSw1djE4YzAsMC41NTMsMC40NDcsMSwxLDFoMjBjMC41NTMsMCwxLTAuNDQ3LDEtMVY1QzI3LDQuNDQ3LDI2LjU1Myw0LDI2LDR6ICAgIE0yNiw1djEzLjg2OWwtMy4yNS0zLjUzQzIyLjU1OSwxNS4xMjMsMjIuMjg3LDE1LDIyLDE1cy0wLjU2MSwwLjEyMy0wLjc1LDAuMzM5bC0yLjYwNCwyLjk1bC03Ljg5Ni04Ljk1ICAgQzEwLjU2LDkuMTIzLDEwLjI4Nyw5LDEwLDlTOS40NCw5LjEyMyw5LjI1LDkuMzM5TDYsMTMuMDg3VjVIMjZ6IE02LDE0LjZsNC00LjZsOC4wNjYsOS4xNDNsMC41OCwwLjY1OEwyMS40MDgsMjNINlYxNC42eiAgICBNMjIuNzQsMjNsLTMuNDI4LTMuOTU1TDIyLDE2bDQsNC4zNzlWMjNIMjIuNzR6IiBmaWxsPSIjMzMzMzMzIi8+PHBhdGggZD0iTTIwLDEzYzEuNjU2LDAsMy0xLjM0MywzLTNzLTEuMzQ0LTMtMy0zYy0xLjY1OCwwLTMsMS4zNDMtMywzUzE4LjM0MiwxMywyMCwxM3ogTTIwLDhjMS4xMDIsMCwyLDAuODk3LDIsMiAgIHMtMC44OTgsMi0yLDJjLTEuMTA0LDAtMi0wLjg5Ny0yLTJTMTguODk2LDgsMjAsOHoiIGZpbGw9IiMzMzMzMzMiLz48L2c+PC9zdmc+');
  }

  /* This is a masking container for the zoomed iframe element
  */
  .thumbnail {
    -ms-zoom: ${thumbnailZoom};
    -moz-transform: scale(${thumbnailZoom});
    -moz-transform-origin: 0 0;
    -o-transform: scale(${thumbnailZoom});
    -o-transform-origin: 0 0;
    -webkit-transform: scale(${thumbnailZoom});
    -webkit-transform-origin: 0 0;
  }

  /* This is our screen sizing
  */
  .thumbnail,
  .thumbnail iframe {
    width: ${thumbnailWidth};
    height: ${thumbnailHeight};
  }

  /* This facilitates the fade-in transition instead of flicker.
     It also helps us maintain the illusion that this is an image,
     since some webpages will have a preloading animation or wait
     for some images to download
  */
  .thumbnail iframe {
    opacity: ${({ loading }) => (loading ? 0 : 1)};
    transition: all 300ms ease-in-out;
  }

  /* This pseudo element masks the iframe, so that mouse wheel
     scrolling and clicking do not affect the simulated "screenshot"
  */
  .thumbnail:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`
