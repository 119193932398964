/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const VerticalCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  background: #fdfbf8;
  border-radius: 4px;
  padding: 24px;
  height: 284px;
  min-width: 256px;
`

export const Title = styled.div`
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0px;
  text-align: left;
  color: #34383c;
`
export const Description = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #565d66;
  margin-top: 24px;
`

export const Pill = styled.div`
  border: ${props => (props.selected ? '0px' : '1px solid #959da8')};
  background: #ffffff;
  border-radius: 100px;
  padding: 4px 12px;
  margin-right: 4px;
  &:hover {
    cursor: pointer;
  }
`
export const PillRow = styled.div`
  margin-top: 4px;
  display: flex;
  .is-active {
    background: #424a54;
    color: #ffffff;
    cursor: pointer;
  }
`
export const PillContainer = styled.div`
  margin-top: 8px;
`
export const RecipientsWrap = styled.div`
  display: flex;
  margin-top: 32px;
`
export const RecipientsCount = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-left: 4px;
`

export const ButtonContainer = styled.div`
  height: 100%;
  width: 100%;
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 10px;
`
export const IconWrapper = styled.div`
  position: absolute;
  right: 25px;
  top: 20px;
`
export const Dash = styled.hr`
  border: 2px solid #34383c;
  width: 40px;
  margin-top: 25px;
`
