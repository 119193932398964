import { SET_AUTHENTICATED_USER } from './types'

export const initialState = {
  me: null
}

export default function campaignsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      return {
        ...state,
        me: action.data
      }
    default:
      return state
  }
}
