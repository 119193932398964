import { addToastAction, removeToastAction } from './actionsCreator'

export const showToast = (message, level = 'success') => dispatch => {
  const toast = {
    id: Math.random(),
    message,
    level
  }
  dispatch(addToastAction(toast))
}

export const hideToast = id => dispatch => {
  dispatch(removeToastAction(id))
}
