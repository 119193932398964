/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container, TableWrapper } from './styles'
import TemplatesPreview from '../TemplatesPreview'
import { useCampaignResultState } from '@src/scenes/CampaignResult/CampaignResultContext'

function TemplateSetTable({ onClose }) {
  const { campaign, stats } = useCampaignResultState()
  const [previewingTemplateId, setPreviewingTemplateId] = useState(null)

  const urlsById = campaign.email_templates.reduce(
    (acc, item) => ({
      ...acc,
      [item.template_id]: item.phishing_url && `https://${item.phishing_url}`
    }),
    {}
  )

  const data = stats?.templates.map(item => ({
    id: item.template_id,
    url: urlsById[item.template_id],
    name: item.template_name
  }))

  return (
    <Container>
      <TableWrapper>
        <table>
          <thead>
            <tr>
              <th>Template Set</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            {data.map(row => (
              <tr>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setPreviewingTemplateId(row.id)
                    }}
                  >
                    {row.name}
                  </a>
                </td>
                <td>{row.url}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
      <TemplatesPreview
        campaingTemplates={data}
        templateId={previewingTemplateId}
        visible={!!previewingTemplateId}
        onBack={() => setPreviewingTemplateId(null)}
        onClose={() => {
          setPreviewingTemplateId(null)
          onClose()
        }}
      />
    </Container>
  )
}

TemplateSetTable.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email_templates: PropTypes.array
  }).isRequired,
  stats: PropTypes.shape({
    templates: PropTypes.array
  })
}

export default TemplateSetTable
