import React from 'react'
import { TitleSection } from '../../styles'
// import PerformanceAnalysisGraphsTabs from './PerformanceAnalysisGraphsTabs'
import { PerformanceAnalysisTableProvider } from './PerformanceAnalysisTable/PerformanceAnalysisTableContext'

export default function PerformanceAnalysis() {
  return (
    <div>
      <TitleSection>Performance Analysis</TitleSection>
      {/* <PerformanceAnalysisGraphsTabs /> */}
      <PerformanceAnalysisTableProvider />
    </div>
  )
}
