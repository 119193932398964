/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const VerticalCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #fdfbf8;
  border-radius: 4px;
  padding: 24px;
  height: 284px;
  min-width: 256px;
`

export const Title = styled.div`
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0px;
  text-align: left;
  color: #34383c;
`

export const SubTitle = styled.div`
  //styleName: Core / Subhead;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #7b828a;
  text-transform: uppercase;
  margin-top: 8px;
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #565d66;
  margin-top: 24px;
`

export const ButtonContainer = styled.div`
  height: 100%;
  width: 100%;
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 10px;
`
