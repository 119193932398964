import styled, { createGlobalStyle } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const TabsContainer = styled.div`
  margin-top: 24px;
  align-self: stretch;
`
export const Scroll = styled.div`
  overflow: auto;
  height: 400px;
`

export const HideModalScroll = createGlobalStyle`
  #reflex-campaign-wizard [class^='Modalstyles__ModalContent'] > div {
    overflow: hidden !important;
  }
`
