import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const Container = styled.div`
  position: relative;
  margin-bottom: 25px;

  ${props =>
    props.fixed
      ? css`
          width: ${props.width || '660px'};
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          top: 30px;
          z-index: 1001;
        `
      : css`
          min-width: 100%;
        `}
  & > div {
    margin-bottom: ${props => (props.count > 1 ? '6px' : '0px')};

    &:last-child {
      margin-bottom: 0;
    }

    p:first-child {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
`

export const toastTheme = {
  ToastColor: {
    info: '#3d7087',
    warn: '#b18341',
    error: '#9a3c3c',
    success: '#308672'
  },
  ToastBackgroundColor: {
    info: 'rgba(227,240,246,0.6)',
    warn: 'rgba(246,238,227,0.6)',
    error: 'rgba(246,227,227,0.6)',
    success: '#e3f6f2'
  },
  ToastBorder: {
    info: '#73acc6',
    warn: '#eac081',
    error: '#cd7575',
    success: '#62b1a0'
  },
  ToastBoxShadow: {
    info: 'rgba(55, 102, 123, 0.4)',
    warn: 'rgba(234, 192, 129, 0.4)',
    error: 'rgba(205, 117, 117, 0.4)',
    success: 'rgba(98, 177, 160, 0.4)'
  }
}

export const ToastWrapper = styled.div`
  position: relative;
  padding: 16px 65px 16px 16px;
  font-size: 14px;
  border-radius: 4px;
  color: ${styledMap('level', toastTheme.ToastColor)};
  background-color: ${styledMap('level', toastTheme.ToastBackgroundColor)};
  border: 1px solid ${styledMap('level', toastTheme.ToastBorder)};
  ${props =>
    props.fixed &&
    css`
      box-shadow: 0 2px 8px 0 ${styledMap('level', toastTheme.ToastBoxShadow)};
    `}
`

export const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 65px;

  & > button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 12px auto auto auto;
    height: 22px;
    width: 22px;
    padding: 5px;

    svg {
      stroke: ${props => props.stroke};
    }
  }
`
