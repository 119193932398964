import React from 'react'
import { useParams, Redirect } from 'react-router-dom'

import PageHeader from '../../components/PageHeader'
import CampaignActions from '../../components/CampaignActions'
import { Tabs, Tab } from '@elevate_security/elevate-component-library'
import StatusBadge from '../../components/StatusBadge'
import ActivityIndicator from '../../components/ActivityIndicator'
import { PageTitle, Group, TabsContainer } from './styles'
import CampaignResultsTab from './CampaignResultsTab'
import CampaignActivityLogTab from './CampaignActivityLogTab'
import { isCampaignInProgress } from '@src/services/utils'
import { ACTION_TYPE } from '@src/components/CampaignActions/utils'
import { breadcrumbLinks, CAMPAIGN_STATUS } from '../../constants'
import { useCampaignResultState } from './CampaignResultContext'

const CampaignResult = () => {
  const params = useParams()
  const { campaign } = useCampaignResultState()

  if (!campaign) return <ActivityIndicator active />

  if (
    campaign.campaign_state !== CAMPAIGN_STATUS.Ended &&
    !isCampaignInProgress(campaign.campaign_state)
  )
    return <Redirect to={`/engagement/reflex2/campaigns/${params.id}`} />

  const renderBreadcrumbs = () => [...breadcrumbLinks, { label: campaign.name }]
  const renderStatusBadge = () => (
    <StatusBadge campaign_state={campaign.campaign_state} />
  )
  const renderActionsIcons = () => (
    <CampaignActions
      campaign={campaign}
      options={{
        ConfirmationText: ' ',
        hideActions: [
          ACTION_TYPE.DELETE,
          ACTION_TYPE.EDIT,
          ACTION_TYPE.RESUME,
          ACTION_TYPE.PAUSE,
          ACTION_TYPE.CANCEL
        ]
      }}
    />
  )
  const renderTitle = () => (
    <PageTitle>
      <Group>
        <span>{campaign.name}</span>
        {renderStatusBadge()}
      </Group>
      {renderActionsIcons()}
    </PageTitle>
  )
  const renderPageHeader = () => (
    <PageHeader title={renderTitle()} breadcrumbs={renderBreadcrumbs()} />
  )
  const renderTabContent = index => {
    switch (index) {
      case 0:
        return <CampaignResultsTab />
      case 1:
        return <CampaignActivityLogTab />
      default:
        return null
    }
  }
  const renderTab = (tab, index) => (
    <Tab title={tab} key={tab}>
      {renderTabContent(index)}
    </Tab>
  )
  const renderTabs = () => (
    <TabsContainer>
      <Tabs>
        {['Campaign Results', 'Campaign Activity Log'].map(renderTab)}
      </Tabs>
    </TabsContainer>
  )
  return (
    <>
      {renderPageHeader()}
      {renderTabs()}
    </>
  )
}

export default CampaignResult
