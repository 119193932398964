import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@elevate_security/elevate-component-library'
import { renderNumberOfRecipients } from './utils'
import { useToggle } from 'ahooks'
import RecipientTable from './RecipientTable'
import { Title, ModalWrapper } from './style'
import { useCampaignResultState } from '@src/scenes/CampaignResult/CampaignResultContext'
import { formatWithCommas } from '@src/services/utils'

function RecipientListModal({ total }) {
  const [showModal, { toggle: toogleShowModal }] = useToggle(false)
  const { campaign } = useCampaignResultState()

  if (total === 0) return renderNumberOfRecipients(total)

  const renderLink = () => (
    <div>
      {renderNumberOfRecipients(total)}{' '}
      {campaign?.mails_sent === 0 ? (
        '(0 successfully sent)'
      ) : (
        <a href="#" onClick={() => toogleShowModal()}>
          ({formatWithCommas(campaign?.mails_sent || 0)} successfully sent)
        </a>
      )}
    </div>
  )

  const renderModal = () => (
    <ModalWrapper>
      <Modal
        style={{ maxWidth: '920px', height: 664 }}
        onClose={() => toogleShowModal()}
        header={<Title>{renderNumberOfRecipients(campaign.mails_sent)}</Title>}
        isOpen={showModal}
      >
        <RecipientTable />
      </Modal>
    </ModalWrapper>
  )

  return (
    <>
      {renderLink()}
      {renderModal()}
    </>
  )
}

RecipientListModal.propTypes = {
  total: PropTypes.number,
  campaign: PropTypes.shape({})
}

export default RecipientListModal
