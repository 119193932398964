import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { List } from './styles'
import BlankCampaignsList from '../BlankCampaignsList'
import CampaignRow from '../CampaignRow'

import { campaignsSelector } from '../../../services/redux/campaigns/selectors'
import { isCreatingCampaign } from '../../../services/redux/wizard/actionsCreator'
import { useHistory } from 'react-router-dom'
import { REFLEX_NEW_CAMPAIGN_PATH } from '@src/constants'

const CampaignsList = ({ activeTab }) => {
  const history = useHistory()
  const { campaignsLoading, campaignList } = useSelector(campaignsSelector)
  const dispatch = useDispatch()
  const { count, results } = campaignList[activeTab]

  const renderItems = useCallback(
    () =>
      results.map(campaign => (
        <CampaignRow key={campaign.id} campaign={campaign} />
      )),
    [results]
  )

  const renderList = () => <List>{renderItems()}</List>

  const handleCreateNewCampaignClick = () => {
    dispatch(isCreatingCampaign(true))
    history.push(REFLEX_NEW_CAMPAIGN_PATH)
  }

  const renderEmptyPage = () => {
    if (campaignsLoading || count) return null
    return (
      <BlankCampaignsList
        campaignType="Reflex"
        onClick={handleCreateNewCampaignClick}
      />
    )
  }

  return (
    <>
      {renderList()}
      {renderEmptyPage()}
    </>
  )
}

CampaignsList.propTypes = {
  activeTab: PropTypes.string.isRequired
}

export default CampaignsList
