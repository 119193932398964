import styled, { css } from 'styled-components'
import { Typography, theme } from '@elevate_security/elevate-component-library'

const { Small, Text, A } = Typography

export const ModalComponent = styled.div.attrs(props => ({
  ...props,
  width: '1096px',
  maxWidth: '1096px',
  maxHeight: '750px',
  height: 'calc(100% - 50px)',
  borderRadius: '8px'
}))`
  > [class^='Modalstyles__ModalContainer'] {
    height: ${props => props.height};
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    max-height: ${props => props.maxHeight};
    border-radius: ${props => props.borderRadius};
    padding: 40px;

    > [class^='Modalstyles__ModalContent'] {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: flex-start;
      padding: 0;
    }

    > [class^='Modalstyles__ModalHeader'],
    > [class^='Modalstyles__ModalFooter'] {
      display: 'flex';
    }
  }
`

export const HeaderMetadata = styled(Small)`
  opacity: 0.8;
  text-transform: uppercase;
  padding-bottom: 12px;
  font-size: 12px;
`

export const RouteContainer = styled.section`
  flex: 1;
  height: 100%;
  min-height: 100%;
  padding: ${props => props.padding || '32px 190px 0 190px'};
  margin: ${props => props.margin || 0};
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'flex-start'};
`

export const Form = styled.form`
  min-width: 100%;
`

export const FieldSet = styled.div.attrs(props => ({
  ...props,
  direction: !props.direction ? 'column' : props.direction
}))`
  position: relative;
  display: flex;
  flex: 1 0 ${props => props.width || 'auto'};
  flex-direction: ${props => props.direction || 'column'};
  line-height: 30px;
  max-width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};
  max-height: ${props => props.height || 'auto'};
  margin-bottom: ${props => props.gap || '24px'};
  ${props =>
    props.direction === 'row'
      ? css`
          margin-right: ${props.gap || '24px'};
        `
      : null}

  ${props =>
    props.width &&
    css`
      & > div {
        flex: 1;
        input {
          min-width: 100%;
          max-width: 100%;
        }
      }
    `}
`

export const Label = styled(Text)`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 14px;
  color: #34383c;
  max-width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};
`

export const SaveForLaterLink = styled(A)`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  color: ${theme.text.color.link};
  &:hover {
    color: ${theme.text.color.linkHover};
  }
`
