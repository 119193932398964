import { getPercentageString } from '../../../../services/utils'

export const normalizeCampaignStats = (stats, campaign) => {
  const { clicked, posted, no_action, opened, reported, mails_sent, not_trackable_opened } =
    stats?.total || {}
  const {
    mails_expired,
    mails_permanent_error,
    mails_total,
    mails_address_error,
    scheduled_start
  } = campaign || {}
  const totalUndeliveredMails =
    mails_address_error + mails_expired + mails_permanent_error

  const emailOpenRate = getPercentageString(opened, mails_sent)
  const notTrackableEmailOpenRate = getPercentageString(not_trackable_opened, mails_sent)
  const linkClickRate = getPercentageString(clicked, mails_sent)
  const credentialPostingRate = getPercentageString(posted, mails_sent)
  const emailReportingRate = getPercentageString(reported, mails_sent)
  const noActionRate = getPercentageString(no_action, mails_sent)
  const undeliveredRate = getPercentageString(
    totalUndeliveredMails,
    mails_total
  )
  return {
    ...stats,
    totalUndeliveredMails,
    mails_total,
    scheduled_start,
    percentage: {
      openedRate: emailOpenRate,
      notTrackableEmailOpenRate: notTrackableEmailOpenRate,
      clickedRate: linkClickRate,
      credentialRate: credentialPostingRate,
      reportingRate: emailReportingRate,
      noActionRate: noActionRate,
      undeliveredRate: undeliveredRate
    }
  }
}
