import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import {
  Modal,
  Button,
  Header,
  Typography
} from '@elevate_security/elevate-component-library'

const { H2, Text } = Typography

const ConfirmationModal = props => {
  const {
    isOpen,
    onClose,
    onConfirm,
    headerText,
    confirmText,
    dismissText,
    bodyText,
    helpText
  } = props

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') onClose()
  }

  const handleOnConfirm = () => {
    if (onConfirm && typeof onConfirm === 'function') onConfirm()
  }

  const renderHeader = (
    <Header>
      <H2 color="900">{headerText}</H2>
    </Header>
  )

  const renderBody = (
    <Fragment>
      <div>
        <Text fontWeight="normal">{bodyText}</Text>
        <br />
        <br />
      </div>
      {helpText && <Text fontWeight="normal">{helpText}</Text>}
    </Fragment>
  )

  const renderButtons = (
    <Fragment>
      <Button key="no" theme="ghost" onClick={handleOnClose}>
        {dismissText}
      </Button>
      <Button key="yes" onClick={handleOnConfirm}>
        {confirmText}
      </Button>
    </Fragment>
  )
  return (
    <Modal
      data-id="hm-sessions-delete-confirmation"
      style={{ maxWidth: '700px', maxHeight: '350px' }}
      onClose={handleOnClose}
      header={renderHeader}
      rightButtons={renderButtons}
      isOpen={isOpen}
    >
      {renderBody}
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  headerText: PropTypes.string,
  confirmText: PropTypes.string,
  dismissText: PropTypes.string,
  bodyText: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func
}

ConfirmationModal.defaultProps = {
  isOpen: false,
  headerText: 'Are you sure?',
  confirmText: 'Yes',
  dismissText: 'No',
  helpText: '',
  onClose: null
}

export default ConfirmationModal
