import styled from 'styled-components'
import { ButtonLink } from '@elevate_security/elevate-component-library'

export const SendEmailButton = styled(ButtonLink)`
  background: #000;
  margin-top: 24px;
  margin-left: 20px;

  & > svg {
    margin-left: 8px;
  }
`

export const Flex = styled.div`
  display: flex;
  flex-grow: ${props => (props.grow >= 0 ? props.grow : 1)};
  flex-shrink: ${props => (props.shrink >= 0 ? props.shrink : 1)};
  flex-basis: ${props => (props.basis ? props.basis : 'auto')};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'flex-start'};
  align-self: ${props => props.self || 'initial'};
`

export const FlexColumn = styled(Flex)`
  flex-direction: column;
  min-height: ${props => (props.stretch ? '100%' : 'auto')};
  max-height: ${props => (props.stretch ? '100%' : 'auto')};
  width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};

  & > * {
    margin-bottom: ${props => props.gap || 0};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Content = styled(FlexColumn)`
  padding-bottom: 30px;

  > img {
    margin-bottom: 16px;
  }
  h3 {
    margin: auto;
    margin-bottom: 16px;
    border-bottom: 1px dotted #565d66;
    cursor: pointer;
  }
  > span {
    padding-top: 8px;
  }

  label {
    line-height: 24px;
    margin-bottom: 0;
  }

  [class^='Typography__Text'] {
    font-size: 16px;
  }
`
export const TextWrapper = styled.div`
  -webkit-margin-after: 30px;
  white-space: nowrap;
  display: flex;
  padding-top: 4px;
  [class^='Typography__Text'] {
    font-size: 20px;
  }
`
export const WordSpacing = styled.span`
  padding: 0 4px;
`
