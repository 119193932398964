import { sanitizeContent } from '../../utils'

export function getSubdomain() {
  const { host } = window.location
  return host.split('.')[0].split('-pr')[0]
}

export function getErrorStatus({ error }) {
  if (error && error.response && error.response.status)
    return { status: error.response.status, error }
  return { status: 'unknown', error }
}

export const getToastObjectFromError = (containerId = null) => error => {
  const toast = {
    id: Math.random(),
    level: 'error',
    message: sanitizeContent(error?.response?.data?.detail) || 'Error!',
    containerId
  }
  return toast
}
