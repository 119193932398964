import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@elevate_security/elevate-component-library'
import { renderNumberOfTemplates } from './utils'
import { useToggle } from 'ahooks'
import RecipientTable from './TemplateSetTable'
import Button from '@elevate_security/elevate-component-library/dist/Button'
import { Title } from './style'

function TemplateSetModal({ templatesCount }) {
  const [showModal, { toggle: toogleShowModal }] = useToggle(false)

  const handleToggle = () => toogleShowModal()

  const renderLink = () => (
    <a href="#" onClick={handleToggle}>
      {renderNumberOfTemplates(templatesCount)}
    </a>
  )

  const renderModal = () => (
    <Modal
      onClose={handleToggle}
      header={<Title>{renderNumberOfTemplates(templatesCount)}</Title>}
      isOpen={showModal}
      rightButtons={<Button onClick={handleToggle}>Done Reviewing</Button>}
    >
      <RecipientTable onClose={handleToggle} />
    </Modal>
  )

  return (
    <>
      {renderLink()}
      {renderModal()}
    </>
  )
}

TemplateSetModal.propTypes = {
  templatesCount: PropTypes.number
}

export default TemplateSetModal
