import styled from 'styled-components'

import { Typography } from '@elevate_security/elevate-component-library'
import theme from '../../../../css/theme'
import { Link as RouterLink } from 'react-router-dom'

const { colors } = theme
const { H2 } = Typography

export const Heading = styled(H2).attrs({
  fontWeight: 'bold'
})`
  color: ${colors.text.link};
  font-size: 18px;
  display: flex;
  margin-right: 8px;

  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.text.linkHover};
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 45px;
`
export const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
  }
`
export const Link = styled(RouterLink)`
  color: #7b828a;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  span {
    text-decoration: underline;
  }
`
