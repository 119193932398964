import React, { useState } from 'react'
import * as campaignsApi from '@src/services/apis/campaigns'
import { useRequest } from 'ahooks'
import ActivityIndicator from '@src/components/ActivityIndicator'
import { useCampaignResultState } from '@src/scenes/CampaignResult/CampaignResultContext'
import axiosInstance from '@src/services/apis/fetcher'
import PerformanceAnalysisTable from '.'

/** action types */
export const CLEAR = 'CLEAR'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const SET_RESULTS = 'SET_RESULTS'
export const SET_META = 'SET_META'
export const SET_TABLE_INFO = 'SET_TABLE_INFO'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_FILTER_BY = 'SET_FILTER_BY'

/** action creators */
export const clear = () => ({ type: CLEAR })
export const clearFilters = () => ({ type: CLEAR_FILTERS })
export const setResults = results => ({ type: SET_RESULTS, results })
export const setMeta = meta => ({ type: SET_META, meta })
export const setTableInfo = tableInfo => ({ type: SET_TABLE_INFO, tableInfo })
export const setFilters = filters => ({ type: SET_FILTERS, filters })
export const setFilterBy = filterBy => ({ type: SET_FILTER_BY, filterBy })

/** initial value */
const initialFiltersValues = {
  filterBy: '',
  opened: [],
  clicked: [],
  reported: [],
  posted: [],
  not_trackable_opened: [],
  department: [],
  location: [],
  tenure: []
}
const initialState = {
  results: [],
  meta: [],
  filters: initialFiltersValues,
  hideClearFilter: true,
  tableInfo: {
    page: 1,
    size: 10,
    sort: 'first_name'
  }
}

/** helpers */
const handleFilter = key => (state, action) => {
  if (action.filters[key] === undefined || action.filters[key] === null)
    return {}
  if (state.filters[key].includes(action.filters[key]))
    return {
      [key]: state.filters[key].filter(value => value !== action.filters[key])
    }
  else
    return {
      [key]: [...state.filters[key], action.filters[key]]
    }
}

const checkIfShouldHide = filters => {
  return JSON.stringify(filters) === JSON.stringify(initialFiltersValues)
}

/** reducer */
export function reducer(state, action) {
  switch (action.type) {
    case CLEAR:
      return initialState
    case SET_RESULTS:
      return { ...state, results: action.results }
    case SET_META:
      return { ...state, meta: action.meta }
    case SET_FILTER_BY: {
      const newFilters = {
        ...state.filters,
        filterBy: action.filterBy
      }
      return {
        ...state,
        filters: newFilters,
        tableInfo: {
          ...state.tableInfo,
          page: 1
        },
        hideClearFilter: checkIfShouldHide(newFilters)
      }
    }
    case SET_FILTERS: {
      const newFilters = {
        ...state.filters,
        ...action.filters,
        ...handleFilter('opened')(state, action),
        ...handleFilter('clicked')(state, action),
        ...handleFilter('reported')(state, action),
        ...handleFilter('posted')(state, action),
        ...handleFilter('department')(state, action),
        ...handleFilter('location')(state, action),
        ...handleFilter('tenure')(state, action)
      }
      return {
        ...state,
        filters: newFilters,
        tableInfo: {
          ...state.tableInfo,
          page: 1
        },
        hideClearFilter: checkIfShouldHide(newFilters)
      }
    }
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: initialFiltersValues,
        hideClearFilter: true
      }
    case SET_TABLE_INFO:
      return { ...state, tableInfo: action.tableInfo }
    default:
      return state
  }
}

/** contexts */
const PerformanceAnalysisTableStateContext = React.createContext(
  undefined,
  undefined
)
const PerformanceAnalysisTableDispatchContext = React.createContext(
  undefined,
  undefined
)

/** actions */
export const getVictimsFromCampaign = dispatch => async (id, state) => {
  const { tableInfo, filters } = state

  const checkboxFilters = {
    // Yes/No both selected is equivalent to none selected
    ...(filters.opened.length === 1 ? { opened: filters.opened[0] } : {}),
    ...(filters.clicked.length === 1 ? { clicked: filters.clicked[0] } : {}),
    ...(filters.reported.length === 1 ? { reported: filters.reported[0] } : {}),
    ...(filters.posted.length === 1 ? { posted: filters.posted[0] } : {}),
    ...(filters.department.length > 0
      ? { department: filters.department.join(',') }
      : {}),
    ...(filters.location.length > 0
      ? { location: filters.location.join('|') }
      : {}),
    // all tenure filters selected (totoal 7) is equivalent to no tenure selected
    ...(filters.tenure.length > 0 && filters.tenure.length < 7
      ? { tenure: filters.tenure.join(',') }
      : {})
  }

  const response = await campaignsApi.getVictimsFromCampaign(id, {
    ...tableInfo,
    filter__by: filters.filterBy,
    ...checkboxFilters
  })
  dispatch(setResults(response?.data?.results || []))
  dispatch(setMeta(response?.data?.meta || {}))
}

function PerformanceAnalysisTableProvider() {
  const { campaign } = useCampaignResultState()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [actions] = useState({
    getVictimsFromCampaign: getVictimsFromCampaign(dispatch)
  })

  const { loading } = useRequest(
    () => actions.getVictimsFromCampaign(campaign.id, state),
    {
      requestMethod: param => axiosInstance(param),
      refreshDeps: [state.tableInfo, state.filters],
      ready: state.tableInfo,
      debounceInterval: 500
    }
  )

  return (
    <PerformanceAnalysisTableStateContext.Provider value={state}>
      <PerformanceAnalysisTableDispatchContext.Provider value={dispatch}>
        <div style={{ position: 'relative' }}>
          <PerformanceAnalysisTable />
          <ActivityIndicator active={loading} />
        </div>
      </PerformanceAnalysisTableDispatchContext.Provider>
    </PerformanceAnalysisTableStateContext.Provider>
  )
}

/** hooks */
function usePerformanceAnalysisTableState() {
  const context = React.useContext(PerformanceAnalysisTableStateContext)
  if (context === undefined) {
    throw new Error(
      'usePerformanceAnalysisTableState must be used within a PerformanceAnalysisTableProvider'
    )
  }
  return context
}

function usePerformanceAnalysisTableDispatch() {
  const context = React.useContext(PerformanceAnalysisTableDispatchContext)
  if (context === undefined) {
    throw new Error(
      'usePerformanceAnalysisTableDispatch must be used within a PerformanceAnalysisTableProvider'
    )
  }
  return context
}

export {
  PerformanceAnalysisTableProvider,
  usePerformanceAnalysisTableState,
  usePerformanceAnalysisTableDispatch
}
