/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'

import { ParticipantsContainer } from './styles'
import { formatWithCommas } from '../../../../services/utils'

const ParticipantsInfo = ({ count }) => {
  const LabelPluralized = () => (
    <span>{pluralize('Participant', count || 0)}</span>
  )

  return (
    <ParticipantsContainer>
      <h2>{formatWithCommas(count)}</h2>
      <LabelPluralized />
    </ParticipantsContainer>
  )
}

ParticipantsInfo.propTypes = {
  count: PropTypes.number
}

ParticipantsInfo.defaultProps = {
  count: 0
}

export default ParticipantsInfo
