import fetcher from '../fetcher'
import { getErrorStatus } from '../fetcher/utils'
import { BASES_REFLEX_API_URL } from '../../../constants'
import moment from 'moment'

/**
 * GET /campaigns
 * @param {object} params
 */
export async function getReflexCampaigns(params = {}) {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/campaigns`,
      {
        params: {
          ...params,
          sort: '-modified_at'
        }
      }
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * POST /campaigns
 * @param {object} payload
 */
export async function createReflexCampaign(payload) {
  try {
    const { status, data } = await fetcher.post(
      `${BASES_REFLEX_API_URL}/campaigns`,
      payload
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * GET /campaign/:id
 * @param {number} id
 */
export async function getReflexCampaignById(id) {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/campaigns/${id}`
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * GET /campaigns/:id/download/:file_type
 * @param {number} id
 */
export async function downloadReflexCampaignResults(id, filtType = 'csv') {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/campaigns/${id}/download/${filtType}`
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}


/**
 * PATCH /campaigns/:id
 * @param {object} payload
 */
export async function updateReflexCampaign(id, payload) {
  try {
    const { status, data } = await fetcher.patch(
      `${BASES_REFLEX_API_URL}/campaigns/${id}`,
      payload
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * DELETE /campaigns/:id
 * @param {number} id
 */
export async function deleteReflexCampaign(id) {
  try {
    const { status } = await fetcher.delete(
      `${BASES_REFLEX_API_URL}/campaigns/${id}`
    )
    return { status }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * POST /campaigns/:id/clone
 * @param {number} id
 * @param {object} payload
 */
export async function cloneReflexCampaign(id, payload) {
  try {
    const { status, data } = await fetcher.post(
      `${BASES_REFLEX_API_URL}/campaigns/${id}/clone`,
      payload
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * PUT /campaigns/:id/state
 * @param {number} id
 * @param {srting} state
 */
export async function updateReflexStateCampaign(id, state) {
  try {
    const { status, data } = await fetcher.put(
      `${BASES_REFLEX_API_URL}/campaigns/${id}/state`,
      `"${state}"`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * POST /campaigns/:id/state
 * @param {number} id
 * @param {srting} state
 */
export async function sendTestCampaignEmail(
  id,
  { victim_email, victim_first_name, victim_last_name }
) {
  try {
    const {
      status,
      data
    } = await fetcher.post(
      `${BASES_REFLEX_API_URL}/campaigns/${id}/test_email`,
      { victim_email, victim_first_name, victim_last_name }
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * GET /campaigns/:id/activity_logs
 * @param {object} id
 */
export async function getActivityLogsFromCampaign(id, params) {
  const formatActivityLog = activityLog => {
    try {
      return {
        ...activityLog,
        time: moment(activityLog.time).format('M/D/YYYY h:mm A [UTC]') || 'N/A'
      }
    } catch (error) {
      return activityLog
    }
  }

  const formatActivityLogResults = data => {
    if (!data) return null

    const { results, ...rest } = data
    if (!results) return { results, ...rest }

    const newResult = results.map(formatActivityLog)
    return { results: newResult, ...rest }
  }

  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/campaigns/${id}/activity_logs`,
      {
        params
      }
    )
    return { status, data: formatActivityLogResults(data) }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * GET /campaigns/:id/activity_logs/download
 * @param {object} id
 */
export async function downloadActivityLog(id, params) {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/campaigns/${id}/activity_logs/download`,
      {
        params
      }
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * GET /campaigns/:id/stats
 * @param {object} id
 */
export async function getActivityStatsFromCampaign(id, params) {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/campaigns/${id}/stats`,
      {
        params
      }
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}

/**
 * GET /campaigns/{campaign_id}/victims
 * @param {object} id
 */
export async function getVictimsFromCampaign(id, params) {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/campaigns/${id}/victims`,
      {
        params
      }
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
