import { combineReducers, compose, applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

import reflexReducer from './app/reducer'
import campaignsReducer from './campaigns/reducer'
import wizardReducer from './wizard/reducer'
import toastsReducer from './toasts/reducer'

export const rootReducer = combineReducers({
  reflexReducer,
  campaignsReducer,
  wizardReducer,
  toastsReducer
})

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
)

const store = createStore(rootReducer, enhancer)

export default store
