export const FILTER_TYPE = {
  MULTI_SELECT: 'multi-select'
}

export const options = (departments, locations) => [
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: 'OPENED',
    key: 'opened',
    items: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: 'LINK CLICKED',
    key: 'clicked',
    items: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: 'CREDENTIALS POSTED',
    key: 'posted',
    items: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: 'REPORTED',
    key: 'reported',
    items: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: 'DEPARTMENTS',
    key: 'department',
    items: mapArrayOfStringsToOptions(departments),
    ...(departments &&
      departments.length > 4 && {
        showMore: 'Show all departments',
        showLess: 'Show less departments'
      })
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: 'LOCATIONS',
    key: 'location',
    items: mapArrayOfStringsToOptions(locations),
    ...(locations &&
      locations.length > 4 && {
        showMore: 'Show all locations',
        showLess: 'Show less locations'
      })
  },
  {
    type: FILTER_TYPE.MULTI_SELECT,
    title: 'TENURE',
    key: 'tenure',
    items: [
      { label: '< 6 months', value: '< 6 months' },
      { label: '6 - 12 months', value: '6 - 12 months' },
      { label: '1 - 2 years', value: '1 - 2 years' },
      { label: '3 - 5 years', value: '3 - 5 years' },
      { label: '5 - 10 years', value: '5 - 10 years' },
      { label: '10 - 20 years', value: '10 - 20 years' },
      { label: '20+ years', value: '20+ years' }
    ],
    showMore: 'Show all tenure lengths',
    showLess: 'Show less tenure lengths'
  }
]

const mapArrayOfStringsToOptions = array => {
  return array?.sort()?.map(string => ({
    label: string,
    value: string
  }))
}

export const getDropdownOptions = (departments, locations) => [
  ...options(departments, locations)
]
