import styled from 'styled-components'

export const TableWrapper = styled.div`
  padding: 0;
  [class^='Tablestyles__TableContainer'] {
    padding: 0 !important;
    [class^='Tablestyles__TableNavigationInfo'] {
      font-weight: normal;
    }
    .table-body {
      height: 390px;
    }
    .table-row {
      padding: 5px 0 !important;
      margin-top: 0 !important;

      .table-data {
        padding: 0 12px;
        font-weight: normal;
      }

      [class^='Tablestyles__TableData'] {
        a {
          width: 100%;
          padding-right: 10px;
        }
        padding-right: 30px;
        &:nth-child(1),
        &:nth-child(3) {
          span.span {
            color: #0c7d84;
            cursor: default;
            display: flex;
          }
        }
      }
    }
  }
`
export const Container = styled.div`
  position: relative;
`
