import React, { useState, useEffect } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  matchPath
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@elevate_security/elevate-component-library/dist/Button'
import ActivityIndicator from '../../components/ActivityIndicator'
import PageHeader from '../../components/PageHeader'
import CampaingsTabs from './CampaingsTabs'
import CampaignsList from './CampaignsList'
import {
  breadcrumbLinks,
  campaignTabNames,
  REFLEX_NEW_CAMPAIGN_PATH,
  REFLEX_EDIT_CAMPAIGN_BASE_PATH,
  REFLEX_CAMPAIGNS_BASE_PATH
} from '../../constants'
import CampaignWizard from '../CampaignWizard'
import { Container } from './styles'
import { isCreatingCampaign } from '../../services/redux/wizard/actionsCreator'
import {
  campaignsSelector,
  campaignStatusCount
} from '../../services/redux/campaigns/selectors'

const Campaings = () => {
  const [activeTab, setActiveTab] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { campaignsLoading, campaignsLoaded } = useSelector(campaignsSelector)
  const counter = useSelector(campaignStatusCount)

  useEffect(() => {
    // Set the default tab of campaigns
    if (campaignsLoaded && activeTab === null) {
      /**
       * Default should be In-Progress
       * If no campaigns, default should be Scheduled
       * If no campaigns, default should be All
       */
      if (counter && !!counter[campaignTabNames.IN_PROGRESS])
        setActiveTab(campaignTabNames.IN_PROGRESS)
      else if (counter && !!counter[campaignTabNames.SCHEDULED])
        setActiveTab(campaignTabNames.SCHEDULED)
      else setActiveTab(campaignTabNames.ALL)
    }
  }, [campaignsLoaded])

  const isCampaignsListPath =
    matchPath(location.pathname, {
      path: REFLEX_CAMPAIGNS_BASE_PATH,
      exact: true,
      strict: false
    }) !== null

  const createNewCampaign = () => {
    dispatch(isCreatingCampaign(true))
    history.push(REFLEX_NEW_CAMPAIGN_PATH)
  }

  const Header = () => (
    <>
      <PageHeader title="Campaigns" breadcrumbs={breadcrumbLinks} />
      <Button onClick={createNewCampaign}>Create New Campaign</Button>
    </>
  )

  return (
    <div>
      <Header />
      <Container>
        {activeTab && (
          <CampaingsTabs
            activeTab={activeTab}
            onTabChange={setActiveTab}
            counter={counter}
          />
        )}
        {activeTab && <CampaignsList activeTab={activeTab} />}
        <ActivityIndicator
          active={campaignsLoading && isCampaignsListPath}
          margin="200px auto"
          opacity="0.3"
        />
      </Container>
      <Switch>
        <Route
          path={[REFLEX_NEW_CAMPAIGN_PATH, REFLEX_EDIT_CAMPAIGN_BASE_PATH]}
          component={CampaignWizard}
        />
      </Switch>
    </div>
  )
}

export default Campaings
