import React from 'react'
import PropTypes from 'prop-types'

import {
  Tabs,
  Tab,
  Typography
} from '@elevate_security/elevate-component-library'
import { campaignsTabs } from '../../../constants'

const CampaingsTabs = ({ activeTab, onTabChange, counter }) => {
  const handleOnChange = index => {
    const { name } = campaignsTabs[index]
    onTabChange(name)
  }

  const titleWithCount = name => (
    <Typography.Text>{`${name} (${counter[name] || 0})`}</Typography.Text>
  )

  const renderTab = tab => (
    <Tab
      key={tab.name}
      selected={activeTab === tab.name}
      title={titleWithCount(tab.name)}
    ></Tab>
  )

  return (
    <Tabs onChange={index => handleOnChange(index)}>
      {campaignsTabs?.map(renderTab)}
    </Tabs>
  )
}

CampaingsTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  counter: PropTypes.shape({})
}

export default CampaingsTabs
