import { SET_WIZARD_FORM } from './types'
import { getWizardForm, getCampaignInstance } from './selectors'
import * as api from '../../apis/campaigns'
import { setReflexCampaignList } from '../campaigns/actions'
import { CAMPAIGN_STATUS } from '../../../constants'
import { addToastAction, toastErrorHelper } from '../toasts/actionsCreator'
import { campaignLoading, setWizardCampaign } from './actionsCreator'
import { getToastObjectFromError } from '@src/services/apis/fetcher/utils'

export function setWizardForm(form) {
  return { type: SET_WIZARD_FORM, data: form }
}

export function submitCampaign() {
  const getToastFromError = getToastObjectFromError('modal-toast-manager')
  return async (dispatch, getState) => {
    try {
      dispatch(campaignLoading(true))

      const campaign = getCampaignInstance(getState())
      // eslint-disable-next-line no-unused-vars
      const campaignId = campaign.id()
      const form = getWizardForm(getState())

      /**
       * Before Hook
       * This hook can be used to update or change values before
       * the form is submitted to the server.
       */
      const updatedForm = form.runCallbacks('before')
      const { id, campaign_state, ...payload } = updatedForm.value()
      if (
        id &&
        campaign_state &&
        campaign_state === CAMPAIGN_STATUS.Scheduled
      ) {
        const { error: error1 } = await api.updateReflexCampaign(id, {
          current_wizard_step: 6
        })
        if (error1) {
          const toast = getToastFromError(error1)
          dispatch(addToastAction(toast))
          throw new Error(error3.response?.data?.detail)
        }
        const { error: error2 } = await api.updateReflexStateCampaign(
          id,
          'Scheduled'
        )
        if (error2) {
          const toast = getToastFromError(error2)
          dispatch(addToastAction(toast))
          throw new Error(error3.response?.data?.detail)
        }

        return { data: { id } }
      }
      // eslint-disable-next-line no-unused-vars
      const { status, data, error: error3 } = id
        ? await api.updateReflexCampaign(id, payload)
        : await api.createReflexCampaign(payload)

      if (error3) {
        const toast = getToastFromError(error3)
        dispatch(addToastAction(toast))
        throw new Error(error3.response?.data?.detail)
      }

      dispatch(setWizardCampaign(data))

      if (!id) dispatch(setReflexCampaignList())

      return { status, data, error: error3 }
    } catch (error) {
      dispatch(toastErrorHelper('An error has happened. Try again.'))
      throw error
    } finally {
      dispatch(campaignLoading(false))
    }
  }
}

export const setReflexCampaign = id => {
  return async dispatch => {
    try {
      dispatch(campaignLoading(true))

      // eslint-disable-next-line no-unused-vars
      const { status, data } = await api.getReflexCampaignById(id)

      dispatch(setWizardCampaign(data))
    } catch (error) {
      console.error(error)
      dispatch(toastErrorHelper('An error has happened. Try again.'))
    } finally {
      dispatch(campaignLoading(false))
    }
  }
}
