import React from 'react'

import { TemplateName } from './styles'
import { Icon, Tooltip } from '@elevate_security/elevate-component-library'

export const columns = [
  {
    key: 'template_name',
    header: ' ',
    isHeadSort: false
  },
  {
    key: 'mails_sent',
    header: 'Recipients'.toUpperCase(),
    isHeadSort: false
  },
  {
    key: 'opened',
    header: 'Opened'.toUpperCase(),
    isHeadSort: false
  },
  {
    key: 'clicked',
    header: 'Link clicked'.toUpperCase(),
    isHeadSort: false
  },
  {
    key: 'posted',
    header: 'Creds Posted'.toUpperCase(),
    isHeadSort: false
  },
  {
    key: 'reported',
    header: 'Reported'.toUpperCase(),
    isHeadSort: false
  },
  {
    key: 'no_action',
    header: 'No Action'.toUpperCase(),
    isHeadSort: false
  }
]

export const renderData = data =>
  data.map(template => {
    return {
      ...template,
      template_name:
        template.mails_sent === 0 ? (
          <TemplateName>
            <span className="template-name">{template.template_name}</span>
            <Tooltip
              body={
                <div>
                  Due to the random nature of template set assignment,{' '}
                  <strong>{template.template_name}</strong> was not sent to any
                  recipients in this campaign.
                </div>
              }
              placement="top"
              size="sm"
              readOnly
            >
              <Icon name="CircleExclamation" size="xsm" fill="#CB7D20" />
            </Tooltip>
          </TemplateName>
        ) : (
          template.template_name
        )
    }
  })
