import React from 'react'
import { TitleSection } from '../../styles'
import { VerticalCardsContainer } from './styles'
import CampaignSummaryCard from './CampaignSummaryCard'
import CampaignSummaryAdittionalCard from './CampaignSummaryAdditionalCard'
import { getSummaryDetailsFrom } from '../CampaignSummary/CampaignSummaryCard/utils'
import CampaignCustomCard from '../CampaignSummary/CampaignCustomCard'
import { normalizeCampaignStats } from './utils'
import { useCampaignResultState } from '../../CampaignResultContext'

export default function CampaignSummary() {
  const { campaign, stats } = useCampaignResultState()
  if (!stats || !campaign) return null
  const normalizedStats = normalizeCampaignStats(stats, campaign)
  const summaryDetails = getSummaryDetailsFrom(normalizedStats, campaign)

  const renderSummaryCard = card => <CampaignSummaryCard {...card} />
  const renderSummaryCards = () => (
    <VerticalCardsContainer>
      {summaryDetails.map(renderSummaryCard)}
      <CampaignCustomCard />
      <CampaignSummaryAdittionalCard />
    </VerticalCardsContainer>
  )

  return (
    <div>
      <TitleSection>Campaign Summary</TitleSection>
      {renderSummaryCards()}
    </div>
  )
}
