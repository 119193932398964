import CampaignInfoStep from './components/CampaignInfoStep'
import RecipientsStep from './components/RecipientsStep'
import TemplatesStep from './components/TemplatesStep'
import NotificationsStep from './components/NotificationsStep'
import SchedulingStep from './components/SchedulingStep'
import ConfirmStep from './components/ConfirmStep'
import { STEP_PATH } from './utils'
import TemplatesPreview from './components/TemplatesPreview'

const routes = [
  {
    exact: true,
    path: STEP_PATH.CAMPAIGN_INFO,
    component: CampaignInfoStep
  },
  {
    exact: true,
    path: STEP_PATH.RECIPIENTS,
    component: RecipientsStep
  },
  {
    exact: true,
    path: STEP_PATH.TEMPLATES,
    component: TemplatesStep
  },
  {
    exact: true,
    path: STEP_PATH.TEMPLATES_PREVIEW,
    component: TemplatesPreview
  },
  {
    exact: true,
    path: STEP_PATH.NOTIFICATIONS,
    component: NotificationsStep
  },
  {
    exact: true,
    path: STEP_PATH.SCHEDULING,
    component: SchedulingStep
  },
  {
    exact: true,
    path: STEP_PATH.CONFIRM,
    component: ConfirmStep
  }
]

export default routes
