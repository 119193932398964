import React from 'react'
import moment from 'moment'
import pluralize from 'pluralize'
import { formatWithCommas } from '../../../../../services/utils'

const renderUndeliveredRateDescription = (
  mails_total,
  totalUndeliveredMails
) => {
  if (mails_total === 0) return 'No emails were delivered in this campaign'
  if (totalUndeliveredMails === 0)
    return 'All emails in this campaign were properly delivered!'
  return (
    <div>
      <strong>{formatWithCommas(totalUndeliveredMails)}</strong>
      {' out of the '}
      <strong>{formatWithCommas(mails_total)}</strong>{' '}
      {pluralize('email', mails_total)}
      {' targeted in this campaign could not be properly delivered '}
    </div>
  )
}

export const getSummaryDetailsFrom = (stats, campaign) => {
  if (!stats) return []
  const {
    percentage: {
      openedRate,
      notTrackableEmailOpenRate,
      clickedRate,
      credentialRate,
      reportingRate,
      noActionRate,
      undeliveredRate
    },
    totalUndeliveredMails,
    mails_total,
    total: { clicked, posted, no_action, opened, reported, mails_sent, not_trackable_opened }
  } = stats || {}
  return [
    {
      title: formatWithCommas(mails_sent),
      subtitle: '# of Emails Sent',
      description: (
        <div>
          <strong>{formatWithCommas(mails_sent)}</strong> Reflex{' '}
          {pluralize('email', mails_sent)} {mails_sent === 1 ? 'was' : 'were'}{' '}
          sent out to {pluralize('recipient', mails_total, false)} on{' '}
          {moment(campaign.mail_start).format('MMM D, YYYY')}
        </div>
      )
    },
    {
      title: openedRate,
      subtitle: 'Email open rate',
      description: (
        <div>
          Out of <strong>{formatWithCommas(mails_sent)}</strong>{' '}
          {pluralize('email', mails_sent)} with open tracking,{' '}
          <strong>{formatWithCommas(opened)}</strong>{' '}
          {pluralize('email', opened)} {opened === 1 ? 'was' : 'were'} opened
        </div>
      )
    },
    {
      title: notTrackableEmailOpenRate,
      subtitle: 'Rate of emails not trackable for open event',
      description: (
          <div>
            Out of <strong>{formatWithCommas(mails_sent)}</strong>{' '}
            {pluralize('email', mails_sent)} with open tracking,{' '}
            <strong>{formatWithCommas(not_trackable_opened)}</strong>{' '}
            {pluralize('email', not_trackable_opened)} {not_trackable_opened === 1 ? 'was' : 'were'} not trackable
          </div>
      )
    },
    {
      title: clickedRate,
      subtitle: 'Link click rate',
      description: (
        <div>
          Out of <strong>{formatWithCommas(mails_sent)}</strong>{' '}
          {pluralize('phishing link', mails_sent)},{' '}
          <strong>{formatWithCommas(clicked)}</strong>{' '}
          {pluralize('link', clicked)} {clicked === 1 ? 'was' : 'were'} clicked
        </div>
      ),
      onClick: () => {}
    },
    {
      title: credentialRate,
      subtitle: 'Credential Posting Rate',
      description: (
        <div>
          <strong>{formatWithCommas(posted)}</strong>{' '}
          {pluralize('recipient', posted)} posted credentials
        </div>
      ),
      onClick: () => {}
    },
    {
      title: reportingRate,
      subtitle: 'Email reporting rate',
      description: (
        <div>
          <strong>{formatWithCommas(reported)}</strong>{' '}
          {pluralize('email', reported)} {reported === 1 ? 'was' : 'were'}{' '}
          reported through approved channels
        </div>
      ),
      onClick: () => {}
    },
    {
      title: noActionRate,
      subtitle: 'No action rate',
      description: (
        <div>
          {`Out of `}
          <strong>{formatWithCommas(mails_sent)}</strong>
          {` successfully delivered ${pluralize('email', mails_sent)}, `}
          <strong>{formatWithCommas(no_action)}</strong>
          {` ${pluralize('recipient', no_action)} took no actions whatsoever`}
        </div>
      ),
      onClick: () => {}
    },
    {
      title: undeliveredRate,
      subtitle: 'undelivered rate',
      description: renderUndeliveredRateDescription(
        mails_total,
        totalUndeliveredMails
      ),
      onClick: () => {}
    }
  ]
}
