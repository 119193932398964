import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useParams } from 'react-router-dom'
import {
  getActivityStatsFromCampaign,
  getReflexCampaignById
} from '@src/services/apis/campaigns'

import { getCampaignsTemplateById } from '@src/services/apis/templates'

/** action types */
export const SET_CAMPAIGN = 'SET_CAMPAIGN'
export const SET_STATS = 'SET_STATS'
export const SET_TEMPLATE_PREVIEW = 'SET_TEMPLATE_PREVIEW'
export const SET_TEMPLATES_PREVIEW_LOADING = 'SET_TEMPLATES_PREVIEW_LOADING'
export const SET_CAMPAIGN_AND_STATS_LOADING = 'SET_CAMPAIGN_AND_STATS_LOADING'

/** action creators */
export const setCampaign = campaign => ({ type: SET_CAMPAIGN, campaign })
export const setStats = stats => ({ type: SET_STATS, stats })
export const setTemplatePreview = (id, template) => ({
  type: SET_TEMPLATE_PREVIEW,
  id,
  template
})
export const setTemplatesPreviewLoading = loading => ({
  type: SET_TEMPLATES_PREVIEW_LOADING,
  loading
})
export const setCampaignAndStatsLoading = loading => ({
  type: SET_CAMPAIGN_AND_STATS_LOADING,
  loading
})

/** initial value */
const initialState = {
  campaign: null,
  stats: null,
  templatesPreview: null,
  campaignAndStatsLoading: false,
  templatesPreviewLoading: false
}

/** reducer */
export function reducer(state, action) {
  switch (action.type) {
    case SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign
          ? {
              ...action.campaign,
              timezone: action.campaign.timezone || 'UTC'
            }
          : action.campaign
      }
    case SET_STATS:
      return {
        ...state,
        stats: action.stats
      }
    case SET_TEMPLATE_PREVIEW:
      return {
        ...state,
        templatesPreview: {
          ...(state.templatesPreview || {}),
          [action.id]: action.template
        }
      }
    case SET_CAMPAIGN_AND_STATS_LOADING:
      return {
        ...state,
        campaignAndStatsLoading: action.loading
      }
    case SET_TEMPLATES_PREVIEW_LOADING:
      return {
        ...state,
        templatesPreviewLoading: action.loading
      }
    default:
      return state
  }
}

/** contexts */
const CampaignResultStateContext = React.createContext(undefined, undefined)
const CampaignResultDispatchContext = React.createContext(undefined, undefined)

function CampaignResultProvider({ children }) {
  const params = useParams()
  const [state, dispatch] = React.useReducer(reducer, initialState)

  /**
   * This hook is reponsible to fetch campaign data and its stats
   */
  useEffect(() => {
    const fetchCampaign = async () => {
      const { data } = await getReflexCampaignById(params.id)
      dispatch(setCampaign(data))
    }

    const fetchStats = async () => {
      const { data } = await getActivityStatsFromCampaign(params.id)
      dispatch(setStats(data))
    }

    const { campaignAndStatsLoading } = state

    if (params.id && !campaignAndStatsLoading) {
      dispatch(setCampaignAndStatsLoading(true))
      Promise.all([fetchCampaign(), fetchStats()]).then(() => {
        setCampaignAndStatsLoading(false)
      })
    }
  }, [params])

  /**
   * This hook is responsible to fetch preview for all templatesets in a campaign
   */
  useEffect(() => {
    const { stats, templatesPreview, templatesPreviewLoading } = state

    const fetchTemplate = async templateId => {
      const { data } = await getCampaignsTemplateById(params.id, templateId)
      dispatch(setTemplatePreview(templateId, data))
    }

    if (
      stats &&
      stats.templates &&
      !templatesPreview &&
      !templatesPreviewLoading
    ) {
      dispatch(setTemplatesPreviewLoading(true))
      Promise.all(
        stats.templates.map(({ template_id }) => fetchTemplate(template_id))
      ).then(() => {
        dispatch(setTemplatesPreviewLoading(false))
      })
    }
  }, [state.stats])

  return (
    <CampaignResultStateContext.Provider value={state}>
      <CampaignResultDispatchContext.Provider value={dispatch}>
        {children}
      </CampaignResultDispatchContext.Provider>
    </CampaignResultStateContext.Provider>
  )
}

/** hooks */
function useCampaignResultState() {
  const context = React.useContext(CampaignResultStateContext)
  if (context === undefined) {
    throw new Error(
      'useCampaignResultState must be used within a CampaignResultProvider'
    )
  }
  return context
}

function useCampaignResultDispatch() {
  const context = React.useContext(CampaignResultDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useCampaignResultDispatch must be used within a CampaignResultProvider'
    )
  }
  return context
}

CampaignResultProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export {
  CampaignResultProvider,
  useCampaignResultState,
  useCampaignResultDispatch
}
