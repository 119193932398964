/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  Typography,
  TIME_ZONES_INVERTED
} from '@elevate_security/elevate-component-library'
import { CAMPAIGN_STATUS } from '../../../../constants'
import { utcAndTzToTime } from '../../../../services/utils'
import { Container, Heading, Link, InfoContainer } from './styles'
import StatusBadge from '../../../../components/StatusBadge'
import { getPathFromStepNumber } from '../../../CampaignWizard/utils'

const { Text } = Typography

const formatDate = (date, tz) =>
  moment(utcAndTzToTime({ date, timezone: tz })).format(
    'MMM Do, YYYY [at] h:mm A'
  )

const CampaignInfo = ({ campaign, onCampaignClick }) => {
  const {
    id,
    name,
    description,
    campaign_state,
    current_wizard_step,
    scheduled_start,
    expiry,
    timezone
  } = campaign

  const Legend = () => {
    if (campaign_state === CAMPAIGN_STATUS.Configuring) {
      return (
        <div>
          {description && (
            <>
              <Text color="300" fontWeight="normal">
                {description}
              </Text>
              <br />
            </>
          )}
          <Text color="300" fontWeight="normal">
            {`Completed ${current_wizard_step || 1} of 6 steps. `}
          </Text>
          <Link to={getPathFromStepNumber(id)[current_wizard_step || 1]}>
            <Text color="300" fontWeight="normal">
              Continue where you left off
            </Text>
          </Link>
        </div>
      )
    }

    return (
      <div style={{ marginTop: '8px' }}>
        <Text color="300" fontWeight="normal">
          {`${formatDate(scheduled_start, timezone)} - ${formatDate(
            expiry,
            timezone
          )} ${TIME_ZONES_INVERTED[timezone] || ''}`}
        </Text>
      </div>
    )
  }

  return (
    <Container>
      <InfoContainer>
        <Heading onClick={onCampaignClick}>{name}</Heading>
        <StatusBadge campaign_state={campaign_state} />
      </InfoContainer>
      <Legend />
    </Container>
  )
}

CampaignInfo.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    campaign_state: PropTypes.string,
    current_wizard_step: PropTypes.number,
    scheduled_start: PropTypes.string,
    expiry: PropTypes.string,
    description: PropTypes.string,
    timezone: PropTypes.string
  }).isRequired,
  onCampaignClick: PropTypes.func.isRequired
}

export default CampaignInfo
