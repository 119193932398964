import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import {
  CustomSelect,
  Tabs,
  Tab,
  Card
} from '@elevate_security/elevate-component-library'
import * as templateApi from '../../../../services/apis/templates'
import { Container, TabsContainer, Scroll, HideModalScroll } from './styles'
import { useSelector } from 'react-redux'
import * as campaignActions from '../../../../services/redux/campaigns/actions'
import useActions from '@src/services/redux/useActions'
import { campaignsSelector } from '@src/services/redux/campaigns/selectors'
import ActivityIndicator from '../../../../components/ActivityIndicator'

const getTabs = showCredentialPost => [
  {
    name: 'Email',
    key: 'email_template'
  },
  ...[
    showCredentialPost
      ? {
          name: 'Credential Capture Page',
          key: 'landing_template'
        }
      : {}
  ],
  {
    name: 'Educational Page',
    key: 'auth_template'
  }
]

const TemplatesPreview = () => {
  const location = useLocation()
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [currentTemplateLoading, setCurrentTemplateLoading] = useState(false)

  const { setTemplates } = useActions(campaignActions)
  const { templateId } = useParams()
  const history = useHistory()

  const { templates, templatesLoading } = useSelector(campaignsSelector)

  useEffect(() => {
    if (!location.state?.templates && !templates && !templatesLoading)
      setTemplates()
  }, [templates])

  useEffect(() => {
    if (!currentTemplate || currentTemplate.id !== templateId) {
      fetchTemplate()
    }
  }, [templateId])

  const templateOptions = useMemo(
    () =>
      (location.state?.templates || templates || []).map(temp => ({
        label: temp.name,
        value: temp.id
      })),
    [templates]
  )

  const fetchTemplate = async () => {
    setCurrentTemplateLoading(true)
    const { data } = await templateApi.getTemplateById(templateId)
    setCurrentTemplate(data)
    setCurrentTemplateLoading(false)
  }

  const handleSelectChange = option => {
    const pathParts = location.pathname.split('/')
    const last = pathParts.pop()
    const newTemplateUrl =
      last === 'preview'
        ? pathParts.join('/') + `/preview/${option.value}`
        : pathParts.join('/') + `/${option.value}`

    history.push(newTemplateUrl, location.state)
  }

  const renderDropdownOfTemplates = () => (
    <CustomSelect
      style={{ width: '30%', margin: 10, position: 'absolute' }}
      id="customSelect"
      placeholder="Select a option"
      defaultValue={
        currentTemplate
          ? { label: currentTemplate.name, value: currentTemplate.id }
          : null
      }
      onChange={handleSelectChange}
      options={templateOptions}
      isSearch
      maxHeightDropdown="230px"
      searchBy="label"
    />
  )

  const renderTemplateTabs = () => (
    <TabsContainer>
      {currentTemplate && (
        <Tabs>
          {getTabs(currentTemplate.show_credential_post)?.map(
            ({ name, key }) => (
              <Tab title={name} key={name}>
                <Card>
                  <Scroll>
                    <iframe
                      srcDoc={currentTemplate[key]?.contents}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Scroll>
                </Card>
              </Tab>
            )
          )}
        </Tabs>
      )}
      {currentTemplateLoading && <ActivityIndicator active />}
    </TabsContainer>
  )

  return (
    <Container>
      {renderDropdownOfTemplates()}
      {renderTemplateTabs()}
      <HideModalScroll />
    </Container>
  )
}

export default TemplatesPreview
