import {
  SET_CAMPAIGNS_LIST,
  SET_CAMPAIGNS_LOADING,
  SET_EP_GROUPS,
  SET_EP_GROUPS_LOADING,
  CREATE_CAMPAIGN_MESSAGE,
  SET_DOMAINS_LIST,
  SET_TEMPLATES_LIST,
  SET_TEMPLATES_LOADING
} from './types'

export const initialState = {
  campaignList: {
    All: { count: 0, results: [] },
    Draft: { count: 0, results: [] },
    Scheduled: { count: 0, results: [] },
    Completed: { count: 0, results: [] },
    'In Progress': { count: 0, results: [] }
  },
  campaignsLoading: false,
  campaignsLoaded: false,
  groups: null,
  groupsMap: null,
  groupsLoading: false,
  domains: [],
  templates: null,
  templatesLoading: false
}

const createMapOfGroups = groups => {
  const createAMapWithIDGroupAsTheKey = (map, group) => {
    map[group.id] = group
    return map
  }
  return groups.reduce(createAMapWithIDGroupAsTheKey, {})
}

const createMapOfGroupsByCategories = groups => {
  const createAMapWithIDGroupAsTheKey = (map, group) => {
    map[group.group_category] = [...(map[group.group_category] || []), group]
    return map
  }
  return groups.reduce(createAMapWithIDGroupAsTheKey, {})
}

const sortCampaings = (a, b) => a.created_at - b.created_at

export default function campaignsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CAMPAIGNS_LOADING:
      return {
        ...state,
        campaignsLoading: action.data
      }
    case SET_CAMPAIGNS_LIST:
      return {
        ...state,
        campaignsLoading: false,
        campaignsLoaded: true,
        campaignList: [
          'All',
          'Draft',
          'Scheduled',
          'Completed',
          'In Progress'
        ].reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              count: action.data[key].length,
              results: action.data[key].sort(sortCampaings)
            }
          }),
          {}
        )
      }
    case CREATE_CAMPAIGN_MESSAGE:
      return {
        ...state,
        campaignMessages: state.campaignMessages.concat(action.message)
      }
    case SET_EP_GROUPS_LOADING:
      return {
        ...state,
        groupsLoading: action.data
      }
    case SET_EP_GROUPS:
      return {
        ...state,
        groups: action.data,
        groupsMap: createMapOfGroups(action.data),
        groupsByCategory: createMapOfGroupsByCategories(action.data),
        groupsLoading: false
      }
    case SET_DOMAINS_LIST:
      return {
        ...state,
        domains: action.data
      }
    case SET_TEMPLATES_LOADING:
      return {
        ...state,
        templatesLoading: action.data
      }
    case SET_TEMPLATES_LIST:
      return {
        ...state,
        templates: action.data,
        templatesLoading: false
      }
    default:
      return state
  }
}
