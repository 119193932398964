/* eslint-disable no-param-reassign */
import axios from 'axios'
import { getSubdomain } from './utils'

const axiosOpts = {
  headers: { 'X-Tenant': getSubdomain() },
  withCredentials: true,
  paramsSerializer: params => {
    const searchParams = new URLSearchParams()
    for (const key of Object.keys(params)) {
      const param = params[key]
      if (Array.isArray(param)) {
        for (const p of param) {
          searchParams.append(key, p)
        }
      } else {
        searchParams.append(key, param)
      }
    }
    return searchParams.toString()
  }
}

// Create the preconfigured axiosInstance, with the base URL of our API
const axiosInstance = axios.create(axiosOpts)

const UNAUTHORIZED = 'global:unauthorized'

// Add the Token to requests made with Axios
axiosInstance.interceptors.request.use(
  config => {
    // Make sure the request is sent to our API to avoid leaking the token
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('JWT')
      if (token) {
        const bearerToken = token.replace(/Token/g, 'Bearer')
        config.headers.Authorization = bearerToken
      }
    }
    return config
  },
  error => Promise.reject(error)
)

// Register a handler for 401 responses from axios
axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const { response } = error

    // if 401 is returned, dispatch event to initiate authorization flow
    if (response && response.status === 401) {
      window.dispatchEvent(
        // eslint-disable-next-line no-undef
        new Event(UNAUTHORIZED, { bubbles: true, cancelable: false })
      )
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
