/* eslint-disable no-prototype-builtins */
import cloneDeep from 'lodash/cloneDeep'
import Moment from 'moment'
import { ControlType } from './form.types'
import { timeAndTzToUTC } from '../utils'

export const Constrain = {
  /**
   * Validates that the value of a given field is no null, undefined or empty.
   *
   * @param {object} field The form field definition to validate
   * @param {object} constrain The MIN_required constrain passed to the field configuration
   * @returns {boolean} True if valid false otherwise
   * @constructor
   */
  REQUIRED: (field, constrain) => {
    const { type, value } = field

    switch (type) {
      case ControlType.TOGGLE:
      case ControlType.CHECKBOX:
      case ControlType.RADIO:
      case ControlType.MULTI_SELECT:
        return !constrain.value || Constrain.MIN_SELECTIONS(field, { value: 1 })
      case ControlType.BOOL:
        return typeof value === 'boolean' && constrain.value === value
      default:
        return (
          !constrain.value ||
          (value !== null && value !== undefined && value !== '')
        )
    }
  },
  /**
   * Validates arrays of values with a minimum length or objects in the form of:
   * @example
   * {
   *   optionName: { checked: true }
   * }
   *
   * @param {object} field The form field definition to validate
   * @param {object} constrain The MIN_required constrain passed to the field configuration
   * @returns {boolean} True if valid false otherwise
   * @constructor
   */
  MIN_SELECTIONS: (field, constrain) => {
    const { type, value } = field
    if (!value) return false
    if (
      [
        ControlType.TOGGLE,
        ControlType.CHECKBOX,
        ControlType.MULTI_SELECT
      ].indexOf(type) > -1
    ) {
      if (Array.isArray(value)) {
        return value.length >= (constrain.value || 1)
      }
      const checked = Object.keys(value).filter(option => value[option].checked)

      return checked.length >= (constrain.value || 1)
    }
    return true
  },
  /**
   * Validates that a given field has a maximum number of values.
   * @param field
   * @param constrain
   * @returns {boolean}
   * @constructor
   */
  MAX_SELECTIONS: (field, constrain) => {
    const { type, value } = field

    if (
      [ControlType.TOGGLE, ControlType.CHECKBOX, ControlType.RADIO].indexOf(
        type
      ) > -1 &&
      constrain.value
    ) {
      const checked = Object.keys(value).filter(option => value[option].checked)

      return checked.length <= constrain.value
    }

    return true
  },
  DATE: (field, constrain) => {
    const { type, value } = field

    // if there is no value we dont need validate
    // in that case the required validator is responsible
    // for raise the error.
    if (!value) {
      return true
    }

    if ([ControlType.TEXT, ControlType.DATE].indexOf(type) > -1) {
      const { after, before, sameOrAfter, sameOrBefore, timezone } = constrain
      const utcValue = timeAndTzToUTC({ date: value, timezone })
      const utcAfter = timeAndTzToUTC({ date: after, timezone })
      const utcBefore = timeAndTzToUTC({ date: before, timezone })
      const utcSameOrAfter = timeAndTzToUTC({ date: sameOrAfter, timezone })
      const utcSameOrBefore = timeAndTzToUTC({ date: sameOrBefore, timezone })
      return (
        Moment(value, constrain.format, true).isValid() &&
        (!constrain.hasOwnProperty('after') ||
          Moment(utcValue).isAfter(utcAfter)) &&
        (!constrain.hasOwnProperty('before') ||
          Moment(utcValue).isBefore(utcBefore)) &&
        (!constrain.hasOwnProperty('sameOrAfter') ||
          Moment(utcValue).isSameOrAfter(utcSameOrAfter)) &&
        (!constrain.hasOwnProperty('sameOrBefore') ||
          Moment(utcValue).isSameOrBefore(utcSameOrBefore))
      )
    }

    return false
  },
  INTEGER: (field, constrain) => {
    const { type, value } = field
    if ([ControlType.NUMBER].indexOf(type) > -1) {
      const numberValue = parseInt(value, 10)
      return (
        Number.isInteger(numberValue) &&
        (!constrain.hasOwnProperty('min') || value >= constrain.min) &&
        (!constrain.hasOwnProperty('max') || value <= constrain.max)
      )
    }
    return true
  },
  EQUAL_TEXT: (field, constrain) => {
    const { type, value } = field
    if ([ControlType.TEXT].indexOf(type) > -1) {
      return value === constrain.value
    }
    return true
  }
}

/**
 * Validates a single field and returns a new field object reference
 * with updated errors and valid properties based on the constrains results.
 *
 * - field.errors Will be an array of error message strings for any failed constrain
 * - field.valid Will be true if none of the constrains failed and false otherwise
 *
 * @param field The field object to validate
 * @returns {object} True if the field is valid, or an array with error messages for each constrain that failed.
 */
export function validateField(field) {
  const { validations } = field
  if (Array.isArray(validations)) {
    const errors = validations
      .map(constrain =>
        !constrain.test(field, constrain) ? constrain.message : true
      )
      .filter(validation => validation !== true)
    return {
      ...field,
      errors: errors.length ? errors : [],
      valid: !errors.length,
      validations
    }
  }
  return true
}

/**
 * Validates a full form object and return a new form object reference with
 * all fields validity updated.
 *
 * @see validateField
 *
 * @param form The form object to run validations for.
 * @returns {*} A new form object reference with fields validity updated.
 */
export function validateForm(form) {
  const data = cloneDeep(form)
  Object.keys(data).forEach(name => {
    data[name] = validateField(data[name])
  })
  return data
}

/**
 * Validates a single field and returns true or false based on the constrains results.
 *
 * @see validateField
 *
 * @param field The field object to run validations for.
 * @returns {boolean} True if the field is valid, false otherwise
 */
export function isFieldValid(field) {
  return field.valid
}

/**
 * Validates a full form object
 *
 * @see validateForm
 *
 * @param form The form object to run validations for.
 * @returns {boolean} True if every field is valid, false otherwise.
 */
export function isFormValid(form) {
  return Object.keys(form).every(field => form[field].valid)
}
