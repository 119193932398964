import moment from 'moment'

import * as api from '../../apis/campaigns'
import * as apiDomains from '../../apis/domains'
import * as apiTemplates from '../../apis/templates'
import { getEPGroups } from '../../apis/groups'
import { campaignsTabs, CAMPAIGN_STATUS } from '../../../constants'
import {
  setCampaingsList,
  setCampaingsLoading,
  setEpGroups,
  setEPGroupsLoading,
  setDomainsList,
  setTemplatesList,
  setTemplatesLoading
} from './actionsCreator'
import {
  addToastAction,
  toastErrorHelper,
  toastSuccessHelper
} from '../toasts/actionsCreator'
import { sanitizeContent, exportCSVFromApi } from '../../utils'

export const setReflexCampaignList = () => {
  return async dispatch => {
    try {
      dispatch(setCampaingsLoading(true))

      const { data } = await api.getReflexCampaigns({ size: 1 })
      const { meta } = data || {}
      const allCount = meta?.totalElements || 0

      const {
        data: { results }
      } = await api.getReflexCampaigns({
        size: allCount
      })

      const filteredCampaigns = {
        All: results,
        Draft: [],
        Scheduled: [],
        Completed: [],
        'In Progress': []
      }

      results.forEach(camp => {
        const tab = campaignsTabs
          .slice(1)
          .find(tab => tab.status.includes(camp.campaign_state))
        if (tab) {
          filteredCampaigns[tab.name].push(camp)
        }
      })

      dispatch(setCampaingsList(filteredCampaigns))
    } catch (error) {
      console.error(error)
      dispatch(
        addToastAction({
          id: Math.random(),
          message: 'Error! Could not load the campaigns. Please try again!',
          level: 'error'
        })
      )
    } finally {
      dispatch(setCampaingsLoading(false))
    }
  }
}

export const setEPGroups = () => {
  return async dispatch => {
    try {
      dispatch(setEPGroupsLoading(true))
      const { data } = await getEPGroups()
      dispatch(setEpGroups(data.results))
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setEPGroupsLoading(false))
    }
  }
}

export const deleteCampaign = (id, name, campaign_state) => {
  return async dispatch => {
    try {
      /** There is a restriction on BE that Ended campaign can
       * only be deleted if it is archived
       */
      if (campaign_state === CAMPAIGN_STATUS.Ended) {
        await api.updateReflexCampaign(id, { archived: true })
      }
      await api.deleteReflexCampaign(id)
      dispatch(
        toastSuccessHelper(
          `<b>${sanitizeContent(name)}</b> successfully removed.`
        )
      )
      dispatch(setReflexCampaignList())
    } catch (error) {
      console.error(error)
      dispatch(toastErrorHelper('The campaign was not removed. Try again.'))
    }
  }
}

export const copyCampaign = id => {
  return async dispatch => {
    try {
      const { data } = await api.cloneReflexCampaign(id)

      if (data && data.id) {
        const style = `style="text-decoration: underline;"`
        const link = `<a ${style} href="/engagement/reflex2/campaigns/${data.id}">Want to edit it now</>`

        dispatch(setReflexCampaignList())
        dispatch(
          toastSuccessHelper(
            `<b>${sanitizeContent(
              data?.name
            )}</b> has been added as a draft. ${link}?`
          )
        )
      } else {
        dispatch(toastErrorHelper('The campaign was not copied. Try again.'))
      }
    } catch (error) {
      console.error(error)
      dispatch(toastErrorHelper('The campaign was not copied. Try again.'))
    }
  }
}

export const cancelCampaign = (id, name) => {
  return async dispatch => {
    try {
      await api.updateReflexStateCampaign(id, CAMPAIGN_STATUS.Ended)
      dispatch(
        toastSuccessHelper(
          `<b>${sanitizeContent(name)}</b> has been cancelled.`
        )
      )
      dispatch(setReflexCampaignList())
    } catch (error) {
      console.error(error)
      dispatch(toastErrorHelper('The campaign was not cancelled. Try again.'))
    }
  }
}

export const pauseCampaign = (id, name) => {
  return async dispatch => {
    try {
      await api.updateReflexStateCampaign(id, CAMPAIGN_STATUS.Paused)
      dispatch(
        toastSuccessHelper(`<b>${sanitizeContent(name)}</b> has been paused.`)
      )
      dispatch(setReflexCampaignList())
    } catch (error) {
      console.error(error)
      dispatch(toastErrorHelper('The campaign was not paused. Try again.'))
    }
  }
}

export const resumeCampaign = (id, name) => {
  return async dispatch => {
    try {
      await api.updateReflexStateCampaign(id, CAMPAIGN_STATUS.Running)
      dispatch(
        toastSuccessHelper(`<b>${sanitizeContent(name)}</b> has been resumed.`)
      )
      dispatch(setReflexCampaignList())
    } catch (error) {
      console.error(error)
      dispatch(toastErrorHelper('The campaign was not resumed. Try again.'))
    }
  }
}

export const donwloadCampaignResults = (id, name) => {
  return async dispatch => {
    try {
      const { data } = await api.downloadReflexCampaignResults(id)
      if (data) {
        const filename = `${moment().format(
          'YYYY_MM_DD_HH_mm_ss'
        )}_results_${name}.csv`

        return exportCSVFromApi({
          data,
          filename
        })
      } else {
        dispatch(
          toastErrorHelper(
            'The campaign results file was not downloaded. Try again.'
          )
        )
      }
    } catch (error) {
      console.error(error)
      dispatch(
        toastErrorHelper(
          'The campaign results file was not downloaded. Try again.'
        )
      )
    }
  }
}

export const donwloadActivityLog = (id, name) => {
  return async dispatch => {
    try {
      const { data } = await api.downloadActivityLog(id)
      if (data) {
        const filename = `${moment().format(
          'YYYY_MM_DD_HH_mm_ss'
        )}_activity_log_${name}.csv`

        return exportCSVFromApi({
          data,
          filename
        })
      } else {
        dispatch(
          toastErrorHelper(
            'The campaign activity log file was not downloaded. Try again.'
          )
        )
      }
    } catch (error) {
      console.error(error)
      dispatch(
        toastErrorHelper(
          'The campaign activity log file was not downloaded. Try again.'
        )
      )
    }
  }
}

export const setDomains = domains => {
  return async dispatch => {
    if (domains) {
      return dispatch(setDomainsList(domains))
    }
    try {
      const { data } = await apiDomains.getDomains()
      dispatch(setDomainsList(data))
    } catch (error) {
      console.error(error)
    }
  }
}

export const setTemplates = templates => {
  return async dispatch => {
    if (templates) {
      return dispatch(setTemplatesList(templates))
    }
    try {
      dispatch(setTemplatesLoading(true))
      const { data } = await apiTemplates.getTemplates()
      dispatch(setTemplatesList(data))
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setTemplatesLoading(false))
    }
  }
}
