import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import CustomSelect from '@elevate_security/elevate-component-library/dist/CustomSelect'
import IconAction from '@elevate_security/elevate-component-library/dist/IconAction'
import Toggle from '@elevate_security/elevate-component-library/dist/Toggle'
import Layout from '@elevate_security/elevate-component-library/dist/Layout'
import Input from '@elevate_security/elevate-component-library/dist/Input'
import RadioButton from '@elevate_security/elevate-component-library/dist/RadioButton'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import IconLink from '../../../../components/IconLink'
import HelpTooltip from '../../../../components/HelpTooltip'
import InputErrors from '../../../../components/InputErrors'
import { RouteContainer, Form, FieldSet, Label } from '../../styles'
import {
  REFLEX_TEMPLATES_FORM_NAME,
  getTemplatesForm
} from '../TemplatesStep/form'
import { withForm } from '@src/services/form-manager/with-form'
import * as wizardActions from '@src/services/redux/wizard/actions'
import * as campaignsActions from '@src/services/redux/campaigns/actions'
import { wizardSelector } from '@src/services/redux/wizard/selectors'
import { campaignsSelector } from '@src/services/redux/campaigns/selectors'
import useActions from '@src/services/redux/useActions'
import { formOutOfSync } from '../../utils'
import { TemplateOption, UrlInputWrapper, TrashWrapper } from './styles'
import { isCampaignInProgress, isCampaignPaused } from '@src/services/utils'

const TemplatesStep = ({ handleChange, values }) => {
  const { id } = useParams()
  const { pathname } = useLocation()
  const history = useHistory()
  const { campaign, wizardForm } = useSelector(wizardSelector)
  const { templates, templatesLoading, domains } = useSelector(
    campaignsSelector
  )
  const { setWizardForm } = useActions(wizardActions)
  const { setDomains } = useActions(campaignsActions)
  const [isValidForm, setIsValidForm] = useState(true)

  const domainList = useMemo(
    () => (domains || []).map(({ host }) => ({ label: host, value: host })),
    [domains]
  )

  useEffect(() => {
    !templatesLoading && setWizardForm(getTemplatesForm(campaign, templates))
  }, [templatesLoading])

  useEffect(() => {
    if (!domains) setDomains()
  }, [domains])

  useEffect(() => {
    if (id && campaign.id() && id !== values.id && templates) {
      setWizardForm(getTemplatesForm(campaign, templates))
    }
  }, [campaign, templates])

  /**
   * This hook is resposible to validate the form based
   * on the value of isValidForm
   */
  useEffect(() => {
    if (wizardForm && wizardForm.valid() && !isValidForm) {
      handleChange('email_templates_valid', {
        value: false
      })
    } else if (wizardForm && !wizardForm.valid() && isValidForm) {
      handleChange('email_templates_valid', {
        value: true
      })
    }
  }, [isValidForm])

  if (formOutOfSync(REFLEX_TEMPLATES_FORM_NAME, wizardForm) || templatesLoading)
    return <ActivityIndicator active />

  const inProgress =
    campaign && isCampaignInProgress(campaign.data().campaign_state)
  const isPaused = campaign && isCampaignPaused(campaign.data().campaign_state)

  const {
    email_templates,
    randomly_template,
    email_templates_valid,
    type_of_template_sets
  } = wizardForm.get()

  /**
   * format for custom select options
   */
  const templatesList = templates
    .filter(template => {
      if (randomly_template.value)
        return template.show_credential_post === type_of_template_sets.value
      return true
    })
    .map(template => ({
      label: template.name,
      value: template.id,
      render: (
        <TemplateOption>
          <div>{template.name}</div>
          <IconLink
            iconName="Eye"
            iconSize="xsm"
            text="Preview Template"
            onClick={() => history.push(pathname + `/preview/${template.id}`)}
          />
        </TemplateOption>
      )
    }))

  /**
   * toggle for single and multi template selection
   */
  const onToggle = checked => {
    // if user un-toggle the multi selection then remove more than the 1 templates
    if (!checked && email_templates.value && email_templates.value.length) {
      const withUpdatedTemplatesForm = wizardForm.updateValue(
        'email_templates',
        { value: [email_templates.value[0]] }
      )
      const updatedForm = withUpdatedTemplatesForm.updateValue(
        'randomly_template',
        { value: false }
      )
      setWizardForm(updatedForm)
    } else {
      handleChange('randomly_template', { value: true })
    }
  }

  /**
   * change handle for template and phhshing url
   */
  const onChange = (index, template_id, hostName, domainName) => {
    // concat host and domain
    const prefix = typeof hostName === 'string' ? hostName : ''
    const postfix = typeof domainName === 'string' ? domainName : ''

    const phishing_url =
      typeof hostName === 'string' || typeof domainName === 'string'
        ? `https://${prefix}.${postfix}`
        : null

    const copiedEmailTemplates = [...email_templates.value]
    if (template_id !== null)
      copiedEmailTemplates[index].template_id = template_id
    if (phishing_url !== null)
      copiedEmailTemplates[index].phishing_url = phishing_url

    handleChange('email_templates', {
      value: copiedEmailTemplates
    })
  }

  const addAnotherTemplate = () => {
    handleChange('email_templates', {
      value: [
        ...email_templates.value,
        {
          phishing_url: null,
          template_id: null
        }
      ]
    })
  }

  const removeTemplate = index => {
    const copyArr = [...email_templates.value]
    copyArr.splice(index, 1)
    handleChange('email_templates', {
      value: copyArr
    })
  }

  /**
   * format { phishing_url, template_id } into { host, domain, selectedTemplate }
   */
  const mappedFields = (email_templates.value || []).map((template, index) => {
    const selectedTemplate = templatesList.find(
      tmpl => tmpl.value === template.template_id
    )

    // parse phsishing_url to get domain and host
    const domain = template.phishing_url
      ? domainList.find(h => template.phishing_url.endsWith(h.value))
      : null

    let url = template.phishing_url || ''
    if (domain && domain.value) {
      url = url.replace(`${domain.value}`, '')
    }
    url = url.replace('https://', '')
    const host = url.endsWith('.') ? url.substr(0, url.length - 1) : url

    if (!host) {
      // if host is empty set default value is cdn-{campaign_id} in the form
      onChange(index, null, `cdn-${id}`, domain?.value)
    }

    return {
      host,
      domain,
      selectedTemplate
    }
  })

  /**
   * This is a anonymous function to keep updating the isValidForm state
   * based on the value of form fields
   */
  // eslint-disable-next-line no-unused-vars
  const anynomous = (() => {
    const isValid = mappedFields.reduce(
      (accumulator, { host, domain, selectedTemplate }) =>
        !!(accumulator && host && domain && selectedTemplate),
      true
    )
    if (isValid !== isValidForm) setIsValidForm(isValid)
  })()

  const EmailTemplates = () => {
    const totalTemplates = email_templates.value?.length || 0
    const showTrash = totalTemplates > 1
    return (
      <FieldSet>
        {mappedFields.map(({ host, domain, selectedTemplate }, index) => {
          return (
            <div key={`template-${Math.random()}-${index}`}>
              <FieldSet>
                <Label>
                  {randomly_template.value
                    ? `Template ${index + 1}`
                    : `Phishing Email Template`}
                </Label>
                <CustomSelect
                  style={{ width: '100%' }}
                  id="customSelect"
                  placeholder="Choose a template..."
                  defaultValue={selectedTemplate}
                  onChange={({ value }) => onChange(index, value, null, null)}
                  options={templatesList}
                  isSearch
                  maxHeightDropdown="200px"
                  disabled={inProgress}
                  searchBy="label"
                  invalid={!isValidForm && !selectedTemplate}
                />
                {showTrash && !inProgress && (
                  <TrashWrapper>
                    <IconAction
                      name="Trash"
                      tooltipText="Remove Template"
                      onClick={() => removeTemplate(index)}
                    />
                  </TrashWrapper>
                )}
              </FieldSet>
              <FieldSet>
                <Label>
                  {randomly_template.value
                    ? `URL ${index + 1}`
                    : `Phishing URL`}
                </Label>
                <Layout container>
                  <span>https://</span>
                  <UrlInputWrapper>
                    <Input
                      defaultValue={host}
                      onBlur={e =>
                        onChange(index, null, e?.target?.value, domain?.value)
                      }
                      disabled={inProgress && !isPaused}
                      invalid={!isValidForm && !host}
                    />
                  </UrlInputWrapper>
                  .
                  <CustomSelect
                    style={{
                      width: '273px',
                      marginLeft: '13px',
                      marginRight: 0
                    }}
                    id={`customSelectDomain-${index}`}
                    defaultValue={domain}
                    onChange={({ value }) => onChange(index, null, host, value)}
                    options={domainList}
                    isSearch
                    maxHeightDropdown="200px"
                    disabled={inProgress && !isPaused}
                    invalid={!isValidForm && !domain}
                  />
                </Layout>
              </FieldSet>
            </div>
          )
        })}
        {randomly_template.value && !inProgress && (
          <IconLink
            iconName="Plus"
            iconSize="xsm"
            text="Add another template"
            onClick={() => addAnotherTemplate()}
          />
        )}
      </FieldSet>
    )
  }

  const typeOfTemplateSetsOptions = [
    {
      value: true,
      label: 'The ones that capture credentials'
    },
    {
      value: false,
      label: 'The ones that don’t capture credentials'
    }
  ]

  return (
    <RouteContainer>
      <Form>
        <FieldSet direction="row">
          <Toggle
            name="randomly_template"
            label="Send a randomly selected template to each recipient (AKA the variety pack)"
            checked={randomly_template.value}
            onChange={value => onToggle(value.checked)}
            disabled={inProgress}
          />
          <HelpTooltip body="If enabled, we will randomly send out a chosen template to each recipient. This allows you to test the efficacy of one template over another within the same campaign." />
        </FieldSet>
        {randomly_template.value && (
          <div
            style={{ padding: '0 0 20px 50px', margin: '-20px 0px -10px 0' }}
          >
            <div style={{ marginBottom: 10 }}>
              Which type of template sets do you want to use?
            </div>
            <RadioButton
              options={typeOfTemplateSetsOptions}
              name="defaultRadio"
              radioSelected={type_of_template_sets.value}
              vertical
              onChange={value => {
                handleChange('type_of_template_sets', { value })
              }}
              controlled={true}
            />
          </div>
        )}
        {!isValidForm && (
          <FieldSet direction="row">
            <InputErrors errors={email_templates_valid.errors} />
          </FieldSet>
        )}

        <EmailTemplates />
      </Form>
    </RouteContainer>
  )
}

TemplatesStep.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number,
    randomly_template: PropTypes.bool,
    email_templates: PropTypes.shape([])
  }),
  handleChange: PropTypes.func.isRequired
}

export default withForm(TemplatesStep)
