/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ItemContainer } from './styles'
import CampaignActions from '../../../components/CampaignActions'
import ParticipantsInfo from './ParticipantsInfo'
import CampaignInfo from './CampaignInfo'
import { REFLEX_CAMPAIGNS_BASE_PATH, CAMPAIGN_STATUS } from '../../../constants'
import { campaignsSelector } from '../../../services/redux/campaigns/selectors'
import { isCampaignScheduled, isCampaignInProgress } from '@src/services/utils'
import { getPathFromStepNumber } from '../../CampaignWizard/utils'

const CampaignRow = ({ campaign }) => {
  const history = useHistory()
  const { groups } = useSelector(campaignsSelector)

  const onCampaignClick = () => {
    if (
      campaign.campaign_state === CAMPAIGN_STATUS.Ended ||
      isCampaignInProgress(campaign.campaign_state)
    ) {
      history.push(`/engagement/reflex2/campaigns/${campaign.id}/overview`)
    } else onEditCampaign()
  }

  const onEditCampaign = () => {
    if (
      isCampaignInProgress(campaign.campaign_state) ||
      isCampaignScheduled(campaign.campaign_state)
    ) {
      history.push(`${REFLEX_CAMPAIGNS_BASE_PATH}/${campaign.id}/confirm`)
    } else {
      history.push(
        getPathFromStepNumber(campaign.id)[campaign.current_wizard_step || 1]
      )
    }
  }

  const getNumberOfParticipants = () => {
    // use the members_count from groups only for draft and scheduled campaigns
    const sum_of_all_members_count = groups
      ?.filter(group => campaign.es_groups_id?.includes(group.id))
      ?.map(group => group.members_count)
      ?.reduce((sum, value) => sum + value, 0)

    if (
      campaign.campaign_state === CAMPAIGN_STATUS.Configuring ||
      campaign.campaign_state === CAMPAIGN_STATUS.Scheduled
    ) {
      return sum_of_all_members_count || campaign.mails_total
    } else {
      return campaign.mails_total
    }
  }

  return (
    <ItemContainer>
      <CampaignInfo campaign={campaign} onCampaignClick={onCampaignClick} />
      <ParticipantsInfo count={getNumberOfParticipants()} />
      <CampaignActions campaign={campaign} onEdit={onEditCampaign} />
    </ItemContainer>
  )
}

CampaignRow.propTypes = {
  campaign: PropTypes.object
}

CampaignRow.defaultProps = {
  campaign: null
}

export default CampaignRow
