import styled from 'styled-components'

export const TemplateOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    position: relative;
    left: 0;
  }
`
export const UrlInputWrapper = styled.div`
  margin-left: auto;
  margin-right: 13px;
  [class^='inputstyles__InputContainer'] {
    input[type='text'] {
      width: 273px !important;
    }
  }
`

export const TrashWrapper = styled.div`
  position: absolute;
  right: -30px;
  top: 30px;
`
