import React from 'react'
import { IconWrapper } from './styles'
import moment from 'moment'
import { utcAndTzToTime } from '@src/services/utils'
import TemplateSetModal from '../TemplateSetModal'
import RecipientListModal from '../RecipientListModal'

const getNotificationLabels = (
  notify_on_click,
  notify_on_open,
  notify_on_post
) => {
  const array = []
  if (notify_on_click) array.push('On link clicked')
  if (notify_on_open) array.push('On email opened')
  if (notify_on_post) array.push('On credential posted')

  if (array.length === 0) return 'Don’t send notifications'
  return array.join('; ')
}

/**
 * This function takes a UTC time and timezone and return parsed date for the picker
 * so that the date appears according to the selected timezone
 *
 *
 */
export const getFormattedData = data => {
  return {
    ...data,
    scheduled_start: utcAndTzToTime({
      date: data.scheduled_start,
      timezone: data.timezone
    }),
    expiry: utcAndTzToTime({
      date: data.expiry,
      timezone: data.timezone
    }),
    mail_end: utcAndTzToTime({
      date: data.mail_end,
      timezone: data.timezone
    })
  }
}

export const getAditionalDetailsFrom = (campaign, groupsMap) => {
  if (!campaign) return []

  const campaignData = getFormattedData(campaign)

  const {
    scheduled_start,
    expiry,
    stagger_count,
    mail_end,
    notify_email,
    id,
    is_trial,
    stagger_pause,
    handler: { email },
    es_groups_id,
    notify_on_click,
    notify_on_open,
    notify_on_post,
    email_templates,
    timezone,
    mails_total
  } = campaignData

  const getRecipientsGroupsValue = () => {
    if (!es_groups_id) return '—'
    if (es_groups_id.length === 1) return groupsMap?.[es_groups_id[0]]?.name

    return `${es_groups_id.length} groups`
  }
  const templatesCount = email_templates && email_templates.length

  const zoneAbbr = timezone ? moment.tz(timezone).format('z') : null

  // if zone abbreviation not present in moment.js then show UTC diff
  const zoneName =
    zoneAbbr && (zoneAbbr.includes('+') || zoneAbbr.includes('-'))
      ? `(UTC${moment.tz(timezone).format('Z')})`
      : zoneAbbr || ''

  const formatString = 'MMM D, YYYY, h:mm A'

  return [
    {
      label: 'Campaign Start',
      value:
        `${moment(scheduled_start).format(formatString)}${' '}${zoneName}` ||
        '—'
    },
    {
      label: 'Campaign End',
      value: `${moment(expiry).format(formatString)} ${zoneName}` || '—'
    },
    {
      label: 'Recipient Group(s)',
      value: getRecipientsGroupsValue()
    },
    {
      label: 'Email Stagger Rate',
      value: `${stagger_count || '—'} emails every ${
        stagger_pause || '—'
      } seconds`
    },
    {
      label: 'Email Sending Ended',
      value: mail_end
        ? `${moment(mail_end).format(formatString)} ${zoneName}`
        : '—'
    },
    {
      label: 'Recipient List',
      value: <RecipientListModal total={mails_total} />
    },
    { label: 'Campaign Created By', value: email || '—' },
    { label: 'Campaign ID', value: id || '—' },
    {
      label: 'Template Sets & URLs',
      value: <TemplateSetModal templatesCount={templatesCount} />
    },
    {
      label: 'Email Notifications',
      value: getNotificationLabels(
        notify_on_click,
        notify_on_open,
        notify_on_post
      )
    },
    {
      label: 'Notifications Recipient',
      value: notify_email || '—'
    },
    {
      label: 'Test Campaign?',
      value: (
        <>
          {is_trial ? 'Yes' : 'No'}
          <IconWrapper title={'Edit campaign description'}>
            {/* <IconAction
              name={'Pencil'}
              onClick={() => {}}
              size="xsm"
              disabled
            /> */}
          </IconWrapper>
        </>
      )
    }
  ]
}
