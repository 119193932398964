import styled from 'styled-components'
import theme from '../../../css/theme'

const { colors } = theme

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  align-items: center;
  border-top: 1px solid ${colors.border.default};
  padding: 1.43rem 1rem 1.43rem 0;

  :first-child {
    border-top: none;
  }
`
