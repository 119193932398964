/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  CustomSelect,
  Tabs,
  Tab,
  Card,
  Typography,
  Modal,
  Header,
  ButtonIcon
} from '@elevate_security/elevate-component-library'
import * as campaignActions from '@src/services/redux/campaigns/actions'
import useActions from '@src/services/redux/useActions'
import { campaignsSelector } from '@src/services/redux/campaigns/selectors'
import ActivityIndicator from '@src/components/ActivityIndicator'
import {
  Container,
  TabsContainer,
  Scroll,
  HideModalScroll,
  TemplateContainer,
  TemplateInfoColumn,
  ModalComponent
} from './styles'
import ToastsManager from '@src/scenes/ToastsManager'
import { useCampaignResultState } from '@src/scenes/CampaignResult/CampaignResultContext'

const { H2 } = Typography

const getTabs = showCredentialPost => [
  {
    name: 'Email',
    key: 'email_template'
  },
  ...[
    showCredentialPost
      ? {
          name: 'Credential Capture Page',
          key: 'landing_template'
        }
      : {}
  ],
  {
    name: 'Educational Page',
    key: 'auth_template'
  }
]

const TemplatesPreview = ({
  visible,
  templateId,
  campaingTemplates,
  onBack,
  onClose
}) => {
  const { campaign, templatesPreview } = useCampaignResultState()
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [currentTemplateLoading, setCurrentTemplateLoading] = useState(false)

  const { setTemplates } = useActions(campaignActions)
  const { templates, templatesLoading } = useSelector(campaignsSelector)

  useEffect(() => {
    if (!campaingTemplates && !templates && !templatesLoading) setTemplates()
  }, [templates])

  useEffect(() => {
    if (templateId && templatesPreview && templatesPreview[templateId]) {
      setCurrentTemplate(templatesPreview[templateId])
      setCurrentTemplateLoading(false)
    } else {
      setCurrentTemplateLoading(true)
    }
  }, [templateId, templatesPreview])

  const templateOptions = useMemo(
    () =>
      (campaingTemplates || templates || []).map(temp => ({
        label: temp.name,
        value: temp.id
      })),
    [templates]
  )

  const handleSelectChange = async option => {
    if (templatesPreview && templatesPreview[option.value]) {
      setCurrentTemplate(templatesPreview[option.value])
    }
  }

  const renderDropdownOfTemplates = () => (
    <CustomSelect
      style={{ width: '40%', margin: '0 10px' }}
      id="customSelect"
      placeholder="Select a option"
      defaultValue={
        currentTemplate
          ? { label: currentTemplate.name, value: currentTemplate.id }
          : null
      }
      onChange={handleSelectChange}
      options={templateOptions}
      isSearch
      maxHeightDropdown="230px"
      searchBy="label"
    />
  )

  const renderTemplateTabs = () => (
    <TabsContainer>
      {currentTemplate && (
        <Tabs>
          {getTabs(currentTemplate.show_credential_post)?.map(
            ({ name, key }) => (
              <Tab title={name} key={name}>
                <Card>
                  <Scroll>
                    <iframe
                      srcDoc={currentTemplate[key]?.contents}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Scroll>
                </Card>
              </Tab>
            )
          )}
        </Tabs>
      )}
      {currentTemplateLoading && <ActivityIndicator active />}
    </TabsContainer>
  )

  const phishingUrl = campaign.email_templates.find(
    t => t.template_id === currentTemplate?.id
  )?.phishing_url

  const modalTitle = (
    <Header>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <H2
          color="900"
          fontWeight="bold"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ButtonIcon icon="ArrowLeft" theme="ghost" onClick={onBack} />
          Template Set Preview
        </H2>
        {renderDropdownOfTemplates()}
      </div>
    </Header>
  )

  const renderInfoColumn = () => {
    if (!currentTemplate) return null
    return (
      <TemplateInfoColumn>
        <p>Sender Name</p>
        <span>{currentTemplate?.email_template?.from_display}</span>
        <p>Sender Email</p>
        <span>{currentTemplate?.email_template?.from_email}</span>
        <p>Subject Line</p>
        <span>{currentTemplate?.email_template?.mailsubject}</span>
        <p>URL</p>
        <span>{phishingUrl && `https://${phishingUrl}`}</span>
      </TemplateInfoColumn>
    )
  }

  return (
    <ModalComponent id="reflex-campaign-wizard">
      <Modal
        isOpen={visible}
        key="campaign-wizard"
        header={modalTitle}
        onClose={onClose}
      >
        <div style={{ position: 'relative', height: '100%' }}>
          <div style={{ width: '90%', margin: '5px auto -10px auto' }}>
            <ToastsManager fixed={false} id="modal-toast-manager" />
          </div>
          <Container>
            <TemplateContainer>
              {renderTemplateTabs()}
              {renderInfoColumn()}
            </TemplateContainer>
            <HideModalScroll />
          </Container>
        </div>
      </Modal>
    </ModalComponent>
  )
}

export default TemplatesPreview
