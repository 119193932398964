import React, { useState } from 'react'
import useActions from '@src/services/redux/useActions'
import { useParams } from 'react-router-dom'

import { TitleSection } from '../styles'
import CampaignActivityLogTable from './CampaignActivityLogTable'
import IconLink from '../../../components/IconLink'
import * as campaignActions from '../../../services/redux/campaigns/actions'
import { useCampaignResultState } from '../CampaignResultContext'

function CampaignActivityLogTab() {
  const [downloading, setDownloading] = useState(false)
  const { donwloadActivityLog } = useActions(campaignActions)
  const params = useParams()
  const { campaign } = useCampaignResultState()

  const handleDownloadActivityLog = async () => {
    setDownloading(true)
    await donwloadActivityLog(params.id, campaign?.name)
    setDownloading(false)
  }

  return (
    <>
      <TitleSection>
        Campaign Activity Log
        <IconLink
          iconName="Download"
          iconSize="xsm"
          fontWeight="bold"
          text="Export Data"
          onClick={handleDownloadActivityLog}
          disabled={downloading}
        />
      </TitleSection>
      <CampaignActivityLogTable />
    </>
  )
}

export default CampaignActivityLogTab
