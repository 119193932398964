import styled from 'styled-components'

export const Group = styled.div`
  margin-right: auto;
  span {
    :first-child {
      margin-right: 22px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }
  }
  [class^='Badgestyles__BadgeRounded'] {
    :first-child {
      margin-left: 22px;
    }
  }
`

export const PageTitle = styled.div`
  display: flex;
  [class^='Modalstyles__ModalContent'] {
    overflow: hidden;
    div {
      height: 40px;
    }
  }
`

export const TabsContainer = styled.div`
  ul {
    margin-left: 0 !important;
  }
`

export const TitleSection = styled.h2`
  font-weight: bold;
  color: #34383c;
  font-size: 20px;
  padding: 24px 0px;
  margin-bottom: 0;
  display: flex;
  a {
    margin-left: auto;
  }
`
