import {
  SET_CAMPAIGNS_LIST,
  SET_CAMPAIGNS_LOADING,
  SET_EP_GROUPS,
  SET_EP_GROUPS_LOADING,
  SET_DOMAINS_LIST,
  SET_TEMPLATES_LIST,
  SET_TEMPLATES_LOADING
} from './types'

export const setCampaingsLoading = value => ({
  type: SET_CAMPAIGNS_LOADING,
  data: value
})

export const setCampaingsList = list => ({
  type: SET_CAMPAIGNS_LIST,
  data: list
})

export const setEPGroupsLoading = value => ({
  type: SET_EP_GROUPS_LOADING,
  data: value
})

export const setEpGroups = results => ({
  type: SET_EP_GROUPS,
  data: results
})

export const setDomainsList = data => ({
  type: SET_DOMAINS_LIST,
  data
})

export const setTemplatesList = data => ({
  type: SET_TEMPLATES_LIST,
  data
})

export const setTemplatesLoading = data => ({
  type: SET_TEMPLATES_LOADING,
  data
})
