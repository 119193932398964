import styled from 'styled-components'
import { Typography } from '@elevate_security/elevate-component-library'

const { Text } = Typography

export const Container = styled.div`
  position: relative;
  min-height: 350px;
  margin-left: -10px;
  margin-top: 30px;
`

export const EmptyListMessage = styled(Text)`
  opacity: 0.7;
  font-size: 18px;
`
export const HeadingWrapper = styled(Text)`
  h1 {
    margin: 16px 0 24px 0;
    line-height: normal;
  }
`
