/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Pill,
  PillContainer,
  PillRow,
  Title,
  VerticalCard,
  Description,
  RecipientsWrap,
  RecipientsCount,
  IconWrapper,
  Dash
} from './styles'
import { Icon, Tooltip } from '@elevate_security/elevate-component-library'
import { useRequest } from 'ahooks'
import { getActivityStatsFromCampaign } from '@src/services/apis/campaigns'
import { useParams } from 'react-router-dom'
import { getPercentageString } from '@src/services/utils'

function CampaignCustomCard() {
  const { id } = useParams()
  const [actions, setActions] = useState([])
  const [stats, setStats] = useState()

  const toggleAction = action => () => {
    if (actions.includes(action))
      setActions(actions.filter(string => string !== action))
    else setActions([...actions, action])
  }

  useRequest(
    async () =>
      actions.length &&
      getActivityStatsFromCampaign(id, { actions: actions.join(',') }),
    {
      debounceInterval: 500,
      onSuccess: ({ data }) => setStats(data),
      refreshDeps: [actions]
    }
  )

  const percentage = getPercentageString(
    stats?.total?.actions,
    stats?.total?.mails_sent
  )
  return (
    <VerticalCard>
      <IconWrapper>
        <Tooltip
          body={
            <div>
              <span>
                Calculate campaign rates on the fly by mixing and matching
                different actions.
              </span>
              <br />
              <br />
              <span>
                Example: Selecting <b>Opened</b> and <b>Clicked</b> will give
                you the rate for recipients who both opened the email <b>and</b>{' '}
                clicked on the phishing link.
              </span>
            </div>
          }
          placement="left"
          size="sm"
          readOnly
        >
          <Icon name="CircleExclamation" size="xsm" fill="#424A54" />
        </Tooltip>
      </IconWrapper>
      <Title>{actions.length === 0 ? <Dash /> : percentage}</Title>
      <PillContainer>
        <PillRow>
          <Pill
            onClick={toggleAction('opened')}
            className={actions.includes('opened') ? 'is-active' : ''}
            selected={actions.includes('opened')}
          >
            Opened
          </Pill>
          <Pill
            onClick={toggleAction('clicked')}
            className={actions.includes('clicked') ? 'is-active' : ''}
            selected={actions.includes('clicked')}
          >
            Clicked
          </Pill>
        </PillRow>
        <PillRow>
          <Pill
            onClick={toggleAction('posted')}
            className={actions.includes('posted') ? 'is-active' : ''}
            selected={actions.includes('posted')}
          >
            Posted
          </Pill>
          <Pill
            onClick={toggleAction('reported')}
            className={actions.includes('reported') ? 'is-active' : ''}
            selected={actions.includes('reported')}
          >
            Reported
          </Pill>
        </PillRow>
      </PillContainer>
      {actions.length ? (
        <RecipientsWrap>
          <Icon name="User" size="sm" fill="#959DA8" />
          <RecipientsCount>{stats?.total?.actions || 0}</RecipientsCount>
        </RecipientsWrap>
      ) : (
        <Description>
          Select 1+ actions to see how many recipients performed those specific
          actions
        </Description>
      )}
      {/* {onClick && (
        <ButtonContainer>
          <Button onClick={onClick}>CTA</Button>
        </ButtonContainer>
      )} */}
    </VerticalCard>
  )
}

export default CampaignCustomCard
