export const SET_CAMPAIGNS_LIST = 'SET_CAMPAIGNS_LIST'
export const SET_CAMPAIGNS_LOADING = 'SET_CAMPAIGNS_LOADING'

export const CREATE_CAMPAIGN_MESSAGE = 'CREATE_CAMPAIGN_MESSAGE'
export const SET_EP_GROUPS = 'SET_EP_GROUPS'
export const SET_EP_GROUPS_LOADING = 'SET_EP_GROUPS_LOADING'

export const SET_DOMAINS_LIST = 'SET_DOMAINS_LIST'

export const SET_TEMPLATES_LIST = 'SET_TEMPLATES_LIST'
export const SET_TEMPLATES_LOADING = 'SET_TEMPLATES_LOADING'
