import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import {
  Typography,
  TIME_ZONES_INVERTED
} from '@elevate_security/elevate-component-library'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import HelpTooltip from '../../../../components/HelpTooltip'
import { REFLEX_CAMPAIGN_CONFIRM_FORM_NAME, getConfirmForm } from './form'
import * as wizardActions from '@src/services/redux/wizard/actions'
import { addToastAction } from '@src/services/redux/toasts/actionsCreator'
import { wizardSelector } from '@src/services/redux/wizard/selectors'
import InputErrors from '@src/components/InputErrors'
import Checkbox from '@src/components/Checkbox'
import { withForm } from '@src/services/form-manager/with-form'
import { formOutOfSync, STEP, STEP_PATH } from '../../utils'
import { Content, SendEmailButton, TextWrapper, WordSpacing } from './styles'
import useActions from '@src/services/redux/useActions'
import { RouteContainer, FieldSet, Form } from '../../styles'
import { campaignsSelector } from '@src/services/redux/campaigns/selectors'
import { CAMPAIGN_STATUS, REFLEX_EDIT_CAMPAIGN_BASE_PATH } from '@src/constants'
import { sendTestCampaignEmail } from '@src/services/apis/campaigns'
import { getFormattedData } from '../SchedulingStep/utils'
import { getToastObjectFromError } from '@src/services/apis/fetcher/utils'
import { sanitizeContent } from '../../../../services/utils'

const { Text, H3 } = Typography

const envelopeImg =
  'https://hm-assets.elevatesecurity.com/master/hm/stamp-envelope.svg'

function ConfirmStep({ handleChange, values }) {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { campaign, wizardForm } = useSelector(wizardSelector)
  const { setWizardForm } = useActions(wizardActions)
  const { groups } = useSelector(campaignsSelector)
  const { templates } = useSelector(campaignsSelector)

  const me = useSelector(state => state.reflexReducer.me || {})
  const [feedbackToUser, setFeedbackToUser] = useState()
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false)

  useEffect(() => {
    setWizardForm(getConfirmForm(campaign))
  }, [])

  useEffect(() => {
    if (id && campaign.id() && id !== values.id) {
      setWizardForm(getConfirmForm(campaign))
    }
  }, [campaign])

  if (formOutOfSync(REFLEX_CAMPAIGN_CONFIRM_FORM_NAME, wizardForm))
    return <ActivityIndicator active />

  const campaignData = getFormattedData(campaign.data())

  const {
    name,
    scheduled_start,
    expiry,
    email_templates = [],
    timezone,
    campaign_state,
    es_groups_id,
    mails_total
  } = campaignData

  const { status } = wizardForm.get()

  const formatString = 'MMM DD, YYYY, h:mm A '

  const sendTestEmail = async () => {
    setIsSendingTestEmail(true)
    setFeedbackToUser(null)
    const {
      email: victim_email,
      first_name: victim_first_name,
      last_name: victim_last_name
    } = me

    try {
      const { error } = await sendTestCampaignEmail(id, {
        victim_email,
        victim_first_name,
        victim_last_name
      })
      if (!error) {
        setFeedbackToUser(' Sent! Check your inbox')
      } else {
        const toast = getToastObjectFromError('modal-toast-manager')(error)
        dispatch(addToastAction(toast))
        setFeedbackToUser(' Error! Email not sent. Try again!')
      }
    } catch (error) {
      const toast = {
        id: Math.random(),
        level: 'error',
        message: sanitizeContent(
          error.response?.data?.detail ||
            error.message ||
            'Error! Email not sent.Try again!'
        ),
        containerId: 'modal-toast-manager'
      }
      dispatch(addToastAction(toast))
      setFeedbackToUser(' Error! Email not sent. Try again!')
    } finally {
      setIsSendingTestEmail(false)
    }
  }

  const getNumberOfParticipants = () => {
    // use the members_count from groups only for draft and scheduled campaigns
    const sum_of_all_members_count = groups
      ?.filter(group => es_groups_id?.includes(group.id))
      .map(group => group.members_count)
      .reduce((sum, value) => sum + value, 0)

    if (
      campaign_state === CAMPAIGN_STATUS.Configuring ||
      campaign_state === CAMPAIGN_STATUS.Scheduled
    ) {
      return sum_of_all_members_count || mails_total
    } else {
      return mails_total
    }
  }

  const navigateWizardSteps = step => {
    if (step === STEP.CAMPAIGN_INFO) {
      history.push(REFLEX_EDIT_CAMPAIGN_BASE_PATH.replace(':id', id))
    } else {
      history.push(STEP_PATH[step].replace(':id', id))
    }
  }

  const numberOfTemplates = (email_templates || []).length
  const templateId = email_templates && email_templates[0].template_id
  const templateName = templates?.find(template => template.id === templateId)
    ?.name

  return (
    <RouteContainer>
      <Form>
        <Content justify="center" align="center" width="100%">
          <img src={envelopeImg} alt="Stamp" />
          <Text color="900" fontWeight="normal">
            Between
          </Text>
          <H3
            onClick={() => navigateWizardSteps(STEP.SCHEDULING)}
            fontWeight="bold"
            color="900"
            data-id="hm-review-timezone"
          >
            <div style={{ textAlign: 'center' }}>
              {scheduled_start && moment(scheduled_start).format(formatString)}{' '}
              - {expiry && moment(expiry).format(formatString)}
              {` `}
              {TIME_ZONES_INVERTED[timezone]}
            </div>
          </H3>
          <Text color="900" fontWeight="normal">
            <WordSpacing>we will run</WordSpacing>
          </Text>
          <H3
            onClick={() => navigateWizardSteps(STEP.CAMPAIGN_INFO)}
            fontWeight="bold"
            color="900"
            data-id="hm-review-timezone"
          >
            {name}
          </H3>
          <Text color="900" fontWeight="normal">
            <WordSpacing>by sending</WordSpacing>
          </Text>
          <TextWrapper>
            {numberOfTemplates > 1 ? (
              <>
                <Text color="900" fontWeight="normal">
                  <WordSpacing>a random template (out of</WordSpacing>
                </Text>
                <H3
                  onClick={() => navigateWizardSteps(STEP.TEMPLATES)}
                  fontWeight="bold"
                  color="900"
                >
                  {numberOfTemplates} template sets
                </H3>
                <Text color="900" fontWeight="normal">
                  )<WordSpacing>to</WordSpacing>
                </Text>
              </>
            ) : (
              <>
                <H3
                  onClick={() => navigateWizardSteps(STEP.TEMPLATES)}
                  fontWeight="bold"
                  color="900"
                >
                  {templateName}
                </H3>
                <Text color="900" fontWeight="normal">
                  <WordSpacing>to</WordSpacing>
                </Text>
              </>
            )}
            <H3
              fontWeight="bold"
              color="900"
              onClick={() => navigateWizardSteps(STEP.RECIPIENTS)}
            >
              {getNumberOfParticipants()} recipients
            </H3>
          </TextWrapper>

          <div>
            <SendEmailButton
              onClick={sendTestEmail}
              disabled={isSendingTestEmail}
            >
              {'Send a sample email template '}
            </SendEmailButton>
            <HelpTooltip body="We will send a sample email of every chosen template set to your inbox." />
            <ActivityIndicator active={isSendingTestEmail} />
          </div>
          <div>{feedbackToUser}</div>
          {isSendingTestEmail && <div>Sending. Please wait.</div>}

          <FieldSet
            style={{ marginTop: '10px' }}
            height="32px"
            data-id="hm-review-confirmation"
          >
            <Checkbox
              onChange={value => handleChange('status', { value })}
              checked={status.value}
              label="Yep, everything looks correct!"
              invalid={!status.valid}
              disabled={campaign_state !== CAMPAIGN_STATUS.Configuring}
            />
            {!status.valid && <InputErrors errors={status.errors} />}
          </FieldSet>
        </Content>
      </Form>
    </RouteContainer>
  )
}

ConfirmStep.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    id: PropTypes.number
  })
}

export default withForm(ConfirmStep)
