/* eslint-disable react/prop-types */
import React from 'react'
import {
  Table,
  Icon,
  ButtonLink
} from '@elevate_security/elevate-component-library'
import columns from './columns'
import { prepareSortParam, prepareTableData } from './utils'
import {
  PerformanceAnalysisTableWrapper,
  PerformanceAnalysisGridWrapper,
  Toolbar,
  SearchInput,
  EmptyData
} from './styles'
import MoreFilter from './MoreFilter'
import {
  clearFilters,
  setFilters,
  setFilterBy,
  setTableInfo,
  usePerformanceAnalysisTableDispatch,
  usePerformanceAnalysisTableState
} from './PerformanceAnalysisTableContext'

function PerformanceAnalysisTable() {
  const {
    hideClearFilter,
    filters,
    results,
    meta
  } = usePerformanceAnalysisTableState()
  const dispatch = usePerformanceAnalysisTableDispatch()
  const handleChangeTable = info => {
    const { pagination, sort } = info || {}
    const { limit = 10, offset = 0 } = pagination
    const page = Math.floor(offset / limit) + 1
    dispatch(
      setTableInfo({
        page,
        size: limit,
        sort: prepareSortParam(sort)
      })
    )
  }

  const filtersSelectedOptions = [
    'opened',
    'clicked',
    'reported',
    'posted',
    'department',
    'location',
    'tenure'
  ].reduce(
    (acc, key) => [...acc, ...filters[key].map(value => ({ key: key, value }))],
    []
  )

  return (
    <PerformanceAnalysisTableWrapper>
      <PerformanceAnalysisGridWrapper>
        <Toolbar>
          <SearchInput
            value={filters.filterBy}
            iconLeft={<Icon name="Search" size="xsm" fill="#7E8691" />}
            placeholder="Filter by recipient, dept, etc."
            onChange={value =>
              value ? dispatch(setFilterBy(value)) : dispatch(setFilterBy(''))
            }
          />
          <MoreFilter
            filters={filtersSelectedOptions}
            onSelection={({ key, value }) =>
              dispatch(setFilters({ [key]: value }))
            }
          />
          {!hideClearFilter && (
            <ButtonLink onClick={() => dispatch(clearFilters())}>
              Clear filters
            </ButtonLink>
          )}
        </Toolbar>
        {results?.length ? (
          <Table
            manual
            resizable
            bodyHeight="375px"
            data={prepareTableData(results)}
            totalCount={meta.totalElements || 0}
            defaultPage={1}
            columns={columns}
            defaultPageSize={10}
            pageSizeOptions={[5, 10, 25, 50]}
            onChangeTable={handleChangeTable}
          />
        ) : (
          <EmptyData>
            <span>No recipients to show.</span>
          </EmptyData>
        )}
      </PerformanceAnalysisGridWrapper>
    </PerformanceAnalysisTableWrapper>
  )
}

export default PerformanceAnalysisTable
