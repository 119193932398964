import fetcher from '../fetcher'
import { getErrorStatus } from '../fetcher/utils'
import { BASES_REFLEX_API_URL } from '../../../constants'

/**
 * Gets all domains
 * GET /domains
 * @param {object} params
 */
export async function getDomains() {
  try {
    const { status, data } = await fetcher.get(
      `${BASES_REFLEX_API_URL}/domains`
    )
    return { status, data }
  } catch (error) {
    return getErrorStatus({ error })
  }
}
