import React, { useState } from 'react'
import { Table } from '@elevate_security/elevate-component-library'
import columns from './columns'
import { Container, TableWrapper } from './styles'
import { getVictimsFromCampaign } from '@src/services/apis/campaigns'
import ActivityIndicator from '@src/components/ActivityIndicator'
import { useRequest } from 'ahooks'
import axiosInstance from '@src/services/apis/fetcher'
import { prepareSortParam } from './utils'
import { useCampaignResultState } from '@src/scenes/CampaignResult/CampaignResultContext'

function RecipientTable() {
  const { campaign } = useCampaignResultState()
  const [results, setResults] = useState([])
  const [meta, setMeta] = useState([])
  const [tableInfo, setTableInfo] = useState({
    page: 1,
    size: 25,
    sort: 'first_name'
  })
  const { loading } = useRequest(
    () => getVictimsFromCampaign(campaign.id, tableInfo),
    {
      requestMethod: param => axiosInstance(param),
      onSuccess: ({ data: { results, meta } }) => {
        setResults(results || [])
        setMeta(meta || {})
      },
      refreshDeps: [tableInfo],
      ready: !!tableInfo
    }
  )

  const handleChangeTable = info => {
    const { pagination, sort } = info || {}
    const { limit = 25, offset = 0 } = pagination
    const page = Math.floor(offset / limit) + 1
    setTableInfo({
      page,
      size: limit,
      sort: prepareSortParam(sort)
    })
  }

  return (
    <Container>
      <TableWrapper>
        <ActivityIndicator active={loading} opacity="0.3" />
        <Table
          manual
          resizable
          bodyHeight="375px"
          data={results}
          totalCount={meta.totalElements || 0}
          defaultPage={1}
          columns={columns}
          defaultPageSize={25}
          pageSizeOptions={[5, 10, 25, 50]}
          onChangeTable={handleChangeTable}
        />
      </TableWrapper>
    </Container>
  )
}

export default RecipientTable
