export default [
  {
    key: 'message',
    header: 'Activity'.toUpperCase(),
    isHeadSort: true
  },
  {
    key: 'time',
    header: 'Occurred'.toUpperCase(),
    isHeadSort: true,
    asc: false
  }
]
