import styled from 'styled-components'
import { Typography } from '@elevate_security/elevate-component-library'

const { Text } = Typography

export const TemplateSetTableWrapper = styled.div`
  padding: 0;
  [class^='Tablestyles__TableContainer'] {
    padding: 0 !important;
    .table-row {
      padding: 8px 0 !important;
      margin-top: 0 !important;

      .table-data {
        padding-right: 50px;
      }

      [class^='Tablestyles__TableData'] {
        a {
          width: 100%;
          padding-right: 10px;
        }
        padding-right: 30px;
        &:nth-child(1),
        &:nth-child(3) {
          span.span {
            color: #0c7d84;
            cursor: default;
            display: flex;
          }
        }
      }
    }
  }
`
export const TableWrapper = styled.div`
  position: relative;
`

export const EmptyListMessage = styled(Text)`
  opacity: 0.7;
  font-size: 15px;
`

export const EmptyContainer = styled.div`
  background-color: rgb(245, 246, 248);
  border-radius: 5px;
  text-align: center;
  margin: 20px 10px;
  padding: 40px 15px;
  display: grid;
  grid-gap: 5px;
  flex-direction: column;
  align-items: center;
`
