import styled from 'styled-components'
import { Typography } from '@elevate_security/elevate-component-library'

const { Text } = Typography

export const EmptyListMessage = styled(Text)`
  opacity: 0.7;
  font-size: 15px;
`

export const Container = styled.div`
  background-color: rgb(245, 246, 248);
  border-radius: 5px;
  text-align: center;
  margin: 20px 10px;
  padding: 40px 15px;
  display: grid;
  grid-gap: 5px;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: underline !important;
  }
`
