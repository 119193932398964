import Moment from 'moment'

import { Constrain } from '@src/services/form-manager/validator'

import { utcAndTzToTime, isCampaignInProgress } from '@src/services/utils'

export const getDatesValidations = data => ({
  scheduled_start: [
    {
      test: Constrain.REQUIRED,
      value: true,
      message: 'Start date is required'
    },
    ...(!isCampaignInProgress(data.campaign_state)
      ? [
          {
            test: Constrain.DATE,
            value: true,
            after: Moment(),
            message: 'Start time cannot be in the past',
            timezone: data.timezone
          }
        ]
      : [])
  ],
  expiry: [
    { test: Constrain.REQUIRED, value: true, message: 'End date is required' },
    {
      test: Constrain.DATE,
      value: true,
      after: data.scheduled_start || Moment(),
      message: 'End time must be after start time',
      timezone: data.timezone
    }
  ]
})

/**
 * This function takes a UTC time and timezone and return parsed date for the picker
 * so that the date appears according to the selected timezone
 *
 *
 */
export const getFormattedData = data => {
  return {
    ...data,
    scheduled_start: utcAndTzToTime({
      date: data.scheduled_start,
      timezone: data.timezone
    }),
    expiry: utcAndTzToTime({
      date: data.expiry,
      timezone: data.timezone
    })
  }
}

/**
 * This function is to update the validations of
 * scheduled_start and expiry and returns the updated form
 */
export const updateValidations = (form, skipValidation = false) => {
  const { scheduled_start, expiry, timezone, campaign_state } = form.get()

  const validations = getDatesValidations({
    scheduled_start: scheduled_start.value,
    timezone: timezone.value,
    expiry: expiry.value,
    campaign_state: campaign_state.value
  })
  let updatedForm = form
  if (scheduled_start.value && scheduled_start.value.length) {
    updatedForm = updatedForm.update(
      'scheduled_start',
      {
        ...scheduled_start,
        validations: validations.scheduled_start
      },
      skipValidation
    )
  }
  if (expiry.value && expiry.value.length) {
    updatedForm = updatedForm.update(
      'expiry',
      {
        ...expiry,
        validations: validations.expiry
      },
      skipValidation
    )
  }
  return updatedForm
}

export const staggerCountOptions = [50, 100, 150, 200].map(value => ({
  label: `${value}`,
  value
}))

export const staggerPauseOptions = [60, 120, 180, 240, 300].map(value => ({
  label: `${value}`,
  value
}))
