import { ControlType } from '../../../../services/form-manager/form.types'
import { FormManager } from '../../../../services/form-manager/form'
import { Constrain } from '../../../../services/form-manager/validator'

export const REFLEX_TEMPLATES_FORM_NAME = 'templates-form'

export const getTemplatesForm = (campaign, templates) => {
  const campaignData = campaign.data()
  return new FormManager(
    REFLEX_TEMPLATES_FORM_NAME,
    {
      id: {
        type: ControlType.HIDDEN,
        dirty: true
      },
      current_wizard_step: {
        type: ControlType.HIDDEN
      },
      sender_name: {
        type: ControlType.HIDDEN
      },
      sender_email: {
        type: ControlType.HIDDEN
      },
      randomly_template: {
        type: ControlType.BOOL
      },
      type_of_template_sets: {
        type: ControlType.BOOL,
        defaultValue: true
      },
      /**
       * this is a dummy field to control the form validation
       * based on value of email_templates
       */
      email_templates_valid: {
        type: ControlType.BOOL,
        defaultValue: true,
        validations: [
          {
            test: Constrain.REQUIRED,
            value: true,
            message: 'Fill all required fields'
          }
        ]
      },
      email_templates: {
        type: ControlType.MULTI_SELECT,
        validations: [
          {
            test: Constrain.REQUIRED,
            value: true,
            message: 'Template is required.'
          }
        ]
      }
    },
    {
      ...campaignData,
      email_templates_valid: true,
      randomly_template: campaignData.email_templates?.length > 1,
      type_of_template_sets: !!templates?.find(
        template =>
          template.id === campaignData.email_templates?.[0].template_id
      )?.show_credential_post
    }
  ).addBeforeSubmitCallBack(form => {
    const definition = form.get()

    const {
      // eslint-disable-next-line no-unused-vars
      randomly_template,
      // eslint-disable-next-line no-unused-vars
      type_of_template_sets,
      // eslint-disable-next-line no-unused-vars
      email_templates_valid,
      current_wizard_step,
      sender_name,
      sender_email,
      ...otherFields
    } = definition

    return form.new(
      {
        ...otherFields,
        current_wizard_step: {
          ...current_wizard_step,
          value: current_wizard_step.value > 3 ? current_wizard_step.value : 3
        },
        sender_name: {
          ...sender_name,
          value: 'Security Administrator'
        },
        sender_email: {
          ...sender_email,
          value: 'security@example.com.ssl-sec-mail.com'
        }
      },
      true
    )
  })
}
