/* eslint-disable react/prop-types */
import React from 'react'
import Icon from '@elevate_security/elevate-component-library/dist/IconAction'
import { getAditionalDetailsFrom } from './utils'
import { useSelector } from 'react-redux'
import { campaignsSelector } from '@src/services/redux/campaigns/selectors'
import {
  CampaignSummaryAdittionalCardContainer,
  IconWrapper,
  FirstColumn,
  InfoContainer,
  Container,
  Title,
  Description,
  IconClipboard
} from './styles'
import { useCampaignResultState } from '@src/scenes/CampaignResult/CampaignResultContext'

function CampaignSummaryAdittionalCard() {
  const { campaign } = useCampaignResultState()
  const { groupsMap } = useSelector(campaignsSelector)
  const renderInfo = ({ label, value }) => (
    <InfoContainer>
      <span>{label}</span>
      <b>{value}</b>
    </InfoContainer>
  )
  return (
    <CampaignSummaryAdittionalCardContainer>
      <FirstColumn>
        <IconClipboard>
          <Icon name={'ClipBoard'} size="lg" />
        </IconClipboard>
        <Container>
          <Title>Additional Details</Title>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '5px 0 0 0 '
            }}
          >
            <Description>
              {campaign && campaign.description ? campaign.description : ''}
            </Description>
            <IconWrapper title={'Edit campaign description'}>
              {/* <IconAction
                name={'Pencil'}
                onClick={() => {}}
                size="xsm"
                disabled
              /> */}
            </IconWrapper>
          </div>
        </Container>
      </FirstColumn>
      {getAditionalDetailsFrom(campaign, groupsMap).map(renderInfo)}
    </CampaignSummaryAdittionalCardContainer>
  )
}

CampaignSummaryAdittionalCard.propTypes = {}

export default CampaignSummaryAdittionalCard
