import Toasts from '@src/components/Toasts'
import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const ToastsManager = ({ id, fixed }) => {
  const toasts = useSelector(state => state.toastsReducer)

  if (!toasts || !toasts.length) {
    return null
  }

  if (id) {
    const filtered = toasts.filter(toast => toast.containerId === id)
    return <Toasts toasts={filtered} fixed={fixed} />
  }

  const filtered = toasts.filter(toast => !toast.containerId)
  return <Toasts toasts={filtered} fixed={fixed} />
}

ToastsManager.propTypes = {
  id: PropTypes.number,
  fixed: PropTypes.bool
}

ToastsManager.defaultProps = {
  id: null,
  fixed: true
}

export default ToastsManager
