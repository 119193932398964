import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import InputErrors from '../../../../components/InputErrors'
import { RouteContainer, Form, FieldSet, Label } from '../../styles'
import { REFLEX_RECIPIENTS_FORM_NAME, getRecipientsForm } from './form'
import { withForm } from '../../../../services/form-manager/with-form'
import * as wizardActions from '../../../../services/redux/wizard/actions'
import { wizardSelector } from '../../../../services/redux/wizard/selectors'
import { campaignsSelector } from '../../../../services/redux/campaigns/selectors'
import useActions from '../../../../services/redux/useActions'
import { formOutOfSync } from '../../utils'
import { StyledAsyncTypeAhead } from '@src/components/StyledAsyncTypeAhead'

const RecipientsStep = ({ handleChange, values }) => {
  const { id } = useParams()
  const { campaign, wizardForm } = useSelector(wizardSelector)
  const { groups, groupsLoading } = useSelector(campaignsSelector)
  const { setWizardForm } = useActions(wizardActions)

  useEffect(() => {
    setWizardForm(getRecipientsForm(campaign, groups || []))
  }, [])

  useEffect(() => {
    if (!groupsLoading && id && campaign.id() && id !== values.id) {
      setWizardForm(getRecipientsForm(campaign, groups))
    }
  }, [campaign, groups, groupsLoading])

  const { es_groups_id } = wizardForm?.get() || {}

  const handleSelectGroups = groupsSelected => {
    handleChange('es_groups_id', { value: groupsSelected })
  }

  if (formOutOfSync(REFLEX_RECIPIENTS_FORM_NAME, wizardForm) || groupsLoading)
    return <ActivityIndicator active />

  return (
    <RouteContainer>
      <Form>
        <FieldSet>
          <Label>Recipient Groups</Label>
          <StyledAsyncTypeAhead
            selectHintOnEnter
            multiple
            placeholder="Choose or search groups..."
            labelKey="name"
            selected={es_groups_id.value}
            options={groups}
            onChange={handleSelectGroups}
            onSearch={() => {}}
            filterBy={['name']}
          />
          <InputErrors errors={es_groups_id?.errors} relative />
        </FieldSet>
      </Form>
    </RouteContainer>
  )
}

RecipientsStep.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number
  }),
  handleChange: PropTypes.func.isRequired
}

export default withForm(RecipientsStep)
