import Input from '@elevate_security/elevate-component-library/dist/Input'
import styled from 'styled-components'

// Note: span width applies to the ColumnWrapper's parent
export const PerformanceAnalysisTableWrapper = styled.div`
  background: #ffffff;
  height: 100% !important;
  position: relative;
  .table-body {
    margin-top: -5px;
    height: 438px !important;
    [data-column-key='recipient'] {
      border-right: 1px solid #c9cfd6;
    }
  }
  .table-head .table-row {
    padding: 8px 0;
  }
  .table-row {
    margin: 0;
    padding: 0px 0;
    border-color: #c9cfd6;
    .table-data
      :not([data-column-key='recipient'])
      :not([data-column-key='posted'])
      :not([data-column-key='clicked'])
      :not([data-column-key='reported']) {
      padding-left: 18px;
    }
    .table-data[data-column-key='recipient'],
    .table-data[data-column-key='opened'] {
      padding-right: 18px;
    }
  }
  .span {
    width: 100% !important;
  }

  [data-column-key='recipient'] {
    min-width: 0;
    width: 15% !important;
    flex: 0 0 15% !important;
  }
`

export const PerformanceAnalysisGridWrapper = styled.div`
  [class^='Tablestyles__TableContainer'] {
    padding: 0px;
    min-width: 1055px;
  }
`
export const YesOrNoContainer = styled.div`
  padding: 9px 18px;
  width: 100% !important;
  height: 100% !important;
  background-color: ${({ isYes, inverted }) => {
    if (inverted) return isYes ? '#E3F6F2' : '#f6e3e3'
    return isYes ? '#f6e3e3' : '#E3F6F2'
  }};
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 4px;
  color: ${({ isYes, inverted }) => {
    if (inverted) return isYes ? '#15785F' : '#c73434'
    return isYes ? '#c73434' : '#15785F'
  }};

  svg {
    height: 12px !important;
  }
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;

  [class^='inputstyles__InputContainer'] {
    .icons-left {
      left: 16px;
      svg {
        stroke: #7e8691;
      }
    }
  }
`
export const SearchInput = styled(Input)`
  background-color: #eef0f3;
  width: 252px;
  border: none;
  padding-left: 40px;

  :hover {
    border: none;
  }
`

export const EmptyData = styled.div`
  background-color: rgb(245, 246, 248);
  border-radius: 5px;
  text-align: center;
  margin: 20px 0px;
  padding: 40px 15px;
  display: grid;
  grid-gap: 5px;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 15px;
    font-weight: 500;
    opacity: 0.7;
  }
`
