import React, { Suspense, useEffect, useState } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useLocation
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  Modal,
  Header,
  Typography,
  Button,
  ButtonIcon,
  Link
} from '@elevate_security/elevate-component-library'
import ActivityIndicator from '../../components/ActivityIndicator'
import Breadcrumbs from './components/Breadcrumbs'
import StatusBadge from '../../components/StatusBadge'
import { ModalComponent, HeaderMetadata, SaveForLaterLink } from './styles'
import routes from './routes'
import {
  REFLEX_CAMPAIGNS_BASE_PATH,
  REFLEX_EDIT_CAMPAIGN_BASE_PATH,
  CAMPAIGN_STATUS
} from '../../constants'
import {
  STEP,
  TITLE,
  NEXT_STEP,
  PREVIOUS_STEP,
  STEP_PATH,
  getStepFromPathName,
  getStepIndex
} from './utils'
import * as wizardActions from '../../services/redux/wizard/actions'
import { wizardSelector } from '../../services/redux/wizard/selectors'
import * as campaignActions from '../../services/redux/campaigns/actions'
import useActions from '../../services/redux/useActions'
import { UNLOAD_WIZARD } from '../../services/redux/wizard/types'
import {
  toastSuccessHelper,
  addToastAction
} from '@src/services/redux/toasts/actionsCreator'
import ToastsManager from '../ToastsManager'
import { sanitizeContent } from '../../services/utils'

const { H2 } = Typography

const CampaignWziard = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(STEP.CAMPAIGN_INFO)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [url, setUrl] = useState(null)
  const { submitCampaign, setReflexCampaign, setWizardForm } = useActions(
    wizardActions
  )
  const { setReflexCampaignList } = useActions(campaignActions)
  const { id } = useParams()
  const { wizardForm, campaign, isCreatingCampaign } = useSelector(
    wizardSelector
  )

  const { campaign_state } = campaign.data() || {}
  const submitMessages = {
    created: '<b>Success!</b> New campaign created.',
    createdAndScheduled: '<b>Success!</b> New campaign created and scheduled.',
    updated: `<b>${sanitizeContent(campaign.properties.name)}</b> updated.`,
    updatedAndScheduled: `<b>${sanitizeContent(
      campaign.properties.name
    )}</b> updated & scheduled.`
  }

  const campaignSubmittedIndex =
    wizardForm && wizardForm.value('current_wizard_step')
      ? wizardForm.value('current_wizard_step')
      : -1

  const isDraft = CAMPAIGN_STATUS.Configuring === campaign_state

  useEffect(() => {
    if (!location.pathname.includes('templates/preview')) {
      if (campaign_state === CAMPAIGN_STATUS.Ended) {
        history.push(`/engagement/reflex/campaign/report/${id}`)
      }
    }
  }, [id, campaign_state, location])

  useEffect(() => {
    if (location.pathname !== url) {
      const next = getStepFromPathName(location.pathname)
      if (currentStep !== next) {
        setCurrentStep(next)
        setUrl(location.pathname)
      }
    }
  }, [location])

  const handleSubmit = async finishLater => {
    if (finishLater && currentStep === STEP.CONFIRM) {
      handleClose(finishLater)
      return
    }
    const validateForm = wizardForm.validate()
    setWizardForm(validateForm)
    if (validateForm.valid()) {
      try {
        const { data } = await submitCampaign()
        setFormSubmitted(true)
        if (currentStep === STEP.CONFIRM) {
          if (isCreatingCampaign)
            dispatch(toastSuccessHelper(submitMessages.createdAndScheduled))
          else dispatch(toastSuccessHelper(submitMessages.updatedAndScheduled))
          handleClose(false)
        } else {
          let campaignId = id || data?.id
          history.push(
            STEP_PATH[NEXT_STEP[currentStep]].replace(':id', campaignId)
          )
          setCurrentStep(NEXT_STEP[currentStep])
          if (finishLater) {
            handleClose(finishLater)
          }
        }
      } catch (error) {
        const toast = {
          id: Math.random(),
          level: 'error',
          message: error.message,
          containerId: 'modal-toast-manager'
        }
        dispatch(addToastAction(toast))
      }
    }
  }

  useEffect(() => {
    if (id) setReflexCampaign(id)
  }, [])

  const handlePrevious = () => {
    if (currentStep === STEP.RECIPIENTS) {
      history.push(REFLEX_EDIT_CAMPAIGN_BASE_PATH.replace(':id', id))
      setCurrentStep(PREVIOUS_STEP[currentStep])
    } else if (currentStep === STEP.TEMPLATES_PREVIEW) {
      history.push(STEP_PATH[STEP.TEMPLATES].replace(':id', id))
      setCurrentStep(STEP.TEMPLATES)
    } else if (currentStep !== STEP.CAMPAIGN_INFO) {
      history.push(STEP_PATH[PREVIOUS_STEP[currentStep]].replace(':id', id))
      setCurrentStep(PREVIOUS_STEP[currentStep])
    }
  }

  const handleClose = finishLater => {
    if (currentStep !== STEP.TEMPLATES_PREVIEW) {
      if (formSubmitted || finishLater) {
        setReflexCampaignList()
        if (isCreatingCampaign)
          dispatch(toastSuccessHelper(submitMessages.created))
        else dispatch(toastSuccessHelper(submitMessages.updated))
      }
      history.push(REFLEX_CAMPAIGNS_BASE_PATH)
      dispatch({ type: UNLOAD_WIZARD })
    } else if (location.state?.campaign_result_url) {
      history.push(location.state?.campaign_result_url)
    } else {
      handlePrevious()
    }
  }

  const modalTitle = (
    <Header>
      {currentStep !== STEP.TEMPLATES_PREVIEW && (
        <HeaderMetadata color="500" fontWeight="bold">
          {`${isCreatingCampaign ? 'Create' : 'Edit'} a Reflex Campaign ${
            getStepIndex(currentStep) + 1
          } of ${6}`}
        </HeaderMetadata>
      )}
      <H2
        color="900"
        fontWeight="bold"
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {currentStep === STEP.TEMPLATES_PREVIEW && (
          <ButtonIcon
            icon="ArrowLeft"
            theme="ghost"
            onClick={() => handleClose(false)}
          />
        )}
        {TITLE[currentStep]}
        {campaign_state && (
          <span>
            &nbsp;
            <StatusBadge campaign_state={campaign_state} />
          </span>
        )}
      </H2>
    </Header>
  )

  const rightButtons = () => {
    if (currentStep === STEP.CONFIRM && !isDraft) return []
    return [
      <Button
        type="submit"
        onClick={() => handleSubmit(false)}
        key="next_btn"
        fontWeight="bold"
        disabled={!wizardForm || !wizardForm.valid() || campaign.busy}
      >
        {currentStep === STEP.CONFIRM ? 'Schedule Campaign' : 'Save & Continue'}
      </Button>
    ]
  }

  const breadcrumb = (
    <Breadcrumbs
      wizardStep={currentStep}
      userStepIndex={campaignSubmittedIndex}
      onNavigate={step => {
        if (step === STEP.CAMPAIGN_INFO) {
          history.push(REFLEX_EDIT_CAMPAIGN_BASE_PATH.replace(':id', id))
        } else {
          history.push(STEP_PATH[step].replace(':id', id))
        }
        setCurrentStep(step)
      }}
    />
  )

  const leftButtons =
    currentStep !== STEP.CAMPAIGN_INFO
      ? [
          // eslint-disable-next-line react/jsx-indent
          <Button
            type="submit"
            onClick={handlePrevious}
            key="previous_btn"
            fontWeight="bold"
            theme="ghost"
            data-id="hm-wizard-previous-button"
          >
            Previous
          </Button>
        ]
      : []

  const closeButton = () => {
    if (currentStep === STEP.TEMPLATES_PREVIEW) return () => null

    if (!id)
      return (
        <span onClick={() => dispatch({ type: UNLOAD_WIZARD })}>
          <Link to={`${REFLEX_CAMPAIGNS_BASE_PATH}`} disabled={campaign.busy}>
            Cancel
          </Link>
        </span>
      )

    if (!isDraft && currentStep === STEP.CONFIRM) return null

    return (
      <SaveForLaterLink
        href="/#"
        onClick={e => {
          e.preventDefault()
          handleSubmit(true)
        }}
        disabled={campaign.busy}
      >
        Save & Finish Later
      </SaveForLaterLink>
    )
  }

  return (
    <ModalComponent id="reflex-campaign-wizard">
      <Modal
        isOpen
        key="campaign-wizard"
        header={modalTitle}
        closeButton={closeButton()}
        onClose={() => handleClose(false)}
        {...(currentStep !== STEP.TEMPLATES_PREVIEW
          ? {
              rightButtons: rightButtons(),
              leftButtons: leftButtons,
              breadcrumb: breadcrumb
            }
          : {})}
      >
        <div style={{ position: 'relative', height: '100%' }}>
          <div style={{ width: '90%', margin: '5px auto -10px auto' }}>
            <ToastsManager fixed={false} id="modal-toast-manager" />
          </div>
          {campaign.busy && <ActivityIndicator active />}
          <Suspense fallback={<ActivityIndicator active name="suspense" />}>
            <Switch>
              {routes.map(({ path, exact, component: Component }) => (
                <Route
                  key={path}
                  exact={exact}
                  path={path}
                  component={Component}
                />
              ))}
            </Switch>
          </Suspense>
        </div>
      </Modal>
    </ModalComponent>
  )
}

export default CampaignWziard
