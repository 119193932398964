import styled from 'styled-components'

export const TableWrapper = styled.div`
  padding: 0;
  table {
    padding: 0 !important;
    font-weight: normal;
    width: 100%;
    thead {
      font-weight: bold;
      color: grey;
    }
    tr {
      border-bottom: 1px solid #efefef;
      th,
      td {
        padding: 12px 0 !important;
        margin-top: 0 !important;
      }

      a {
        color: #0c7d84;
      }
    }
  }
`
export const Container = styled.div`
  position: relative;
`
