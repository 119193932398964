import invert from 'lodash/invert'
import { matchPath } from 'react-router-dom'

import {
  REFLEX_CAMPAIGNS_BASE_PATH,
  REFLEX_NEW_CAMPAIGN_PATH,
  REFLEX_EDIT_CAMPAIGN_BASE_PATH
} from '../../constants'

const STEP_BASE_PATH = REFLEX_EDIT_CAMPAIGN_BASE_PATH

export const STEP = {
  CAMPAIGN_INFO: 'CAMPAIGN_INFO',
  RECIPIENTS: 'RECIPIENTS',
  TEMPLATES: 'TEMPLATES',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SCHEDULING: 'SCHEDULING',
  CONFIRM: 'CONFIRM',
  TEMPLATES_PREVIEW: 'TEMPLATES_PREVIEW'
}
export const STEPS = Array.from(
  Object.keys(STEP).filter(name => ['TEMPLATES_PREVIEW'].indexOf(name) < 0)
)

export const getStepIndex = step => {
  const arr = Object.keys(STEP)
  return arr.findIndex(name => name === step)
}

export const getStepName = index => {
  const arr = Object.keys(STEP)
  return STEP[arr[index - 1]]
}

export const STEP_PATH = {
  [STEP.CAMPAIGN_INFO]: [REFLEX_NEW_CAMPAIGN_PATH, STEP_BASE_PATH],
  [STEP.RECIPIENTS]: `${STEP_BASE_PATH}/recipients`,
  [STEP.TEMPLATES]: `${STEP_BASE_PATH}/templates`,
  [STEP.NOTIFICATIONS]: `${STEP_BASE_PATH}/notifications`,
  [STEP.SCHEDULING]: `${STEP_BASE_PATH}/scheduling`,
  [STEP.CONFIRM]: `${STEP_BASE_PATH}/confirm`,
  [STEP.TEMPLATES_PREVIEW]: `${STEP_BASE_PATH}/templates/preview/:templateId?`
}

export const getPathFromStepNumber = id => ({
  1: `${REFLEX_CAMPAIGNS_BASE_PATH}/${id}`,
  2: `${REFLEX_CAMPAIGNS_BASE_PATH}/${id}/recipients`,
  3: `${REFLEX_CAMPAIGNS_BASE_PATH}/${id}/templates`,
  4: `${REFLEX_CAMPAIGNS_BASE_PATH}/${id}/notifications`,
  5: `${REFLEX_CAMPAIGNS_BASE_PATH}/${id}/scheduling`,
  6: `${REFLEX_CAMPAIGNS_BASE_PATH}/${id}/confirm`
})

export const NEXT_STEP = {
  CAMPAIGN_INFO: STEP.RECIPIENTS,
  RECIPIENTS: STEP.TEMPLATES,
  TEMPLATES: STEP.NOTIFICATIONS,
  NOTIFICATIONS: STEP.SCHEDULING,
  SCHEDULING: STEP.CONFIRM,
  CONFIRM: null
}

export const PREVIOUS_STEP = invert(NEXT_STEP)

export const BREADCRUMB = {
  [STEP.CAMPAIGN_INFO]: 'Campaign Info',
  [STEP.RECIPIENTS]: 'Recipients',
  [STEP.TEMPLATES]: 'Templates',
  [STEP.NOTIFICATIONS]: 'Notifications',
  [STEP.SCHEDULING]: 'Scheduling',
  [STEP.CONFIRM]: 'Confirm'
}

export const TITLE = {
  [STEP.CAMPAIGN_INFO]: 'Enter campaign info',
  [STEP.RECIPIENTS]: 'Choose recipients',
  [STEP.TEMPLATES]: 'Select templates',
  [STEP.NOTIFICATIONS]: 'Set notification preferences',
  [STEP.SCHEDULING]: 'Schedule campaign',
  [STEP.CONFIRM]: 'Confirm & schedule your campaign',
  [STEP.TEMPLATES_PREVIEW]: `Template Preview`
}

export function getStepFromPathName(pathname) {
  const name = Object.keys(STEP_PATH).find(key => {
    const match = matchPath(pathname, {
      path: STEP_PATH[key],
      exact: true,
      strict: false
    })
    return match !== null
  })
  return name ? STEP[name] : null
}

export const formOutOfSync = (formName, wizardForm) => {
  return !(formName && wizardForm && wizardForm.is(formName))
}

export const mapNamesToBehaviors = (names, behaviors) => {
  if (Array.isArray(behaviors) && Array.isArray(names)) {
    return names
      .filter(name => !name.includes('audience'))
      .map(name => {
        const selected = behaviors.find(behavior =>
          behavior.name.includes(name)
        )
        return selected ? selected.label : name
      })
  }
  return []
}
