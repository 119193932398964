import React from 'react'
import CampaignSummary from './CampaignSummary'
import PerformanceAnalysis from './PerformanceAnalysis'
import TemplateSetPerformance from './TemplateSetPerformance'

function CampaignResultsTab() {
  return (
    <>
      <CampaignSummary />
      <TemplateSetPerformance />
      <PerformanceAnalysis />
    </>
  )
}

export default CampaignResultsTab
