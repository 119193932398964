import styled from 'styled-components'
import { Layout } from '@elevate_security/elevate-component-library'

export const ActionContainer = styled(Layout)`
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
  padding: 0 0 0 15px;
`

export const IconWrapper = styled.span`
  margin-left: 24px;
  flex-shrink: 0;
  button {
    width: 195px;
    svg {
      margin-bottom: 4px;
      margin-right: 8px;
    }
  }
`
