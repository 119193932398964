import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { wizardSelector } from '../redux/wizard/selectors'
import * as wizardActions from '../redux/wizard/actions'
import useActions from '../redux/useActions'

/**
 * This component extends the capabilities of each step
 * component with form helpers so each step has to minimally
 * manage their form.
 *
 * To make use of it, instead of exporting your step component
 * export the following:
 *
 * @example
 * withForm(RollOutType)
 *
 * This will provide a `handleChange` function in the props of your
 * component, which you can use to register input changes to the form
 * that gets propagated back to your component by the store.
 *
 * @example
 * onChange={value => handleChange('type', { value })}
 *
 * @param WrappedComponent
 * @returns {function(*): *}
 */
export function withForm(WrappedComponent) {
  return function wrapper(props) {
    const { wizardForm } = useSelector(wizardSelector)
    const { setWizardForm } = useActions(wizardActions)
    const [values, setValues] = useState(wizardForm ? wizardForm.value() : {})

    useEffect(() => {
      if (wizardForm) {
        const formValues = wizardForm.value()
        if (!isEqual(formValues, values)) {
          setValues(formValues)
        }
      }
    }, [wizardForm])

    const handleChange = (name, value) => {
      const form = wizardForm.updateValue(name, value)
      setValues(form.value())
      setWizardForm(form)
    }

    const updatedProps = {
      ...props,
      values,
      handleChange
    }

    return <WrappedComponent {...updatedProps} />
  }
}
